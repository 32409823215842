import { userApi } from "@/api/user";

const getDefaultState = () => {
  return {
    user: {
      subscription: null,
      referId: null,
    },
    email: "",
    emailValid: true,
    checkboxValid: true,
    loadStatus: "", // ok pending error
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_EMAIL_VALID(state, valid) {
      state.emailValid = valid;
    },
    SET_CHECKBOX_VALID(state, valid) {
      state.checkboxValid = valid;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_LOAD_STATUS(state, status) {
      state.loadStatus = status;
    },
  },
  actions: {
    async getUser({ commit }) {
      try {
        commit("SET_LOAD_STATUS", "pending");
        const res = await userApi.getUser();
        commit("SET_USER", res.data);
        commit("refer/SET_REFER", res.data.referId, { root: true });
        commit("SET_LOAD_STATUS", "ok");
      } catch (e) {
        commit("SET_LOAD_STATUS", "error");
      }
    },
    setEmail({ commit }, email) {
      commit("SET_EMAIL", email);
    },
    setEmailValid({ commit }, data) {
      commit("SET_EMAIL_VALID", data);
    },
    setCheckboxlValid({ commit }, data) {
      commit("SET_CHECKBOX_VALID", data);
    },
  },
  getters: {
    user: (state) => state.user,
    getEmail: (state) => state.email,
    getEmailValid: (state) => state.emailValid,
    getCheckboxValid: (state) => state.checkboxValid,
    getUserStatus: (state) => state.loadStatus,
    getUserBalance: (state) => state.user.balance,
  },
};
