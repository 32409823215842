<template>
  <button
    :class="`button-link button-link--size--${size} button-link--color--${color}`"
    :disabled="disabled"
    :readonly="readonly"
  >
    <span
      v-if="icon"
      class="button-link__icon"
      v-html="require(`@/assets/img/icons/${icon}.svg`)"
    />
    <span
      v-if="arrow && left"
      class="button-link__arrow-left"
      v-html="require(`@/assets/img/icons/arrow-left.svg`)"
    />
    <slot />
    <span
      v-if="arrow && !left"
      class="button-link__arrow"
      v-html="require(`@/assets/img/icons/arrow.svg`)"
    />
  </button>
</template>

<script>
export default {
  name: "ButtonLink",
  props: {
    size: {
      type: String,
      required: false,
      default: "s",
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return ["s", "m", "l"].indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
      required: false,
      default: "accent",
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return ["accent", "success"].indexOf(value) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    arrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.button-link {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  white-space: nowrap;
  border: none;
  overflow: hidden;
  cursor: pointer;
  transition: color $transition;
  padding: 0;
  margin: 0;

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }

  &__arrow {
    display: flex;
    align-items: center;
    margin-left: 10px;
    &-left {
      margin-right: 10px;
      margin-left: 0;
    }
    svg {
      width: 7px;
      height: 11px;
      fill: $accent;
      transition: fill $transition;
    }
  }

  &:hover {
    color: $title;
    .button-link__arrow {
      svg {
        fill: $title;
      }
    }
  }

  &[readonly] {
    pointer-events: none;
  }

  &--size {
    &--s {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 500;
    }

    &--m {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
    }

    &--l {
      font-size: 1rem;
      line-height: 1.429rem;
      font-weight: 600;
    }
  }

  &--color {
    &--accent {
      color: $accent;

      .button-link__icon {
        svg {
          path {
            fill: $accent;
          }
        }
      }
    }

    &--success {
      color: $success;

      .button-link__icon {
        svg {
          path {
            fill: $success;
          }
        }
      }
    }
  }
}
</style>
