<template>
  <button
    :class="`slider__button slider__button--${direction}`"
    :disabled="disabled"
    v-html="require(`@/assets/img/icons/arrow.svg`)"
  ></button>
</template>

<script>
export default {
  name: "SliderButton",
  props: {
    direction: {
      type: String,
      required: true,
      default: "left",
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.slider__button {
  width: 60px;
  height: 60px;
  background-color: $accent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $transition;
  &--left {
    border-radius: 20px 0 0 20px;
    //margin-right: 2px;
    svg {
      transform: rotate(180deg);
    }
  }
  &--right {
    border-radius: 0 20px 20px 0;
    //margin-left: 2px;
  }
  svg {
    transition: $transition;
    fill: $primary;
  }
  &:disabled {
    background-color: $primary;
    cursor: default;
    svg {
      fill: $input;
    }
  }
  &:hover:not(:disabled) {
    background-color: $accent-disabled;
    box-shadow: $button-shadow-disabled;
    svg {
      fill: $accent;
    }
  }
}
</style>
