<template>
  <main class="complete app-container">
    <app-complete-success v-if="getLoadingStatus === 'ok'" />

    <app-complete-error v-else-if="getLoadingStatus === 'error'" />
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CompleteSuccess from "@/components/Complete/Success";
import CompleteError from "@/components/Complete/Error";
import helpers from "@/helpers/index";

export default {
  name: "Complete",
  components: {
    "app-complete-success": CompleteSuccess,
    "app-complete-error": CompleteError,
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
    ...mapGetters("payment", ["completeResponse"]),
  },
  async created() {
    const query = helpers.methods.parseUrlQuery();
    await this.completeDraft(query.id);
  },
  methods: {
    ...mapActions("payment", ["completeDraft"]),
    ...mapActions("cart", ["getCart"]),
  },
  watch: {
    async getLoadingStatus() {
      if (this.getLoadingStatus === "ok") {
        await this.getCart();
      }
    },
  },
};
</script>

<style lang="scss">
.complete {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
