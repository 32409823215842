<template>
  <section
    class="form cart-goods"
    :class="{
      'form--disabled': user && !user.subscription,
    }"
  >
    <div v-if="user && !user.subscription" class="form__overlay"></div>
    <header>
      <h3>Товары</h3>
    </header>

    <main>
      <header>
        <app-checkbox v-model:value="all">Выбрать все</app-checkbox>

        <app-button-link size="m" @click="deleteMany">
          Удалить выбранные
        </app-button-link>
      </header>

      <main>
        <app-goods-group
          v-for="group in groups"
          :key="group[0].item.retailPointId"
          :group-item="group[0].item"
          v-model:active="pickedRetailPoint"
        >
          <app-goods-group-item
            v-for="groupItem in group"
            :key="groupItem.item._id"
            :group-item="groupItem.item"
            :count="groupItem.count"
            :picked="pickedGoods.has(groupItem.item._id)"
            @del="del(groupItem.item._id)"
            @changeCount="changeCount($event, groupItem.item._id)"
            @change="changeCheckbox(groupItem.item._id)"
          />
        </app-goods-group>
      </main>
    </main>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Checkbox from "@/components/UI/Inputs/Checkbox";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import GoodsGroup from "@/components/Cart/Goods/Components/Group";
import GoodsGroupItem from "@/components/Cart/Goods/Components/GroupItem";

export default {
  name: "CartGoods",
  components: {
    "app-checkbox": Checkbox,
    "app-button-link": ButtonLink,
    "app-goods-group": GoodsGroup,
    "app-goods-group-item": GoodsGroupItem,
  },
  computed: {
    ...mapGetters("cart", ["cart"]),
    ...mapGetters("user", ["user"]),
    groups() {
      const obj = {};

      this.cart.forEach((el) => {
        if (!obj[el.item.retailPointId]) {
          obj[el.item.retailPointId] = [el];
        } else {
          obj[el.item.retailPointId].push(el);
        }
      });

      const res = Object.values(obj);

      return res;
    },
  },
  data: () => ({
    all: false,
    pickedRetailPoint: null,
    pickedGoods: new Set(),
  }),
  watch: {
    groups: {
      handler(val) {
        if (this.user.subscription && val.length > 0) {
          this.pickedRetailPoint = val[0][0].item.retailPointId;
        }
      },
      immediate: true,
      deep: true,
    },
    all(val) {
      if (val) {
        this.cart.forEach((item) => {
          this.pickedGoods.add(item.item._id);
        });
      } else {
        this.pickedGoods = new Set();
      }
    },
    pickedRetailPoint: {
      async handler(v) {
        this.setPointIdFoPay(v);
        await this.createDraft();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("cart", ["removeOne", "changeItemCount", "removeMany"]),
    ...mapMutations("payment", { setPointIdFoPay: "SET_POINT_ID_FOR_PAY" }),
    ...mapActions("payment", ["createDraft"]),
    del(_id) {
      this.removeOne(_id);
    },
    deleteMany() {
      this.removeMany(this.pickedGoods);
      this.pickedGoods = new Set();
      this.all = false;
    },
    changeCheckbox(_id) {
      if (this.pickedGoods.has(_id)) {
        this.pickedGoods.delete(_id);
      } else {
        this.pickedGoods.add(_id);
      }
    },
    changeCount(count, _id) {
      this.changeItemCount({ count, _id });
    },
  },
};
</script>

<style lang="scss">
.cart-goods {
  padding-left: 0 !important;
  padding-right: 0 !important;

  & > header,
  & > main > header,
  & .goods-group {
    padding-left: 15px;
    padding-right: 15px;
  }

  & > main {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;

    & > header {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-column-gap: 10px;
      justify-content: space-between;
    }

    & > main {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
}

@media (min-width: $desktop-width) {
  .cart-goods {
    & > header,
    & > main > header,
    & .goods-group {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
