import { server } from "@/api/index";

export const vouchersApi = {
  loadVouchers(phone) {
    return server.get(`/vouchers/${phone}`);
  },
  loadCoupons() {
    return server.get("/vouchers/coupons");
  },
};
