import { server } from "./index";

let offersApi = {
  all(data) {
    return server.post(`/special/web/search`, data);
  },
  searchByRetailPointId(id, data) {
    return server.post(`/special/web/search/${id}`, data);
  },
  categories(projectId) {
    return server.get(`/categories/${projectId}`);
  },
  singleOffer(id) {
    return server.get(`/special/${id}`);
  },
};

export { offersApi };
