<template>
  <section class="promocode-modal">
    <header>
      <h2>Промокод</h2>
    </header>

    <main>
      <app-input
        v-model:value="promocodeValue"
        v-model:valid="promocodeValid"
        mode="promocode"
        label="Введите промокод"
        required
        :disabled="loader"
        :focus="true"
      />
    </main>

    <footer>
      <app-button :disabled="!valid" :loading="loader" @click="send">
        Применить промокод
      </app-button>
    </footer>

    <p v-if="error" class="promocode-modal__error error-message">
      Ошибка сервера. Повторите попытку.
    </p>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Input from "@/components/UI/Inputs/Input.vue";
import Button from "@/components/UI/Buttons/Button.vue";

export default {
  name: "PromocodeModal",
  components: {
    "app-input": Input,
    "app-button": Button,
  },
  computed: {
    valid() {
      return this.promocodeValue && this.promocodeValid;
    },
  },
  data: () => ({
    promocodeValue: "",
    promocodeValid: true,
    loader: false,
    error: false,
  }),
  watch: {
    promocodeValue() {
      this.clearErrors();
    },
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    ...mapActions("refer", ["setRefer"]),
    ...mapActions("payment", ["createDraft"]),
    clearErrors() {
      this.error = false;
    },
    async send() {
      this.clearErrors();
      try {
        this.loader = true;
        this.setRefer(this.promocodeValue);
        // await this.createDraft(this.promocodeValue);

        this.toggleModal({
          name: "promocode",
          value: false,
        });
      } catch (e) {
        this.error = true;
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss">
.promocode-modal {
  main {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  &__error {
    position: absolute;
    bottom: 5px;
    left: 15px;
    right: 15px;
  }
}

@media (min-width: $desktop-width) {
  .promocode-modal {
    main {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    &__error {
      bottom: 10px;
      left: 30px;
      right: 30px;
    }
  }
}
</style>
