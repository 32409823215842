import { server } from "./index";

let authApi = {
  register() {
    return server.get(`/auth/register`);
  },
  sendToken(phone) {
    return server.post("/auth/token", phone);
  },
  approveToken(data) {
    return server.post("/auth/approve", data);
  },
};

export { authApi };
