<template>
  <router-link to="/cart">
    <button class="header-cart">
      <span
        class="header-cart__icon"
        v-html="require(`@/assets/img/icons/cart.svg`)"
      ></span>
      <span class="header-cart__text">Корзина</span>
      <span class="header-cart__count" v-if="count > 0">{{ count }}</span>
    </button>
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderCart",
  computed: {
    ...mapGetters("cart", ["count"]),
  },
};
</script>

<style lang="scss">
.header-cart {
  width: 165px;
  height: 60px;
  border-radius: $border-radius;
  background-color: $primary;
  color: $accent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  box-shadow: 12px 12px 20px rgba(45, 80, 251, 0.15);
  transition: background-color $transition, box-shadow $transition,
    background-color $transition;

  &__icon {
    width: 24px;
    height: 24px;
    svg {
      transition: $transition;
      fill: $accent;
    }
  }
  &__count {
    color: $primary;
    background-color: $label-error;
    line-height: 16px;
    border-radius: 30px;
    padding: 2px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  &:hover {
    background-color: $input-hover;
    box-shadow: 0px 4px 25px rgba(45, 80, 251, 0.35);
  }
}
@media (max-width: $desktop-width) {
  .header-cart {
    height: 40px;
    width: fit-content;
    border-radius: 10px;
    padding: 10px;
    &__icon {
      width: 20px;
      height: 20px;
      svg {
        width: inherit;
        height: inherit;
      }
    }
    &__text {
      display: none;
    }
    &__count {
      margin-left: 2px;
    }
  }
}
</style>
