<template>
  <div class="offer-labels" v-if="labelType">
    <div
      class="offer-labels__item"
      :class="`offer-labels__${labelClass} offer-labels__item--${type}`"
    >
      <span
        v-if="icon"
        class="offer-labels__item-icon"
        v-html="require(`@/assets/img/icons/${icon}.svg`)"
      ></span>
      <span v-if="labelText">{{ labelText }}</span>
      <slot v-else></slot>
    </div>
    <!--    <div-->
    <!--      v-if="isHot"-->
    <!--      class="offer-labels__item offer-labels__hot"-->
    <!--      v-html="require(`@/assets/img/icons/hot-offer.svg`)"-->
    <!--    ></div>-->
  </div>
</template>

<script>
export default {
  name: "Label",
  props: {
    type: {
      type: String,
      required: false,
      default: "",
    },
    card: {
      type: Object,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    labelType: {
      type: String,
      required: true,
      default: "",
      validator(value) {
        return (
          [
            "DISCOUNT",
            "BONUSBACK",
            "N_LIKE_M",
            "CUSTOM",
            "SUCCESS",
            "ERROR",
            "",
          ].indexOf(value) !== -1
        );
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    labelText() {
      if (this.labelType === "CUSTOM") {
        return `${this.card.customType}`;
      } else if (this.card && this.card.price) {
        if (this.labelType === "N_LIKE_M") {
          const ratio = this.card.price.ratio.split(":");
          return `${ratio[0]} по цене ${ratio[1]}`;
        } else if (this.labelType === "DISCOUNT") {
          return `Скидка ${this.card.price.percent}%`;
        } else if (this.labelType === "BONUSBACK") {
          return `Кэшбэк ${this.card.price.percent}%`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    labelClass() {
      switch (this.labelType) {
        case "N_LIKE_M":
          return "offer";
        case "DISCOUNT":
          return "discount";
        case "BONUSBACK":
          return "cashback";
        case "CUSTOM":
          return "custom";
        case "SUCCESS":
          return "success";
        case "ERROR":
          return "error";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss">
.offer-labels {
  z-index: 1;
  &__item {
    padding: 10px;
    color: $primary;
    border-radius: 10px;
    width: fit-content;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      svg {
        fill: $primary;
        width: inherit;
        height: inherit;
      }
    }
    &--voucher {
      font-size: 12px;
      padding: 4px;
    }
  }
  &__discount {
    background-color: $label-hot;
  }
  &__cashback {
    background-color: $accent;
  }
  &__offer {
    background-color: $label-yellow;
    color: $title;
  }
  &__custom {
    background-color: $label-pink;
  }
  &__success {
    padding: 15px;
    border-radius: 16px;
    background-color: $label-success;
  }
  &__error {
    padding: 15px;
    border-radius: 16px;
    background-color: $label-error;
  }
  &__hot {
    background-color: $primary;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: $label-hot;
      width: 25px;
      height: 25px;
    }
  }
}
@media (max-width: $desktop-width) {
  .offer-labels {
    &__item {
      font-size: 10px;
      padding: 4px;
    }
  }
}
</style>
