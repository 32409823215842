export default {
  namespaced: true,
  state: {
    paymentTypes: [
      "NEW_CARD",
      "SBP_QR",
      // 'VISA',
      // 'MASTERCARD',
      // 'MAESTRO',
      // 'MIR',
      // 'APPLE',
      // 'GOOGLE',
    ],
    paymentType: "SBP_QR",
  },
  mutations: {
    SET_PAYMENT_TYPE(state, data) {
      state.paymentType = data;
    },
  },
  actions: {
    setPaymentType({ commit }, data) {
      commit("SET_PAYMENT_TYPE", data);
    },
  },
  getters: {
    paymentType: (state) => state.paymentType,
    paymentTypes: (state) => state.paymentTypes,
  },
};
