import { orderApi } from "@/api/order";

export default {
  namespaced: true,
  state: {
    order: {
      id: "",
      goods: [],
      productType: "",
    },
  },
  mutations: {
    SET_ORDER(state, data) {
      state.order.id = data.id;
      state.order.goods = data.goods;
      state.order.productType = data.productType;
    },
  },
  actions: {
    async getOrder({ commit, dispatch }, id) {
      commit("SET_LOADING_STATUS", "pending", { root: true });
      try {
        const res = await orderApi.get(id);
        commit("SET_ORDER", res.data);
        commit("payment/CREATE_DRAFT", res.data.draftInfo, { root: true });

        commit("SET_LOADING_STATUS", "ok", { root: true });
      } catch (e) {
        commit("SET_LOADING_STATUS", "error", { root: true });

        await dispatch(
          "error/toggleError",
          {
            name: "internal",
            value: true,
          },
          { root: true }
        );
      }
    },
  },
  getters: {
    order: (state) => state.order,
  },
};
