<template>
  <app-loader v-if="getLoading" :value="getLoading" />
  <template v-else>
    <h3 class="check-page__title">Данные успешно заполнены</h3>
    <div class="check-page__subtitle">
      Проверьте корректность введенных данных перед отправкой формы. На
      электронную почту {{ partner.contacts.email }} вы получите договор оферты,
      который необходимо распечатать и подписать, после чего отправить скан для
      подтверждения сотрудничества на {{ accountingEmail }}
    </div>
    <div class="check-page__content">
      <div class="check-page__content-group">
        <div class="check-page__content-group--title">Компания</div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Наименование юр. лица/ИП
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.companyInfo.companyTitle }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">ИНН</div>
          <div class="check-page__content-group--item-value">
            {{ partner.companyInfo.INN }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            ФИО генерального директора
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.companyInfo.owner }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Юридический адрес
          </div>
          <div class="check-page__content-group--item-value">
            {{ juridicalAddress }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Фактический адрес
          </div>
          <div class="check-page__content-group--item-value">
            {{ juridicalAddress }}
          </div>
        </div>
      </div>
      <div class="check-page__content-group">
        <div class="check-page__content-group--title">Расчетный счет</div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">Расчетный счет</div>
          <div class="check-page__content-group--item-value">
            {{ partner.bankInfo.account }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">Банк получателя</div>
          <div class="check-page__content-group--item-value">
            {{ partner.bankInfo.bankName }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            ИНН банка получателя
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.bankInfo.receiverINN }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">КПП</div>
          <div class="check-page__content-group--item-value">
            {{ partner.bankInfo.KPP }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Корреспондентский счет
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.bankInfo.correspondentAcc }}
          </div>
        </div>
      </div>
      <div
        class="check-page__content-group"
        v-if="!partner.structure.network.retailNetworkName"
      >
        <div class="check-page__content-group--title">Торговая точка</div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Название торговой точки
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.pointInfo.retailPointName }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">Адрес</div>
          <div class="check-page__content-group--item-value">
            {{ pointAddress }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Телефон кассира для бота
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.pointInfo.cashier.phone }},
            {{ partner.pointInfo.cashier.name }}
          </div>
        </div>
      </div>
      <div class="check-page__content-group" v-else>
        <div
          v-if="partner.structure.points.length > 1"
          class="check-page__content-group--title network-name"
        >
          Торговая сеть: {{ partner.structure.network.retailNetworkName }}
          <span class="points-count">{{
            partner.structure.points.length
          }}</span>
        </div>
        <div v-else class="check-page__content-group--title">
          Торговая точка
        </div>
        <div
          class="check-page__content-group-point"
          v-for="(point, index) in partner.structure.points"
          :key="index"
        >
          <div class="check-page__content-group--item">
            <div class="check-page__content-group--item-key point-name">
              {{ point.retailPointName }}
            </div>
            <div class="check-page__content-group--item-value"></div>
          </div>
          <div class="check-page__content-group--item">
            <div class="check-page__content-group--item-key">Адрес</div>
            <div class="check-page__content-group--item-value">
              {{ getPointAddress(point.address) }}
            </div>
          </div>
          <div class="check-page__content-group--item">
            <div class="check-page__content-group--item-key">
              Телефон кассира для бота
            </div>
            <div class="check-page__content-group--item-value">
              {{ point.cashier.phone }},
              {{ point.cashier.name }}
            </div>
          </div>
          <div class="check-page__content-group--item" v-if="point.webAddress">
            <div class="check-page__content-group--item-key">Сайт</div>
            <div class="check-page__content-group--item-value">
              {{ point.webAddress }}
            </div>
          </div>
          <div class="check-page__content-group--item" v-if="point.workingTime">
            <div class="check-page__content-group--item-key">Режим работы</div>
            <div class="check-page__content-group--item-value">
              {{ point.workingTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="check-page__content-group">
        <div class="check-page__content-group--title">Контакты</div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">Контактное лицо</div>
          <div class="check-page__content-group--item-value">
            {{ partner.contacts.contactPerson }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">
            Электронная почта
          </div>
          <div class="check-page__content-group--item-value">
            {{ partner.contacts.email }}
          </div>
        </div>
        <div class="check-page__content-group--item">
          <div class="check-page__content-group--item-key">Телефон</div>
          <div class="check-page__content-group--item-value">
            {{ partner.contacts.phone }}
          </div>
        </div>
      </div>
      <app-button-link
        v-if="!mobView"
        class="partner-stepper__btn"
        arrow
        left
        @click="$emit('gotostart')"
      >
        Вернуться к редактированию</app-button-link
      >
      <div class="check-page__content-checkboxes">
        <app-checkbox v-model:value="personalAgreed">
          Я даю согласие на
          <app-link :link="getPolicyURLs.privatePolicy"
            >обработку своих персональных данных</app-link
          >
        </app-checkbox>
        <app-checkbox v-model:value="offerAgreed">
          Я соглашаюсь с условиями
          <app-link :link="getPolicyURLs.partnerOffer"
            >договора оферты</app-link
          >
        </app-checkbox>
      </div>
      <app-button
        class="partner-stepper__btn"
        @click="accept"
        :disabled="!personalAgreed || !offerAgreed"
        >Зарегистрироваться</app-button
      >
    </div>
  </template>
</template>

<script>
import Link from "@/components/UI/Links/Link";
import Checkbox from "@/components/UI/Inputs/Checkbox";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import Button from "@/components/UI/Buttons/Button";
import { mapActions, mapGetters, mapState } from "vuex";
import Preloader from "@/components/UI/Preloader";

export default {
  name: "CheckPage",
  components: {
    "app-checkbox": Checkbox,
    "app-link": Link,
    "app-button-link": ButtonLink,
    "app-button": Button,
    "app-loader": Preloader,
  },
  props: {
    partner: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    personalAgreed: false,
    offerAgreed: false,
  }),
  computed: {
    ...mapGetters("partner", ["getLoading"]),
    ...mapGetters({
      getPolicyURLs: "getPolicyURLs",
    }),
    ...mapState({ accountingEmail: "accountingEmail" }),
    mobView() {
      return window.screen.width <= 768;
    },
    juridicalAddress() {
      let flat = this.partner.companyInfo.juridicalAddress.flat;
      return (
        `${this.partner.companyInfo.juridicalAddress.main}, ` +
        `${this.partner.companyInfo.juridicalAddress.street}, ` +
        `${this.partner.companyInfo.juridicalAddress.house}` +
        `${flat ? ", " + flat : ""}`
      );
    },
    address() {
      let flat = this.partner.companyInfo.address.flat;
      return (
        `${this.partner.companyInfo.address.main}, ` +
        `${this.partner.companyInfo.address.street}, ` +
        `${this.partner.companyInfo.address.house}` +
        `${flat ? ", " + flat : ""}`
      );
    },
    pointAddress() {
      return (
        `${this.partner.pointInfo.address.city}, ` +
        `${this.partner.pointInfo.address.street}, ` +
        `${this.partner.pointInfo.address.house}`
      );
    },
  },
  methods: {
    ...mapActions("partner", ["registerPartner", "setPartnerByInn"]),
    async accept() {
      await this.registerPartner();
      await this.$router.push({
        name: "PartnerComplete",
        params: { INN: this.partner.companyInfo.INN },
      });
    },
    getPointAddress(address) {
      return `${address.city}, ` + `${address.street}, ` + `${address.house}`;
    },
  },
};
</script>

<style lang="scss">
.check-page__subtitle {
  margin-bottom: 35px;
}
.check-page__content {
  &-group {
    &--title {
      font-size: 22px;
      font-weight: 700;
      color: $title;
      margin-bottom: 10px;
      &.network-name {
        position: relative;
        & .points-count {
          color: $text;
          font-size: 18px;
          position: absolute;
          top: -25%;
          margin-left: 2px;
        }
      }
    }
    &--item {
      display: flex;
      margin-bottom: 10px;
      &-key {
        min-width: 278px !important;
        &.point-name {
          color: $accent;
          font-weight: 700;
        }
      }
      &-value {
        margin-left: 30px;
        color: $title;
      }
    }
  }
  &-checkboxes {
    margin-top: 20px;
    margin-bottom: 30px;
    .checkbox {
      &:first-child {
        margin-bottom: 16px;
      }
    }
  }
}
@media (max-width: $tablet-width) {
  .check-page__title {
    margin-bottom: 10px;
  }
  .check-page__subtitle {
    margin-bottom: 15px;
    text-align: justify;
  }
  .check-page__content {
    &-group {
      &--item {
        flex-direction: column;
        &-value {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
