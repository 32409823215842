<template>
  <app-map />
</template>

<script>
import YMap from "@/components/YMap";

export default {
  name: "TestMapPage",
  components: { "app-map": YMap },
};
</script>

<style lang="scss"></style>
