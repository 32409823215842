<template>
  <div class="partner-products">
    <app-loader
      v-if="getLoadingStatus === 'pending'"
      :value="getLoadingStatus === 'pending'"
    />
    <div class="partner-products__tabs">
      <div
        :class="['partner-products__tabs-tab', { active: index === activeTab }]"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="setActiveTab(index)"
      >
        {{ tab.text }}
      </div>
    </div>
    <component
      :is="tabs[activeTab].component"
      :points="getPartnerPoints"
      :products="getPartnerProducts"
      @change-component="tabs[1].component = $event"
      @update:point="currentPoint = $event"
      @removed="this.setProductsByPoints(Object.keys(this.getPartnerPoints))"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductsMain from "@/components/Partner/products/ProductsMain";
import Preloader from "@/components/UI/Preloader";
import VoucherTable from "@/components/Products/VoucherTable";
import VoucherForm from "@/components/Products/VoucherForm";

export default {
  name: "PartnerProducts",
  components: {
    "app-products-main": ProductsMain,
    "app-voucher-form": VoucherForm,
    "app-voucher-table": VoucherTable,
    "app-loader": Preloader,
  },
  data: () => ({
    tabs: [
      {
        component: "app-products-main",
        id: 0,
        text: "Товары",
      },
      {
        component: "app-voucher-table",
        id: 1,
        text: "Параметры скидок",
      },
    ],
    activeTab: 0,
    currentPoint: "",
  }),
  async created() {
    await this.setPartnerPoints({ key: this.key });
    await this.setProductsByPoints(Object.keys(this.getPartnerPoints));
    if (!this.getPartnerProducts.length) {
      this.component = "app-new-product";
    }
    localStorage.setItem(
      `${process.env.VUE_APP_PROJECT_ID}PartnerToken`,
      this.key
    );
  },
  computed: {
    ...mapGetters("partner", ["getPartnerInfo", "getPartnerPoints"]),
    ...mapGetters("products", ["getPartnerProducts", "getLoadingStatus"]),
    key() {
      return this.$route.query.key;
    },
    points() {
      return this.getPartnerPoints;
    },
    activeComponent() {
      return this.component;
    },
  },
  methods: {
    ...mapActions("partner", ["setPartnerPoints"]),
    ...mapActions("products", ["addOneProduct", "setProductsByPoints"]),
    setImages(e) {
      this.product.images = e;
    },
    setCategories(e) {
      this.product.categories = e;
    },
    async submit() {
      await this.addOneProduct(this.product);
    },
    async setActiveComponent(e) {
      await this.setProductsByPoints(Object.keys(this.getPartnerPoints));
      this.component = e;
    },
    setActiveTab(idx) {
      this.activeTab = idx;
    },
  },
  watch: {
    async currentPoint() {
      if (this.currentPoint) {
        await this.setProductsByPoints([this.currentPoint]);
      } else {
        await this.setProductsByPoints(Object.keys(this.getPartnerPoints));
      }
    },
  },
};
</script>

<style lang="scss">
.partner-products {
  &__tabs {
    display: flex;
    align-items: start;
    &-tab {
      color: $accent;
      font-size: 25px;
      font-weight: 700;
      cursor: pointer;
      border-bottom: 1px dashed $accent-disabled;
      padding-bottom: 5px;
      transition: color $transition;
      &.active {
        color: $title;
        cursor: default;
        border-bottom: none;
        &:hover {
          color: $title;
        }
      }
      &:first-child {
        margin-right: 30px;
      }
      &:hover {
        color: $accent-hover;
      }
    }
  }
}
</style>
