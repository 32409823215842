<template>
  <div :class="['count-indicator', status]">
    <div
      class="count-indicator__icon"
      v-html="require(`@/assets/img/icons/amount-${status}.svg`)"
    />
    <div class="count-indicator__text">Осталось {{ available }} шт.</div>
  </div>
</template>

<script>
export default {
  name: "CountIndicator",
  props: {
    amount: {
      type: Number,
      required: true,
    },
    available: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    data: [],
  }),
  computed: {
    status() {
      if (this.available / this.amount > 0.5) {
        return "lot";
      } else if (
        this.available / this.amount < 0.5 &&
        this.available / this.amount > 0.2
      ) {
        return "mid";
      } else {
        return "low";
      }
    },
  },
};
</script>

<style lang="scss">
.count-indicator {
  display: flex;
  align-items: center;
  font-size: 12px;
  &.lot {
    color: $label-success;
  }
  &.mid {
    color: $bonus;
  }
  &.low {
    color: $like-button-hover;
  }
  &__text {
    margin-left: 4px;
  }
}
@media (min-width: $desktop-width) {
  .count-indicator {
    font-size: 14px;
    &__icon {
      height: 16px;
    }
  }
}
</style>
