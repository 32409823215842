<template>
  <h2>Ошибка сервера</h2>
  <p>
    Попробуйте перезагрузить страницу. Если ошибка возобновляется спустя пару
    минут, свяжитесь с тех. поддержкой:
    <span>
      <app-link link="tel:+78123221010">+7 (812) 322-10-10</app-link>
      |
      <app-link :link="`mailto:${supportEmail}`">{{ supportEmail }} </app-link>
    </span>
  </p>
</template>

<script>
import Link from "@/components/UI/Links/Link.vue";
import { mapState } from "vuex";

export default {
  name: "InternalError",
  components: {
    "app-link": Link,
  },
  computed: {
    ...mapState({ supportEmail: "supportEmail" }),
  },
};
</script>
