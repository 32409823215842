<template>
  <div class="voucher-form">
    <div class="voucher-form__title">Параметры для выпуска привилегий</div>
    <div class="voucher-form__text">
      Выберите и настройте привилегии для клиентов. Мы будем обновлять скидки,
      ваучеры и купоны каждый месяц. Вы также сможете редактировать параметры. В
      любой момент можно приостановить выпуск привилегий на каждую отдельную
      категорию.
    </div>
    <app-voucher-form-group
      :inputData="items.vouchers.loyalty"
      @update:valid="valid = $event"
      @update:value="setData($event)"
      type="loyalty"
    />
    <app-voucher-form-group
      :inputData="items.vouchers.vouchers"
      @update:valid="valid = $event"
      @update:value="setData($event)"
      type="vouchers"
    />
    <app-voucher-form-group
      :inputData="items.vouchers.coupons"
      @update:valid="valid = $event"
      @update:value="setData($event)"
      type="coupons"
    />
    <div class="voucher-form__buttons">
      <app-button
        class="voucher-form__buttons-button button-success"
        :disabled="!valid"
        @click="submit"
        >Выпустить</app-button
      >
      <app-button
        class="voucher-form__buttons-button button-cancel"
        mode="secondary"
        @click="toVoucherTable"
        >Отменить</app-button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
import VoucherFormGroup from "@/components/Products/VoucherFormGroup";
import { mapActions } from "vuex";

export default {
  name: "VoucherForm",
  components: {
    "app-button": Button,
    "app-voucher-form-group": VoucherFormGroup,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    valid: true,
    data: {},
  }),
  computed: {
    isEdit() {
      return !!this.items._id;
    },
  },
  methods: {
    ...mapActions("partner", ["addPointVouchers", "editPointVouchers"]),
    setValid(e) {
      console.log(e);
    },
    toVoucherTable() {
      this.$router.push({
        name: "PartnerProducts",
        query: {
          key: localStorage.getItem(
            `${process.env.VUE_APP_PROJECT_ID}PartnerToken`
          ),
        },
      });
    },
    setData(e) {
      this.data[e.value] = { ...e };
    },
    async submit() {
      if (!this.isEdit) {
        await this.addPointVouchers({
          data: [
            {
              ...this.data,
              retailPointId: this.items.retailPointId,
            },
          ],
        });
      } else {
        await this.editPointVouchers({
          data: [
            {
              ...this.data,
              retailPointId: this.items.retailPointId,
            },
          ],
        });
        await this.$router.push({
          name: "PartnerProducts",
          query: {
            key: localStorage.getItem(
              `${process.env.VUE_APP_PROJECT_ID}PartnerToken`
            ),
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.voucher-form {
  width: 850px;
  box-shadow: $popup-shadow;
  padding: 30px;
  background: $primary;
  border-radius: 30px;
  margin: 20px auto 10px;
  &__title {
    color: $title;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  &__group {
    padding: 30px;
    background: rgba(234, 238, 244, 0.3);
    border: 2px dashed $text-light;
    border-radius: 30px;
    margin-bottom: 10px;
    &.collapsed {
      .voucher-form__group-header {
        margin-bottom: 0;
      }
      .voucher-form__group-text {
        display: none;
      }
      .voucher-form__group-input__group {
        display: none;
      }
      .voucher-form__group-input {
        display: none;
      }
    }
    &-header {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      &-title {
        color: $accent;
        font-size: 20px;
        font-weight: 700;
        margin-right: 8px;
      }
      &-success-icon {
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &-text {
      margin-bottom: 15px;
    }
    &-input {
      max-width: 350px;
      &__group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 30px;
    &-button {
      max-width: 160px;
      &.button-success {
        margin-right: 30px;
      }
      &.button-cancel {
        height: 64px;
      }
    }
  }
}
</style>
