<template>
  <div
    class="stepper-form point-form"
    :class="{ invalid: !pointData.isValidForm }"
  >
    <div class="point-form__header">
      <div class="partner-products__form-general-header-text">
        <div
          v-if="pointData.isValidForm"
          class="point-form__header-text-icon-success"
          v-html="require(`@/assets/img/icons/success-fill.svg`)"
        />
        <div
          v-else
          class="point-form__header-text-icon-invalid"
          v-html="require(`@/assets/img/icons/warning.svg`)"
        />
        <div>
          {{ pointData.retailPointName }}
        </div>
      </div>
      <div class="point-form__header-icons">
        <div
          class="point-form__header-icon"
          v-html="require(`@/assets/img/icons/trash.svg`)"
          @click="$emit('delete', pointData)"
        />
        <div
          :class="[
            'point-form__header-icon arrow',
            { rotated: pointData.collapsed },
          ]"
          v-html="require(`@/assets/img/icons/arrow-top.svg`)"
          @click="pointData.collapsed = !pointData.collapsed"
        />
      </div>
    </div>
    <div class="point-form__body" :class="{ collapsed: pointData.collapsed }">
      <div class="subtitle">Информация о торговой точке</div>
      <div class="stepper-form__group">
        <app-input
          v-model:value="pointData.retailPointName"
          @input="checkUniqPointName"
          label="Название торговой точки"
          required
          :rules="retailPointNameRules"
          @update:valid="validInput = $event"
        />
        <app-input
          v-model:value="pointData.address.city"
          label="Город"
          required
          @update:valid="validInput = $event"
        />
      </div>
      <div class="stepper-form__group">
        <app-input
          v-model:value="pointData.address.street"
          label="Улица"
          required
          @update:valid="validInput = $event"
        />
        <app-input
          v-model:value="pointData.address.house"
          label="Дом"
          required
        />
      </div>
      <div class="subtitle">Телефон кассира</div>
      <div class="stepper-form__group">
        <app-input
          v-model:value="pointData.cashier.phone"
          mode="phone"
          label="Телефон кассира для бота"
          required
          @update:valid="validInput = $event"
        />
        <app-input
          v-model:value="pointData.cashier.name"
          label="Имя"
          required
          @update:valid="validInput = $event"
        />
      </div>
      <div class="subtitle">Контактная информация</div>
      <div class="stepper-form__group">
        <app-input
          v-model:value="pointData.phone"
          mode="phone"
          label="Телефон"
          required
          @update:valid="validInput = $event"
        />
        <app-input
          mode="url"
          v-model:value="pointData.webAddress"
          @update:valid="validInput = $event"
          label="Сайт"
        />
      </div>

      <app-input v-model:value="pointData.workingTime" label="Режим работы" />
      <app-input v-model:value="pointData.description" label="Описание" />
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
import pointDataInterface from "../../../store/modules/partner/interface/pointDataInterface";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "PointForm",
  components: {
    "app-input": Input,
  },
  props: {
    point: {
      type: Object,
      required: true,
      default: () => {
        return _cloneDeep(pointDataInterface);
      },
    },
    sourcePointsData: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  created() {
    this.pointData = { ...this.pointData, ...this.point };
    if (!this.pointData.retailPointName) {
      this.pointData.retailPointName = `Новая Точка ${this.sourcePointsData.length}`;
    }
  },
  mounted() {
    this.retailPointNameRules = [
      (val) =>
        val.length <= 30 || `Максимум 30 символов , сейчас ${val.length}`,
      () => this.isUniqPointName || `Такое название точки уже есть`,
    ];
    this.checkValid();
  },
  data: () => ({
    validInput: false,
    pointData: pointDataInterface,
    isCollapse: true,
    retailPointNameRules: [],
    isUniqPointName: true,
  }),
  methods: {
    checkValid: function () {
      if (!this.validInput) {
        this.updatePointData(false);
        return;
      }
      this.checkUniqPointName();
      if (!this.isUniqPointName) {
        this.updatePointData(false);
        return;
      }

      const valuesIsNotEmpty = [
        ...Object.values(this.pointData.address),
        ...Object.values(this.pointData.cashier),
      ].every((i) => !!i);

      if (!valuesIsNotEmpty) {
        this.updatePointData(false);
        return;
      }

      const isValidPhones =
        this.pointData.cashier.phone.length === 11 &&
        this.pointData.phone.length === 11;

      if (!isValidPhones) {
        this.updatePointData(false);
        return;
      }

      this.updatePointData(true);
    },
    checkUniqPointName: function () {
      const sameNamesArr = this.sourcePointsData.filter((item) => {
        return (
          item.retailPointName.trim() === this.pointData.retailPointName.trim()
        );
      });
      this.isUniqPointName = !(sameNamesArr.length > 1);
    },
    updatePointData(isValid = false) {
      this.pointData.isValidForm = isValid;
      this.$emit("update:valid-point", this.pointData);
    },
  },
  watch: {
    pointData: {
      deep: true,
      handler() {
        this.$emit("update:info", this.pointData);
        this.checkValid();
      },
    },
    validInput: {
      handler() {
        this.checkValid();
      },
    },
  },
};
</script>

<style lang="scss">
.point-form {
  box-sizing: border-box;
  padding: 40px;
  background: rgba(234, 238, 244, 0.3);
  border-radius: 30px;
  border: 2px dashed $text-light;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  &.invalid {
    border: 2px dashed $error;
  }
  &__header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text {
      &-icon {
        &-success,
        &-invalid {
          height: 35px;
          width: 35px;
          margin-right: 10px;
          & svg {
            height: 100%;
            width: 100%;
            display: block;
          }
        }
        &-success {
        }
        &-invalid {
          fill: $error;
        }
      }
    }
    &-icons {
      display: flex;
    }
    &-icon {
      cursor: pointer;
      &.arrow {
        margin-left: 30px;
        transition: $transition;
        &.rotated {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__body {
    margin-top: 1.5rem;
    &.collapsed {
      display: none;
    }
  }
  &__button {
    margin-bottom: 30px;
  }
}
</style>
