const getDefaultState = () => {
  return {
    value: false,
    name: "internal",
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_ERROR(state, data) {
      state.value = data.value;
      state.name = data.name;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    toggleError({ commit, dispatch, getters }, data) {
      commit("SET_ERROR", data);

      const showModal = getters.errorModal ? data.value : false;
      dispatch(
        "modal/toggleModal",
        { name: "error", value: showModal },
        { root: true }
      );
    },
  },
  getters: {
    value: (state) => state.value,
    name: (state) => state.name,
    error: (state) => {
      return (
        state.value &&
        ["internal", "not-found", "empty-cart"].indexOf(state.name) !== -1
      );
    },
    errorModal: (state) => {
      return (
        state.value && ["draft", "payment-type"].indexOf(state.name) !== -1
      );
    },
  },
};
