<template>
  <div class="voucher-edit">
    <app-voucher-form :items="items" />
  </div>
</template>

<script>
import VoucherForm from "@/components/Products/VoucherForm";
import { mapGetters } from "vuex";

export default {
  name: "VoucherEdit",
  components: {
    "app-voucher-form": VoucherForm,
  },
  computed: {
    ...mapGetters("partner", ["getPointVouchers"]),
    pointId() {
      return this.$route.params.point_id;
    },
    items() {
      return this.getPointVouchers;
    },
  },
};
</script>
