<template>
  <div class="voucher-card">
    <div class="right-top-bottom"></div>
    <div class="left-top-bottom"></div>
    <div class="middle"></div>
    <div class="voucher-card__image">
      <app-labels
        class="voucher-card__image-label"
        label-type="CUSTOM"
        :card="{ customType: 'Скидка при покупке' }"
        type="voucher"
      />
      <img
        v-if="item.retailPointImage"
        class="voucher-card__image-img"
        :src="item.retailPointImage"
        alt="voucher"
      />
      <img
        v-else
        class="voucher-card__image-img"
        src="@/assets/img/empty-photo.png"
        alt="voucher"
      />
    </div>
    <div class="voucher-card__content">
      <div class="voucher-card__content-title">
        {{ type === "voucher" ? "Ваучер" : "Купон" }} на скидку
        {{ item.discount }}%
      </div>
      <div class="voucher-card__content-type">При оплате в точке</div>
      <div class="voucher-card__content-date" v-if="type === 'voucher'">
        Действует до {{ new Date(item.activationDate).toLocaleDateString() }}
      </div>
      <div class="voucher-card__content-count" v-else-if="type === 'coupon'">
        <app-count-indicator
          :amount="item.globalCount"
          :available="item.freeCount"
        />
      </div>
      <router-link
        class="voucher-card__content-point"
        :to="`/partner-info/${item.retailPointId}`"
        >{{ item.retailPointTitle }}</router-link
      >
      <div class="voucher-card__content-point-address">
        {{ item.retailPointAddress }}
      </div>
      <app-button
        class="voucher-card__content-button"
        mode="secondary"
        :size="mobView ? 's' : 'm'"
        @click="showPopup"
        >Подробнее</app-button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
import Labels from "@/components/UI/Labels";
import CountIndicator from "@/components/UI/CountIndicator";
import { mapActions } from "vuex";

export default {
  name: "VoucherCard",
  components: {
    "app-button": Button,
    "app-labels": Labels,
    "app-count-indicator": CountIndicator,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "voucher",
    },
  },
  computed: {
    mobView() {
      return window.screen.width <= 768;
    },
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    showPopup() {
      this.toggleModal({
        name: this.type,
        value: true,
      });
    },
  },
};
</script>

<style lang="scss">
.voucher-card {
  margin-top: 20px;
  box-sizing: border-box;
  background: $primary;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  position: relative;
  border-radius: 15px;
  &__image {
    width: 122px;
    height: 122px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    margin-right: 10px;
    &-label {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    &-title {
      color: $label-hot;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    &-type {
      font-size: 12px;
      margin-bottom: 4px;
    }
    &-date {
      color: $label-success;
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 8px;
    }
    &-point {
      color: $title;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 4px;
    }
    &-point-address {
      font-size: 12px;
      margin-bottom: 4px;
    }
    &-button {
      max-width: 105px;
    }
  }
  & .right-top-bottom {
    position: absolute;
    height: 100%;
    top: -10px;
    bottom: -10px;
    right: -10px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $secondary;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -20px;
      right: 0;
      overflow: hidden;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $secondary;
    }
  }
  & .left-top-bottom {
    position: absolute;
    height: 100%;
    top: -10px;
    bottom: -10px;
    left: -10px;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $secondary;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      overflow: hidden;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $secondary;
    }
  }
  & .middle {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      overflow: hidden;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $secondary;
    }
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: -10px;
      overflow: hidden;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $secondary;
    }
  }
}
@media (min-width: $desktop-width) {
  .voucher-card {
    &__image {
      width: 150px;
      height: 150px;
    }
    &__content {
      &-title {
        font-size: 22px;
      }
      &-type {
        font-size: 14px;
      }
      &-date {
        font-size: 14px;
        line-height: 17px;
      }
      &-point {
        font-size: 14px;
      }
      &-point-address {
        font-size: 14px;
      }
      &-button {
        max-width: 160px;
        margin-left: -25%;
      }
    }
  }
}
</style>
