const filters = {
  price(v) {
    if (!v.toString().length) return "";
    return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  login(v) {
    if (!v) return "";

    return `+7 (XXX) XXX-${v.substring(
      v.length - 4,
      v.length - 2
    )}-${v.substring(v.length - 2)}`;
  },
  monthDeclension(v) {
    function num_word(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if (value > 10 && value < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    }

    return num_word(v, ["месяц", "месяца", "месяцев"]);
  },
};
export default filters;
