import { categoriesConfig } from "@/configs/categories";
import { productsApi } from "@/api/products";

const projectId = () => {
  return process.env.VUE_APP_PROJECT_ID;
};

export default {
  namespaced: true,
  state: {
    products: [],
    singleProduct: {},
    productToPay: JSON.parse(localStorage.getItem("selectedToPay")) || null,
    productsQuantity: 0,
    categories: [],
    activeCategory: 0,
    activePage: 0,
    isLastPage: false,
    isLoading: false,
    projectId: projectId(),
    randomProducts: [],
    ratioProducts: {},
    partnerProducts: [],
  },
  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    SET_PRODUCTS(state, payload) {
      if (payload.products) {
        if (payload.num === 0) {
          state.products = payload.products;
        } else {
          state.products = [...state.offers, ...payload.products];
        }
      }
    },
    SET_CURRENT_PAGE(state, page) {
      state.activePage = page;
    },
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status;
    },
    SET_END_PRODUCTS(state, endStatus) {
      state.isLastPage = endStatus;
    },
    SET_SINGLE_PRODUCT(state, product) {
      state.singleProduct = product;
    },
    SET_PRODUCT_TO_PAY(state, product) {
      state.productToPay = product;
    },
    SET_ACTIVE_CATEGORY(state, id) {
      state.activeCategory = id;
    },
    SET_PRODUCTS_QUANTITY(state, num) {
      state.productsQuantity = num;
    },
    SET_RANDOM_PRODUCTS(state, data) {
      state.randomProducts = data;
    },
    SET_PARTNER_PRODUCTS(state, data) {
      state.partnerProducts = data;
    },
    SET_RATIO_PRODUCTS(state, { ratio, data }) {
      state.ratioProducts[ratio] = data;
    },
  },
  actions: {
    async loadProducts({ commit, state, rootGetters }, pointId) {
      if (state.isLastPage) return false;
      commit("SET_LOADING_STATUS", true);
      let page = {
        num: state.activePage,
        limit: 9,
      };
      if (rootGetters["getCatalogView"] === "map") {
        page = {
          num: 0,
          limit: 1000000,
        };
        commit("SET_END_PRODUCTS", true);
      }
      const data = {
        productType: "product",
        projectId: state.projectId,
        page,
        categoryId: state.activeCategory,
      };

      try {
        const products = await productsApi.getProducts(data, pointId);
        if (products && products.data.length) {
          commit("SET_PRODUCTS", { products: products.data, num: page.num });
        } else {
          commit("SET_END_PRODUCTS", true);
        }
        commit("SET_CURRENT_PAGE", state.activePage + 1);
      } catch (e) {
        console.log(e);
      } finally {
        commit("SET_LOADING_STATUS", false);
      }
    },
    async loadSinglePartnerProduct({ commit }, id) {
      try {
        const product = await productsApi.singleProduct(id);
        commit("SET_SINGLE_PRODUCT", product.data[0]);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async loadRandomProducts({ commit }, count) {
      try {
        const res = await productsApi.getRandomProducts(count);
        commit("SET_RANDOM_PRODUCTS", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async loadProductsByRatio({ state, commit }, data) {
      if (state.ratioProducts[data.ratio]) return;
      try {
        const res = await productsApi.getRatioProducts(data);
        commit("SET_RATIO_PRODUCTS", { ratio: data.ratio, data: res.data });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    setProductToPay({ commit }, data) {
      localStorage.setItem("selectedToPay", JSON.stringify(data));
      commit("SET_PRODUCT_TO_PAY", data);
    },
    resetSingleProduct({ commit }) {
      commit("SET_SINGLE_PRODUCT", {});
    },
    async loadCategories({ commit, state }) {
      let qtyCounter = 0;
      const cats = await productsApi.categories(state.projectId, "product");
      const newCategories = categoriesConfig.map((item) => {
        cats.data.forEach((cat) => {
          if (item.id === cat.id) {
            item.title = cat.title;
            item.quantity = cat.quantity;
          }
          qtyCounter += cat.quantity;
        });
        if (item.id === 0) {
          item.isActive = true;
          item.quantity = qtyCounter;
          commit("SET_PRODUCTS_QUANTITY", qtyCounter);
        }
        return item;
      });

      commit("SET_CATEGORIES", newCategories);
    },
    changeActiveCategory({ commit, state, dispatch }, id) {
      const updatedCategories = state.categories.map(({ ...cat }) => {
        cat.isActive = cat.id === id;
        return cat;
      });
      commit("SET_ACTIVE_CATEGORY", id);
      commit("SET_CATEGORIES", updatedCategories);
      commit("SET_CURRENT_PAGE", 0);
      commit("SET_PRODUCTS", { products: [], num: 0 });
      commit("SET_END_PRODUCTS", false);
      dispatch("loadProducts");
    },
    async addOneProduct({ commit, dispatch }, data) {
      commit("SET_LOADING_STATUS", "pending");
      try {
        const images = await dispatch("addProductImages", data.images);
        data.images = images;
        const res = await productsApi.addProduct(data);
        console.log(res);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        commit("SET_LOADING_STATUS", "ok");
      }
    },
    async addProducts({ commit, dispatch }, data) {
      commit("SET_LOADING_STATUS", "pending");
      try {
        await Promise.all(
          data.map(async (product) => {
            const images = await dispatch("addProductImages", product.images);
            product.images = images;
          })
        );
        await productsApi.addProducts(data);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        commit("SET_LOADING_STATUS", "ok");
      }
    },
    async addProductImages(_, data) {
      let formData = new FormData();
      data.forEach((item) => {
        formData.append("images", item);
      });
      try {
        const res = await productsApi.addProductImages(formData);
        return res.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async editOneProduct({ dispatch }, item) {
      let uploadedImgs = [];
      let editImgs = [];
      item.images.forEach((img) => {
        if (typeof img === "string") {
          uploadedImgs.push(img);
        } else {
          editImgs.push(img);
        }
      });
      try {
        if (editImgs.length) {
          const imgs = await dispatch("addProductImages", editImgs);
          item.images = [...uploadedImgs, ...imgs];
        }
        await productsApi.editOneProduct(item);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async setProductsByPoints({ commit }, ids) {
      try {
        const res = await productsApi.getProductsByPoints(ids);
        commit("SET_PARTNER_PRODUCTS", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async removeProductsByIds({ commit }, ids) {
      commit("SET_LOADING_STATUS", "pending");
      try {
        await productsApi.removeProductsByIds(ids);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        commit("SET_LOADING_STATUS", "ok");
      }
    },
  },
  getters: {
    // Products
    getProducts: (state) => state.products,
    getRandomProductsByRatio: (state) => {
      return (ratio) => {
        return state.productsByRatio.filter((product, index) => {
          if (index >= 6) return;
          return product.price.ratio === ratio;
        });
      };
    },
    getRandomProducts: (state) => state.randomProducts,
    getPartnerProductsCount: (state) => {
      return (pointId) => {
        const product = state.products.find((item) => {
          return item.retailPointId === pointId;
        });
        if (product) return product.count;
        else return 0;
      };
    },
    getLoadingStatus: (state) => state.isLoading,
    getEndProducts: (state) => state.isLastPage,
    getSingleProduct: (state) => state.singleProduct,
    getProductToPay: (state) => state.productToPay,
    getProductsQuantity: (state) => state.productsQuantity,
    // Categories
    getCategories: (state) => state.categories,
    getActiveCategory: (state) => state.activeCategory,
    getCategoryById: (state) => (id) => {
      return state.categories.find((cat) => cat.id === +id);
    },
    getProductsByPointId: (state) => (id) => {
      return state.products.find((item) => item.retailPointId === +id);
    },
    getPartnerProducts: (state) => state.partnerProducts,
    getProductsList: (state) => {
      const products = [];
      state.products.forEach((item) => {
        products.push(...item.products);
      });
      return products;
    },
    getProductsByRatio: (state) => (ratio) => {
      return state.ratioProducts[ratio];
    },
    getProjectId: (state) => state.projectId,
  },
};
