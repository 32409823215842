<template>
  <div class="complete-success">
    <span
      class="complete-success__icon"
      v-html="require('@/assets/img/icons/success.svg')"
    />

    <h1>Оплата прошла успешно</h1>

    <span class="complete-success__subtext">
      На вашу почту отправлено письмо с заказом
    </span>

    <div
      :class="['complete-success__form', { form: completeResponse.chequeUrl }]"
    >
      <div
        v-if="completeResponse.chequeUrl"
        class="complete-success__form-header"
      >
        <span>Чек об оплате</span>
        <app-link :link="completeResponse.chequeUrl">Открыть</app-link>
      </div>

      <app-button @click="goToCatalog">Перейти к покупке товаров</app-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Link from "@/components/UI/Links/Link";
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "CompleteSuccess",
  components: {
    "app-button": Button,
    "app-link": Link,
  },
  computed: {
    ...mapGetters("payment", ["completeResponse"]),
  },
  methods: {
    goToCatalog() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang="scss">
.complete-success {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__icon {
    display: block;
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    svg {
      fill: $label-success;
      width: inherit;
      height: inherit;
    }
  }
  &__subtext {
    margin-top: 10px;
  }
  &__form {
    margin-top: 60px;
    width: 410px;
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
  }
}
@media (max-width: $desktop-width) {
  .complete-success {
    &__form {
      margin-top: 30px;
      width: 260px;
      padding: 15px !important;
    }
  }
}
</style>
