<template>
  <teleport to="body">
    <div class="modal-wrapper">
      <transition name="loading">
        <div
          v-if="showModal"
          :class="['modal', name === 'qr' ? 'qr-modal' : '']"
        >
          <button
            v-if="
              ['payment', 'promocode', 'auth', 'qr'].indexOf(name) !== -1 &&
              !permanent
            "
            class="modal__close"
            @click="close"
          >
            <span v-html="require(`@/assets/img/icons/close.svg`)" />
          </button>

          <component
            v-model:value="showModal"
            :is="`app-${name}-modal`"
            :key="name"
          />
        </div>
      </transition>
    </div>
  </teleport>
  <teleport to="body">
    <transition name="fade">
      <div v-if="showModal" class="overlay" @click="close" />
    </transition>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import PaymentModal from '@/components/home/payment/PaymentModal.vue'
import PromocodeModal from "@/components/UI/Modals/components/PromocodeModal.vue";
import AuthModal from "@/components/UI/Modals/components/Auth/AuthModal.vue";
import ErrorModal from "@/components/Error/Error.vue";
import Overlay from "@/components/UI/Overlay.vue";
import QR from "@/components/UI/Modals/components/QR";
import Voucher from "@/components/UI/Modals/components/Voucher";
import Coupon from "@/components/UI/Modals/components/Coupon";
const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default {
  name: "Modal",
  components: {
    // 'app-payment-modal': PaymentModal,
    "app-promocode-modal": PromocodeModal,
    "app-auth-modal": AuthModal,
    "app-qr-modal": QR,
    "app-error-modal": ErrorModal,
    "app-overlay": Overlay,
    "app-voucher-modal": Voucher,
    "app-coupon-modal": Coupon,
  },
  props: {
    closeIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters("modal", ["value", "name", "permanent"]),
    showModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.toggleModal({
          name: this.name,
          value,
        });
      },
    },
  },
  watch: {
    value() {
      const body = document.querySelector("body");
      if (this.value) {
        disableBodyScroll(body);
      } else {
        enableBodyScroll(body);
      }
    },
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    close() {
      if (!this.permanent) {
        this.showModal = false;
      }
    },
  },
};
</script>

<style lang="scss">
// header and footer are fixed
// main is scrollable

.modal-wrapper {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  //width: 314px;
  width: calc(100vw - 6px);
  height: auto;
  max-height: calc(100vh - 50px);
  padding: 20px 0 30px;
  background: $primary;
  border-radius: $border-radius;
  &__close {
    position: absolute;
    top: 26px;
    right: 15px;
    width: 16px;
    height: 16px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: 100%;
        fill: $text;
      }
    }
  }
  header {
    padding: 0 15px 10px;
  }
  main {
    z-index: 11;
    height: auto;
    max-height: calc(100vh - 150px);
    margin: 0 4px; // чтобы скролл не прилипал к краю
    padding: 5px 11px 5px; // 11px+4px margin слева и справа
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $text-input;
      border-radius: $border-radius;
    }
  }
  footer {
    padding: 10px 15px 0;
  }
}

.qr-modal {
  max-width: 256px;
  max-height: 276px;
  padding: 20px !important;
}

@media (min-width: $desktop-width) {
  .modal {
    width: 480px;
    padding: 35px 0;
    border-radius: $border-radius-desktop;
    &__close {
      top: -10px;
      right: -25px;
      width: 20px;
      height: 20px;
      span {
        svg {
          fill: $primary;
        }
      }
    }
    header {
      padding: 0 30px 10px;
    }
    main {
      max-height: calc(100vh - 250px);
      padding: 5px 26px 5px; // 26px+4px margin слева и справа
    }
    footer {
      padding: 10px 30px 0;
    }
  }
}
</style>
