import { server } from "@/api/index";

export let cartApi = {
  getCart() {
    return server.post("/cart");
  },
  editCart(data) {
    return server.post("/cart/edit", data);
  },
};
