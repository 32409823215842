<template>
  <app-overlay :value="value" />

  <app-spinner v-if="value" mode="dark" size="l" fixed />
</template>

<script>
import Spinner from "@/components/UI/Spinner.vue";
import Overlay from "@/components/UI/Overlay.vue";

export default {
  name: "Preloader",
  components: {
    "app-spinner": Spinner,
    "app-overlay": Overlay,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
