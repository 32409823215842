export default {
  methods: {
    parseUrlQuery() {
      const search = location.search.substring(1);
      if (search.length) {
        return JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
      }
      return {};
    },
  },
};
