<template>
  <div class="start-page__block">
    <div
      class="start-page__block-logo"
      v-html="require(`@/assets/img/icons/sbp-black.svg`)"
    />
    <div
      class="start-page__block-bg"
      v-html="require(`@/assets/img/start-block-bg-1.svg`)"
    />
    <div class="start-page__block-header">
      <div class="start-page__block-header-titles">
        <div class="start-page__block-header-title">
          Эквайринг для бизнеса по ставке
        </div>
        <div class="start-page__block-header-subtitle">0,7%</div>
      </div>
      <div
        class="start-page__block-header-img"
        v-html="require(`@/assets/img/icons/partner-start-1.svg`)"
      />
    </div>
    <div class="start-page__block-content">
      <div
        class="start-page__block-content-item"
        v-for="(rule, index) in rules"
        :key="index"
      >
        <div
          class="start-page__block-content-item-img"
          v-html="require(`@/assets/img/icons/${rule.icon}`)"
        />
        <div class="start-page__block-content-item-text">
          {{ rule.text }}
        </div>
      </div>
    </div>
    <div class="start-page__block-title">Что нужно для подключения?</div>
    <div class="start-page__block-content content-connection">
      <div
        class="start-page__block-content-item"
        v-for="(rule, index) in connection"
        :key="index"
      >
        <div
          class="start-page__block-content-item-img"
          v-html="require(`@/assets/img/icons/${rule.icon}`)"
        />
        <div class="start-page__block-content-item-text">
          {{ rule.text }}
        </div>
      </div>
    </div>
    <div class="start-page__block-separator" />
    <div class="start-page__block-title">Как это работает?</div>
    <div class="start-page__block-content">
      <div
        class="start-page__block-content-item"
        v-for="(rule, index) in howItWork"
        :key="index"
      >
        <div
          class="start-page__block-content-item-img"
          v-html="require(`@/assets/img/icons/${rule.icon}`)"
        />
        <div class="start-page__block-content-item-text">
          {{ rule.text }}
        </div>
        <div class="start-page__block-content-item-step">{{ index + 1 }}</div>
      </div>
    </div>
    <div class="start-page__block-warning">
      <div
        class="start-page__block-warning-icon"
        v-html="require('@/assets/img/icons/warning.svg')"
      />
      <div class="start-page__block-warning-text">
        Для завершения регистрации вам необходимо будет отсканировать и
        подписать заявление-согласие и прислать нам на электронную почту.
      </div>
    </div>
    <div class="start-page__block-button">
      <router-link to="/partner/create"
        ><app-button class="start-page__button"
          >Зарегистрироваться</app-button
        ></router-link
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "StartPageBlockTop",
  components: {
    "app-button": Button,
  },
  data: () => ({
    rules: [
      {
        icon: "acquire.svg",
        text: "Ставка по эквайрингу 0,7%",
      },
      {
        icon: "30-min.svg",
        text: "Подключение к системе за 30 минут",
      },
      {
        icon: "check.svg",
        text: "Не нужно открывать новый счет в банке-эквайере",
      },
    ],
    connection: [
      {
        icon: "list.svg",
        text: "Зарегистрироваться Прислать скан заявки на почту",
      },
      {
        icon: "QR.svg",
        text: "Распечатать QR-код и разместить его на кассе",
      },
    ],
    howItWork: [
      {
        icon: "scan_me.svg",
        text: "Клиент сканирует QR-код и попадает на платёжную страницу",
      },
      {
        icon: "pay.svg",
        text: "Клиент вводит сумму покупки и платёж проходит через СБП (C2B)",
      },
      {
        icon: "cash.svg",
        text: "Платформа отправляет денежный перевод на счёт компании на следующий день",
      },
    ],
  }),
};
</script>
