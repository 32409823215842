<template>
  <header :class="['header', header ? '' : 'header-center']">
    <div class="header__left" v-if="header">
      <router-link to="/">
        <span
          v-html="require(`@/assets/img/icons/${projectId}/logo-mobile.svg`)"
        />
        <span
          v-html="require(`@/assets/img/icons/${projectId}/logo-desktop.svg`)"
        />
      </router-link>
      <!--      <Favourites />-->
    </div>

    <!--    <router-link to="/map"><h3>КАРТА</h3></router-link>-->

    <div class="header__right" v-if="header">
      <router-link class="header__right-partner__link" to="/partner"
        ><app-link size="m" icon="partner">{{
          mobView ? "" : "Стать партнером"
        }}</app-link></router-link
      >
      <app-balance v-if="isAuthorized" />
      <app-header-cart />
      <app-header-login :is-authorized="isAuthorized" @click="login" />
      <app-header-logout v-if="isAuthorized" @click="out" />
    </div>

    <div class="header__center" v-if="!header">
      <router-link to="/">
        <span
          v-html="require(`@/assets/img/icons/${projectId}/logo-desktop.svg`)"
        />
      </router-link>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Login from "@/components/UI/Header/Login";
import Logout from "@/components/UI/Header/Logout";
import Cart from "@/components/UI/Header/Cart";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import Balance from "@/components/UI/Header/Balance";
// import Favourites from "@/components/UI/Header/Favourites";

export default {
  name: "Header",
  components: {
    // Favourites,
    "app-header-cart": Cart,
    "app-header-logout": Logout,
    "app-header-login": Login,
    "app-link": ButtonLink,
    "app-balance": Balance,
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    ...mapActions("auth", ["logout"]),
    login() {
      if (this.isAuthorized) {
        this.$router.push("/cabinet");
      } else {
        this.toggleModal({
          name: "auth",
          value: true,
        });
      }
    },
    out() {
      this.logout();
    },
  },
  computed: {
    ...mapGetters("auth", {
      isAuthorized: "isAuthorized",
    }),
    projectId() {
      return process.env.VUE_APP_PROJECT_ID;
    },
    header() {
      return this.$route.meta.header !== false;
    },
    mobView() {
      return window.screen.width <= 768;
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: rgba(239, 240, 243, 0.92);
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(16px);
  border-bottom: 1px solid rgba(126, 125, 142, 0.2);
  &-center {
    justify-content: space-around;
  }
  h3 {
    padding: 15px 0;
  }
  &__left {
    display: flex;
    align-items: center;

    a {
      span {
        &:first-child {
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    .login {
      margin-left: 30px;
    }
    .logout {
      margin-left: 5px;
    }
    &-partner__link {
      margin-right: 10px;
    }
  }
}
@media (max-width: $desktop-width) {
  .header {
    padding: 15px;
    &__right {
      .login {
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .header {
    &__left {
      a {
        span {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: block;
          }
        }
      }
    }
    &__right {
      &-partner__link {
        margin-right: 60px;
      }
    }
  }
}
</style>
