<template>
  <section class="cart-order form">
    <header>
      <h3>Оформление заказа</h3>
    </header>

    <app-promocode v-if="!user.subscription" />

    <app-payment-draft />

    <app-button @click="next">Перейти к оформлению</app-button>

    <p>Доступные способы оплаты товаров можно выбрать при оформлении заказа</p>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Promocode from "@/components/UI/Promocode";
import PaymentDraft from "@/components/Payment/Components/Draft";
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "CartOrder",
  components: {
    "app-promocode": Promocode,
    "app-payment-draft": PaymentDraft,
    "app-button": Button,
  },
  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("order", ["order"]),
    ...mapGetters("user", ["user"]),
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    next() {
      const redirect = { name: "Payment", params: { id: this.order.id } };

      if (this.isAuthorized) {
        this.$router.push(redirect);
      } else {
        this.toggleModal({
          name: "auth",
          value: true,
          // redirect,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.cart-order {
  top: 50px;
  position: sticky !important;

  & > p {
    margin-top: 10px;
  }
}

@media (min-width: $desktop-width) {
  .cart-order {
    .button {
      margin-top: 25px;
    }

    & > p {
      margin-top: 15px;
    }
  }
}
</style>
