<template>
  <div
    class="tab"
    :style="styleObject"
    :class="{ active: tabInfo.isActive }"
    @click="changeActive"
  >
    <div
      class="tab__icon"
      v-if="tabInfo.icon"
      v-html="require(`@/assets/img/icons/${tabInfo.icon}.svg`)"
    ></div>
    <span class="tab__title">{{ tabInfo.title }}</span>
    <span v-if="tabInfo.quantity" class="tab__count">{{
      tabInfo.quantity
    }}</span>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    tabInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    changeActive() {
      this.$emit("changeActive", this.tabInfo.id);
    },
  },
  computed: {
    styleObject() {
      return {
        "--tab-color": this.tabInfo.color,
        "--tab-border-color": this.tabInfo.borderColor,
      };
    },
  },
};
</script>

<style lang="scss">
.tab {
  padding: 15px;
  width: fit-content;
  background-color: var(--tab-color);
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  border: 1px solid var(--tab-border-color);
  color: $title;
  font-size: 18px;
  line-height: $line-height-root;
  font-weight: 600;
  transition: $transition;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
  }
  &:hover,
  &.active {
    background-color: var(--tab-border-color);
    color: $primary;
    .tab__icon {
      svg {
        fill: $primary;
      }
    }
  }
  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    svg {
      fill: var(--tab-border-color);
      transition: $transition;
    }
  }
  &__count {
    margin-left: 5px;
    font-weight: 400;
  }
}
</style>
