<template>
  <div v-show="offers.length" class="loadObserver"></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LoadOnScroll",
  props: ["options"],
  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, options);
    this.observer.observe(this.$el);
  },
  unmounted() {
    this.observer.disconnect();
  },
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    ...mapGetters("offers", {
      offers: "getOffers",
    }),
  },
};
</script>

<style scoped lang="scss">
.loadObserver {
  width: 1px;
  height: 1px;
}
</style>
