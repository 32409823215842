<template>
  <h2>Ошибка с выбором оплаты</h2>
  <p>Если ошибка возобновляется, попробуйте изменить тип оплаты</p>
</template>

<script>
export default {
  name: "PaymentTypeError",
};
</script>
