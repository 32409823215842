<template>
  <main class="app-container cart">
    <app-breadcrumbs current-page="Корзина" />

    <h1>
      Корзина
      <sup v-if="count > 0">{{ count }}</sup>
    </h1>

    <article v-if="getUserStatus === 'ok' && !empty">
      <aside>
        <app-cart-tariff v-if="!user.subscription" />
        <app-cart-goods v-if="count > 0" />
      </aside>

      <app-cart-order />
    </article>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumbs from "@/components/UI/Breadcrumbs";
import CartTariff from "@/components/Cart/Tariff/Tariff.vue";
import CartGoods from "@/components/Cart/Goods/Goods.vue";
import CartOrder from "@/components/Cart/Order/Order.vue";

export default {
  name: "CartPage",
  components: {
    "app-breadcrumbs": Breadcrumbs,
    "app-cart-tariff": CartTariff,
    "app-cart-goods": CartGoods,
    "app-cart-order": CartOrder,
  },
  created() {
    if (this.empty) {
      this.toggleError({
        value: this.empty,
        name: "empty-cart",
      });
    }
  },
  computed: {
    ...mapGetters("user", ["user", "getUserStatus"]),
    ...mapGetters("cart", ["count", "cart"]),
    ...mapGetters("error", ["error"]),
    ...mapGetters(["getLoadingStatus"]),
    empty() {
      return this.count === 0 && !!this.user.subscription;
    },
  },
  watch: {
    user: {
      deep: true,
      async handler() {
        console.log("user status", this.getUserStatus);
        await this.getCart();

        if (this.empty) {
          this.toggleError({
            value: this.empty,
            name: "empty-cart",
          });
        }
      },
    },
    cart: {
      deep: true,
      handler() {
        if (this.empty) {
          this.toggleError({
            value: this.empty,
            name: "empty-cart",
          });
        }
      },
    },
  },
  methods: {
    ...mapActions("cart", ["getCart"]),
    ...mapActions("error", ["toggleError"]),
  },
};
</script>

<style lang="scss">
.cart {
  padding-top: 30px;
  padding-bottom: 40px;

  h1 {
    margin-bottom: 20px;
    sup {
      font-size: 30px;
      color: $text;
    }
  }

  & > article {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    align-items: start;

    & > aside {
      display: grid;
      align-items: start;
      grid-auto-rows: max-content max-content;
      grid-row-gap: 10px;
    }

    & > aside > section,
    & > section {
      max-width: 450px;
      align-self: start;
    }
  }
}

@media (min-width: $desktop-width) {
  .cart {
    padding-top: 0;
    padding-bottom: 60px;

    & > article {
      grid-template-columns: 0.588fr 0.422fr;
      grid-column-gap: 30px;

      & > aside {
        grid-row-gap: 20px;
        grid-auto-rows: max-content;
      }

      & > aside > section,
      & > section {
        max-width: 100%;
      }
    }
  }
}
</style>
