import { server } from "@/api/index";

export const partnerApi = {
  searchByINN(data) {
    return server.post("/juridical/inn", data);
  },
  searchByBIK(data) {
    return server.post("/juridical/bik", data);
  },
  getPartnerByINN(data) {
    return server.get(`/juridical?inn=${data}`);
  },
  updatePartner(data) {
    return server.post("/juridical/update", data);
  },
  registerPartner(data) {
    return server.post("/juridical/register", data);
  },
  getInfo(id) {
    return server.get(`/juridical/info/${id}`);
  },
  searchPoints(data) {
    return server.post("/juridical/search/points", data);
  },
  setPartnerPointImage(data) {
    return server.post("/juridical/point/image", data);
  },
  getPointVouchers(pointId) {
    return server.get(`/juridical/vouchers?point_id=${pointId}`);
  },
  addPointVouchers(data) {
    return server.post("/juridical/vouchers", data);
  },
  editPointVouchers(data) {
    return server.patch("/juridical/vouchers", data);
  },
};
