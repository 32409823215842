<template>
  <div class="start-page__block">
    <div class="start-page__block-header block-header">
      <div>
        <div class="start-page__block-header-title">
          <span>Бесплатная</span> интернет-витрина
        </div>
        <div class="start-page__block-header-subtitle--text">
          Для продажи акционных товаров или услуг
        </div>
      </div>
      <div
        class="start-page__block-header-img header-img"
        v-html="require(`@/assets/img/icons/partner-start-2.svg`)"
      />
    </div>
    <div class="start-page__block-content block-content">
      <div
        class="start-page__block-content-item content-item"
        v-for="(rule, index) in rules"
        :key="index"
      >
        <div
          class="start-page__block-content-item-img"
          v-html="require(`@/assets/img/icons/${rule.icon}`)"
        />
        <div class="start-page__block-content-item-text">{{ rule.text }}</div>
      </div>
    </div>
    <div class="start-page__block-title">Что нужно сделать?</div>
    <div class="start-page__block-content block-content">
      <div
        class="start-page__block-content-item content-item"
        v-for="(rule, index) in howDoIt"
        :key="index"
      >
        <div
          class="start-page__block-content-item-img"
          v-html="require(`@/assets/img/icons/${rule.icon}`)"
        />
        <div class="start-page__block-content-item-text">{{ rule.text }}</div>
      </div>
    </div>
    <div class="start-page__block-separator" />
    <div class="start-page__block-title">Как это работает?</div>
    <div class="start-page__block-content block-content">
      <div
        class="start-page__block-content-item content-item"
        v-for="(rule, index) in howWorkIt"
        :key="index"
      >
        <div
          class="start-page__block-content-item-img"
          v-html="require(`@/assets/img/icons/${rule.icon}`)"
        />
        <div class="start-page__block-content-item-text">{{ rule.text }}</div>
        <div class="start-page__block-content-item-step bottom-step">
          {{ index + 1 }}
        </div>
      </div>
    </div>
    <div class="start-page__block-warning">
      <div
        class="start-page__block-warning-icon"
        v-html="require('@/assets/img/icons/warning.svg')"
      />
      <div class="start-page__block-warning-text">
        Для завершения регистрации вам необходимо будет отсканировать и
        подписать заявление-согласие и прислать нам на электронную почту.
      </div>
    </div>
    <div class="start-page__block-button">
      <router-link to="/partner/create"
        ><app-button class="start-page__button"
          >Зарегистрироваться</app-button
        ></router-link
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "StartPageBlockBottom",
  components: {
    "app-button": Button,
  },
  data: () => ({
    rules: [
      {
        icon: "start-cart.svg",
        text: "Увеличение продаж и привлечение новых клиентов",
      },
      {
        icon: "start-phone.svg",
        text: "Бесплатное размещение акционных товаров или услуг на сайте и в приложении платформы",
      },
      {
        icon: "start-sound.svg",
        text: "Бесплатная реклама торговой точки на сайте и в приложении платформы",
      },
      {
        icon: "start-push.svg",
        text: "Бесплатные push-уведомления клиентам по геолокации",
      },
    ],
    howDoIt: [
      {
        icon: "form.svg",
        text: "Загрузить список своих товаров или услуг через форму",
      },
      {
        icon: "sales.svg",
        text: "Обновлять свои товары и услуги на площадке",
      },
    ],
    howWorkIt: [
      {
        icon: "buy.svg",
        text: "Клиент покупает товар или услугу на платформе",
      },
      {
        icon: "num.svg",
        text: "Кассир получает уведомление в боте о покупке (наименование и номер заказа)",
      },
      {
        icon: "product.svg",
        text: "Клиент получает товар или услугу по номеру заказа в торговой точке",
      },
      {
        icon: "cashback.svg",
        text: "Платформа отправляет денежный перевод на счёт торговой точки на следующий день",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.block-header {
  margin-top: 40px;
}
.header-img {
  border-radius: 50%;
  background: rgba(39, 179, 84, 0.2);
  box-shadow: 0px 0px 43px 50px rgba(39, 179, 84, 0.2);
}
.block-content {
  flex-wrap: wrap;
  & .content-item {
    max-width: 50%;
    margin-bottom: 30px;
    & .start-page__block-content-item-text {
      max-width: 380px;
    }
  }
}
</style>
