<template>
  <div class="balance">
    <div
      class="balance__icon"
      v-html="require('@/assets/img/icons/balance.svg')"
    />
    <div class="balance__text">{{ getUserBalance }}</div>
    <app-tooltip class="balance__tooltip">
      <template v-slot:label>
        <span class="balance__tooltip-icon">
          <span v-html="require('@/assets/img/icons/question.svg')" />
        </span>
      </template>
      <template v-slot:content>
        <div class="balance__tooltip-content">
          Обменивайте бонусы на скидку при покупке
        </div>
      </template>
    </app-tooltip>
  </div>
</template>

<script>
import Tooltip from "@/components/UI/Tooltip";
import { mapGetters } from "vuex";

export default {
  name: "Balance",
  components: {
    "app-tooltip": Tooltip,
  },
  computed: {
    ...mapGetters("user", ["getUserBalance"]),
  },
};
</script>

<style lang="scss">
.balance {
  width: 95px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  background: $primary;
  border-radius: 10px;
  margin-right: 10px;
  &__icon,
  &__tooltip {
    height: 22px;
    &-content {
      padding: 10px;
      width: 150px;
      color: $title;
    }
  }
  &__text {
    color: $primary;
    font-weight: 600;
    background: $bonus;
    border-radius: 30px;
    padding: 0 6px;
    min-width: 20px;
    text-align: center;
  }
}
@media (min-width: $desktop-width) {
  .balance {
    height: 40px;
    border-radius: 20px;
  }
}
</style>
