<template>
  <section class="tabs">
    <swiper
      class="tabs__swiper"
      slidesPerView="auto"
      :freeMode="true"
      @swiper="onSwiper"
    >
      <swiper-slide v-for="tab in items" :key="tab">
        <app-tab :tabInfo="tab" @changeActive="changeActiveTab" />
      </swiper-slide>
    </swiper>

    <div class="tabs__buttons">
      <app-slider-button
        @click="prevSlide"
        direction="left"
        :disabled="swiperVariables.isStart"
      />
      <app-slider-button
        @click="nextSlide"
        direction="right"
        :disabled="swiperVariables.isEnd"
      />
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/swiper.min.css";
import Tab from "@/components/UI/Tabs/Tab";
import SliderButton from "@/components/UI/Buttons/Slider";
export default {
  name: "Tabs",
  components: {
    "app-slider-button": SliderButton,
    "app-tab": Tab,
    Swiper,
    SwiperSlide,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    active: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      swiper: null,
      swiperVariables: {
        isStart: true,
        isEnd: false,
      },
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
    changeActiveTab(id) {
      this.$emit("changeActive", id);
    },
  },
  watch: {
    swiper: {
      deep: true,
      handler(swiper) {
        this.swiperVariables.isEnd = swiper.isEnd;
        this.swiperVariables.isStart = swiper.isBeginning;
      },
    },
  },
};
</script>

<style lang="scss">
.tabs {
  display: grid;
  grid-template-columns: 1fr 121px;
  grid-column-gap: 10px;
  padding: 30px 0;

  .swiper {
    max-width: calc(100vw - 30px);

    .swiper-slide {
      width: fit-content !important;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__buttons {
    display: none;
  }
}

@media (min-width: $desktop-width) {
  .tabs {
    .swiper {
      max-width: calc(100vw - 121px - 30px - 10px);
    }

    &__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1px;
    }
  }
}

@media (min-width: 1240px) {
  .tabs {
    .swiper {
      max-width: calc(1200px - 121px - 10px);
    }
  }
}
</style>
