import { partnerApi } from "@/api/partner";
import _isEmpty from "lodash/isEmpty";

import companyInfoInterface from "./interface/companyInfoInterface";
import bankInfoInterface from "./interface/bankInfoInterface";
import partnerInfoStructureInterface from "./interface/partnerInfoStructureInterface";

export default {
  namespaced: true,
  state: {
    companyInfo: companyInfoInterface,
    bankInfo: bankInfoInterface,
    partnerInfo: {
      companyInfo: companyInfoInterface,
      bankInfo: bankInfoInterface,
      pointInfo: null,
      contacts: {},
      inviter: "",
      structure: partnerInfoStructureInterface,
    },
    contacts: {},
    loading: false,
    pointInfo: null,
    partnerPoints: [],
    pointVouchers: {},
  },
  mutations: {
    SET_LOCAL_INN(state, data) {
      localStorage.setItem("partnerINN", data);
    },
    SET_COMPANY_INFO(state, data) {
      // localStorage.setItem("partnerCompanyInfo", JSON.stringify(data));

      if (_isEmpty(data)) {
        return;
      }

      const parseData = { ...companyInfoInterface, ...data };
      parseData.address = { ...companyInfoInterface.address, ...data.address };
      parseData.juridicalAddress = {
        ...companyInfoInterface.juridicalAddress,
        ...data.juridicalAddress,
      };
      // с бека могут придти  не все ключи во вложенных обьектах
      state.companyInfo = parseData;
    },
    SET_BANK_INFO(state, data) {
      if (!_isEmpty(data)) {
        state.bankInfo = { ...bankInfoInterface, ...data };
      }
    },
    SET_PARTNER_INFO(state, data) {
      state.partnerInfo = { ...state.partnerInfo, ...data };
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_POINT_INFO(state, data) {
      state.pointInfo = data;
    },
    SET_PARTNER_POINTS(state, data) {
      state.partnerPoints = data;
    },
    SET_POINT_VOUCHERS(state, data) {
      state.pointVouchers = data;
    },
  },
  actions: {
    async setPartnerByInn({ commit }) {
      commit("SET_LOADING", true);
      let partnerInfo;
      try {
        const inn = localStorage.getItem("partnerINN");
        if (inn) {
          partnerInfo = await partnerApi.getPartnerByINN(inn);
          commit("SET_COMPANY_INFO", partnerInfo.data.companyInfo);
          commit("SET_BANK_INFO", partnerInfo.data.bankInfo);
          commit("SET_PARTNER_INFO", partnerInfo.data);
        } else {
          partnerInfo = null;
          commit("SET_COMPANY_INFO", partnerInfo);
          commit("SET_PARTNER_INFO", partnerInfo);
        }
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async searchByINN({ commit }, inn) {
      try {
        const res = await partnerApi.searchByINN({ inn });
        commit("SET_COMPANY_INFO", res.data);
        commit("SET_LOCAL_INN", inn);
        return res.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async searchByBIK({ commit }, bik) {
      try {
        const res = await partnerApi.searchByBIK({ bik });
        commit("SET_BANK_INFO", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async updatePartner({ state }) {
      try {
        // if (state.partnerInfo.pointInfo.image) {
        //   await dispatch("setPartnerPointImage", {
        //     image: state.partnerInfo.pointInfo.image,
        //     inn: state.partnerInfo.companyInfo.INN,
        //   });
        //   // delete state.partnerInfo.pointInfo.image;
        // }
        // console.log(state.partnerInfo);

        await partnerApi.updatePartner(state.partnerInfo);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async registerPartner({ commit, state }) {
      commit("SET_LOADING", true);
      try {
        const res = await partnerApi.registerPartner(state.partnerInfo);
        commit("SET_PARTNER_INFO", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        commit("SET_LOADING", false);
      }
    },
    async loadPartner({ commit }, id) {
      commit("SET_LOADING", true);
      try {
        let info = await partnerApi.getInfo(id);
        commit("SET_POINT_INFO", info.data);
        commit("SET_LOADING", false);
      } catch (e) {
        commit(
          "error/SET_ERROR",
          { value: true, name: "internal" },
          { root: true }
        );
        console.log(e);
      }
    },
    async setPartnerPoints({ commit }, data) {
      // console.log(data);
      commit("SET_LOADING", "pending");
      try {
        const res = await partnerApi.searchPoints(data);
        commit("SET_PARTNER_POINTS", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        commit("SET_LOADING", "ok");
      }
    },
    async setPartnerPointImage(_, data) {
      const formData = new FormData();
      // formData.append("inn", data.inn);
      formData.append("image", data.image);
      try {
        await partnerApi.setPartnerPointImage(formData);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async loadPointVouchers({ commit }, pointId) {
      try {
        const res = await partnerApi.getPointVouchers(pointId);
        // console.log(res.data);
        commit("SET_POINT_VOUCHERS", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async addPointVouchers({ commit }, data) {
      try {
        const res = await partnerApi.addPointVouchers(data);
        commit("SET_POINT_VOUCHERS", res.data);
        // console.log(res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async editPointVouchers({ commit }, data) {
      try {
        const res = await partnerApi.editPointVouchers(data);
        // console.log(res.data);
        commit("SET_POINT_VOUCHERS", res.data);
        // console.log(res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
  getters: {
    getCompanyInfo: (state) => state.companyInfo,
    getBankInfo: (state) => state.bankInfo,
    getContacts: (state) => state.contacts,
    getPartnerInfo: (state) => state.partnerInfo,
    getLoading: (state) => state.loading,
    getPointInfo: (state) => state.pointInfo,
    getPartnerPoints: (state) => state.partnerPoints,
    getPointVouchers: (state) => state.pointVouchers,
    getPointVouchersByPointId: (state) => (pointId) => {
      return state.pointVouchers.find((i) => i.retailPointId === +pointId);
    },
  },
};
