<template>
  <div class="products" :class="`products-${mode}`">
    <div class="products__slider">
      <div class="products__slider-header">
        <template v-if="mode === 'catalog'">
          <h1 class="products__slider-header__title">
            Покупайте товары выгодно
          </h1>
        </template>
        <template v-if="mode === 'cart'">
          <div class="products__slider-header__title">
            <div class="products__slider-header__subtitle">
              <span
                class="products__slider-header__icon"
                v-html="require(`@/assets/img/icons/gift.svg`)"
              />
              Получите второй товар в подарок
            </div>
            <span class="products__slider-header__desc"
              >Положите в корзину еще один товар и получите второй товар по
              меньшей стоимости в подарок.</span
            >
          </div>
        </template>
        <div class="products__slider-header__buttons">
          <template v-if="mode === 'catalog'">
            <app-button
              v-if="width > 414"
              mode="secondary"
              @click="$router.push('/products')"
              >Смотреть все</app-button
            >
            <app-button
              v-else
              mode="secondary"
              @click="$router.push('/products')"
              >Все</app-button
            >
          </template>

          <div class="tabs__buttons">
            <app-slider-button
              @click="prevSlide"
              direction="left"
              :disabled="swiperVariables.isStart"
            />
            <app-slider-button
              @click="nextSlide"
              direction="right"
              :disabled="swiperVariables.isEnd"
            />
          </div>
        </div>
      </div>
      <div class="products__slider-body">
        <swiper
          class="products__swiper"
          :slidesPerView="slideCount"
          :freeMode="true"
          :space-between="spaceBetweenSlides"
          :pagination="{ clickable: true }"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="product in products" :key="product.id">
            <app-offer-card
              :cardInfo="product"
              :updateDraft="mode === 'cart'"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import OfferCard from "@/components/Offers/Card/OfferCard";
import SliderButton from "@/components/UI/Buttons/Slider";
import SwiperCore, { Pagination } from "swiper";
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "ProductsSlider",
  components: {
    Swiper,
    SwiperSlide,
    "app-offer-card": OfferCard,
    "app-slider-button": SliderButton,
    "app-button": Button,
  },
  props: {
    products: {
      type: Array,
    },
    mode: {
      type: String,
      default: "catalog", // "cart"
    },
  },
  created() {
    SwiperCore.use([Pagination]);
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  data: () => ({
    swiper: null,
    swiperVariables: {
      isStart: true,
      isEnd: false,
    },
    width: 0,
  }),
  computed: {
    slideCount() {
      if (this.width < 1024) {
        return "1";
      } else return "3";
    },
    spaceBetweenSlides() {
      if (this.width < 820) {
        return 20;
      } else return 30;
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  watch: {
    swiper: {
      deep: true,
      handler(swiper) {
        this.swiperVariables.isEnd = swiper.isEnd;
        this.swiperVariables.isStart = swiper.isBeginning;
      },
    },
  },
};
</script>

<style lang="scss">
.products {
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  padding: 30px 0;
  //background-color: rgba(3, 0, 32, 0.04);

  @media (max-width: 820px) {
    box-sizing: border-box;
    width: calc(100vw + 15px);
    left: -15px;
    margin-right: -60px;
    padding: 15px 0;
  }

  &__slider {
    overflow: visible;
    box-sizing: border-box;
    max-width: 1260px;
    margin: 0 auto;
    &-header {
      padding: 0 30px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabs__buttons {
          margin-left: 30px;
        }
      }
      &__title {
        color: $accent;
        font-size: 1.5rem;
      }
      &__subtitle {
        color: $label-hot;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &__desc {
        color: $text;
        font-size: 16px;
        font-weight: normal;
        line-height: 1;
      }
      @media (max-width: 820px) {
        padding: 0 20px 10px;
      }
    }
    &-body {
      .swiper {
        padding: 0 30px;
        .swiper-wrapper {
          padding-bottom: 40px;
          display: flex;
          height: 100%;
          align-items: stretch;
          .swiper-slide {
            max-width: 400px;
            height: auto;
            .offer-card {
              height: 100%;
            }
          }
          .swiper-slide-prev {
            .offer-card {
              box-shadow: none;
            }
          }

          @media (max-width: 820px) {
            .swiper-slide {
              max-width: calc(100% - 10px);
            }
          }

          @media (max-width: 414px) {
            .swiper-slide {
              max-width: calc(100% - 5px) !important;
              .offer-card {
                &__body {
                  &-buy {
                    flex-direction: column;
                  }
                  &-price {
                    font-size: 1.5rem;
                  }
                }
              }
            }
          }
        }
        .swiper-pagination {
          margin-bottom: -15px;
        }
        @media (max-width: 820px) {
          padding: 0 20px;
        }
        @media (max-width: 414px) {
          padding: 0 15px;
        }
      }
    }
  }

  &-cart {
    .offer-card {
      &__head {
        &-photo {
          height: 120px;
        }
      }
      &__body {
        &-title {
          font-size: 14px;
          margin-bottom: 3px;
        }
        &-partner {
          font-size: 12px;
        }
        &-address {
          font-size: 12px;
          line-height: 1;
        }
        &-buy {
          display: inline-block;
          .button {
            height: 40px;
          }
          .button p {
            font-size: 16px;
          }
        }
        &-prices {
          span {
            font-size: 14px;
          }
        }
        &-price {
          font-size: 14px;
        }
      }
    }
    .offer-labels__item {
      font-size: 12px;
      padding: 5px;
    }
    .products__slider {
      &-body {
        .swiper .swiper-wrapper .swiper-slide {
          @media (max-width: 414px) {
            width: 150px !important;
            .offer-card__body-price {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
