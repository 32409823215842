<template>
  <section class="goods-group">
    <header>
      <p>{{ groupItem.retailPointName }}</p>

      <app-radio
        v-model:value="value"
        :id="groupItem.retailPointId"
        name="goods"
      />
    </header>

    <main>
      <slot />
    </main>
  </section>
</template>

<script>
import Radio from "@/components/UI/Inputs/RadioDefault";

export default {
  name: "GoodsGroup",
  components: {
    "app-radio": Radio,
  },
  props: {
    active: {
      type: [String, Number],
      required: true,
    },
    groupItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.active;
      },
      set(v) {
        this.$emit("update:active", v);
      },
    },
  },
};
</script>

<style lang="scss">
.goods-group {
  border-top: 1px solid $text-light;

  & > header {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 1px;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 10px;

    & > p {
      font-size: 1.286rem;
      line-height: 1.571rem;
      font-weight: 700;
      color: $title;
    }
  }
}

@media (min-width: $desktop-width) {
  .goods-group {
    & > header {
      padding: 15px 0 15px;
      & > p {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }
}
</style>
