import { tariffsApi } from "@/api/tariffs";

export default {
  namespaced: true,
  state: {
    tariffs: [],
    tariff: {},
  },
  mutations: {
    SET_TARIFFS(state, data) {
      state.tariffs = data;
    },
    SET_TARIFF(state, data) {
      state.tariff = data;
    },
  },
  actions: {
    async getTariffs({ commit, dispatch }) {
      try {
        commit("SET_LOADING_STATUS", "pending", { root: true });

        const res = await tariffsApi.get();
        commit("SET_TARIFFS", res.data.data);
        await dispatch("setTariff", res.data.data[0]);

        commit("SET_LOADING_STATUS", "ok", { root: true });
      } catch (e) {
        commit("SET_LOADING_STATUS", "error", { root: true });

        await dispatch(
          "error/toggleError",
          {
            name: "internal",
            value: true,
          },
          { root: true }
        );
      }
    },
    async setTariff({ commit, dispatch }, data) {
      commit("SET_TARIFF", data);
      await dispatch("payment/createDraft", null, { root: true });
    },
  },
  getters: {
    tariffs: (state) => state.tariffs,
    tariff: (state) => state.tariff,
  },
};
