import { server } from "@/api/index";

let paymentApi = {
  createDraft(data) {
    return server.post("/payment/createDraft", data);
  },

  processDraft(id, data) {
    return server.post(`/payment/processDraft/${id}`, data);
  },

  completeDraft(id) {
    return server.get(`/payment/completeDraft/${id}`);
  },
};

export { paymentApi };
