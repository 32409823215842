<template>
  <app-tabs
    :items="categories"
    :active="activeCategory"
    @changeActive="changeActiveCategory"
  />

  <app-subscribe-banner v-if="!user.subscription" />

  <app-offers-grid
    v-if="getCatalogView === 'list' && !$route.params.filter"
    :offers="offers"
    :random-products="getRandomProducts"
    :is-loading="isLoading"
    :is-end="isEndOffers"
    @loadMore="loadOffers"
  />
  <app-offers-grid
    v-else
    :offers="getProductsByRatio($route.params.filter)"
    :is-loading="isLoading"
    :is-end="isEndOffers"
  />
  <app-offers-map
    v-if="getCatalogView === 'map'"
    :offers="offers"
    :categories="categories"
    :current-category-id="activeCategory"
    :loading="isLoading"
  ></app-offers-map>
</template>

<script>
import Tabs from "@/components/UI/Tabs/Tabs";
import SubscribeBanner from "@/components/UI/SubscribeBanner";
import OffersGrid from "@/components/Offers/OffersGrid";
import { mapActions, mapGetters } from "vuex";
import Map from "@/components/YMap";

export default {
  name: "Offers",
  components: {
    "app-tabs": Tabs,
    "app-subscribe-banner": SubscribeBanner,
    "app-offers-grid": OffersGrid,
    "app-offers-map": Map,
  },
  computed: {
    ...mapGetters("offers", {
      quantity: "getOffersQuantity",
      categories: "getCategories",
      activeCategory: "getActiveCategory",
      offers: "getOffers",
      isLoading: "getLoadingStatus",
      isEndOffers: "getEndOffers",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
    ...mapGetters("products", [
      "getRandomProducts",
      "getPartnerProductsCount",
      "getProductsByRatio",
    ]),
    ...mapGetters(["getCatalogView"]),
  },
  async created() {
    await this.loadCategories();
  },
  methods: {
    ...mapActions("offers", [
      "loadCategories",
      "changeActiveCategory",
      "loadOffers",
    ]),
  },
};
</script>

<style lang="scss"></style>
