<template>
  <div class="link" :class="{ disabled: disabled }">
    <span
      class="link__icon"
      v-if="icon"
      v-html="require(`@/assets/img/icons/${icon}.svg`)"
    ></span>
    <router-link
      v-if="routerLink"
      class="link__item"
      :to="routerLink"
      @click="clickLink"
      :class="{ 'link__item-underline': underline }"
    >
      <slot></slot>
    </router-link>
    <a
      v-else-if="link"
      :href="link"
      class="link__item"
      target="_blank"
      :class="{ 'link__item-underline': underline }"
    >
      <slot></slot>
    </a>
    <button v-else class="link__item link__item-button">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "Link",
  props: {
    routerLink: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    underline: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["clickLink"],
  data() {
    return {};
  },
  methods: {
    clickLink() {
      this.$emit("clickLink");
    },
  },
};
</script>

<style lang="scss">
.link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    svg {
      transition: $transition;
      fill: $accent;
    }
  }
  &__item {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $accent;
    transition: $transition;
    &-underline {
      border-bottom: 1px dashed $accent-disabled;
    }
    &-button {
      padding: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
  &:hover {
    .link {
      &__icon {
        svg {
          fill: $title;
        }
      }
      &__item {
        color: $title;
        &-underline {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}
</style>
