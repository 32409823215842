export default {
  pointId: -1, // используется только на фронте удаление/изменение точек
  id: 0, // записывается бекендом read only
  isValidForm: false,
  collapsed: false,
  retailPointName: "",
  phone: "",
  webAddress: "",
  workingTime: "",
  description: "",
  address: {
    city: "",
    street: "",
    house: "",
  },
  cashier: {
    name: "",
    phone: "",
  },
  image: null,
};
