import { server } from "./index";

let userApi = {
  getUser() {
    return server.get(`/user`);
  },
  setRefer(data) {
    return server.get(`/user/refer?referId=${data}`);
  },
};

export { userApi };
