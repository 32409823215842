<template>
  <section class="payment-draft">
    <main v-if="order.productType === 'SUBSCRIPTION'">
      <p>
        Подписка на {{ order.goods[0].duration }}
        {{ $filters.monthDeclension(order.goods[0].duration) }}
      </p>
      <p>{{ $filters.price(order.goods[0].price) }} ₽</p>

      <p v-if="order.goods[0].discount">Скидка по промокоду</p>
      <p v-if="order.goods[0].discount">
        {{ $filters.price(order.goods[0].discount) }} ₽
      </p>
    </main>

    <main v-else-if="order.productType === 'PAYMENT'">
      <p>Товары ({{ order.goods.length }})</p>
      <!--      TODO: брать итоговую стоимость заказа из order-->
      <p>{{ $filters.price(orderPrice) }} ₽</p>
      <p v-if="discount !== '0.00'">Скидка с учетом акции</p>
      <p v-if="discount !== '0.00'">{{ $filters.price(discount) }} ₽</p>
    </main>

    <footer>
      <p>К оплате</p>
      <p>{{ $filters.price(draftExtra.spend) }} ₽</p>
    </footer>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PaymentDraft",
  computed: {
    ...mapGetters("payment", ["draftExtra"]),
    ...mapGetters("order", ["order"]),
    orderPrice() {
      return this.order.goods.reduce((acc, item) => {
        return acc + item.price;
      }, 0);
    },
    discount() {
      return (this.orderPrice - this.draftExtra.spend).toFixed(2);
    },
  },
};
</script>

<style lang="scss">
.payment-draft {
  margin: 15px 0;
  border: 1px dashed $text-medium;
  border-radius: $border-radius;

  main,
  footer {
    display: grid;
    grid-template-columns: minmax(max-content, 2fr) minmax(max-content, 1fr);
    grid-row-gap: 10px;
    padding: 10px;

    & > p {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
      color: $title;

      .tooltip {
        margin-left: 5px;
      }

      &:nth-child(2n) {
        margin-left: auto;
      }
    }
  }

  main {
    border-bottom: 1px dashed $text-medium;
  }

  footer {
    p {
      font-size: 1.429rem;

      &:nth-child(2n) {
        font-weight: 600;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .order-draft {
    border-radius: $border-radius-desktop;

    main,
    footer {
      padding: 15px 20px;
    }

    footer {
      p {
        font-size: 1.625rem;
      }
    }
  }
}
</style>
