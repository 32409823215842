<template>
  <div
    :class="[
      'input',
      {
        disabled: disabled,
        error: !valid,
        fixed: placeholder || value,
      },
    ]"
  >
    <label v-if="label.length" ref="label" :class="`input__label`">
      {{ label }}
      <span v-if="required" class="input__label-icon"> * </span>
    </label>

    <div ref="inputWrapper" :class="`input__wrapper`">
      <input
        ref="input"
        :id="`${id}input`"
        :class="'input__input'"
        :value="value"
        v-imask="mask"
        :placeholder="placeholder"
        :type="inputMode"
        :required="required"
        :disabled="disabled"
        :autocomplete="inputAutocomplete"
        :rules="validate"
        @accept="onAccept($event)"
        @complete="onComplete"
        @keyup.enter="$emit('enter')"
      />
      <app-tooltip
        v-if="mode === 'vouchers' || mode === 'coupons'"
        class="input__tooltip"
      >
        <template v-slot:label>
          <span v-html="require('@/assets/img/icons/question.svg')" />
        </template>
        <template v-slot:content>
          <div>
            {{ tooltipText }}
          </div>
        </template>
      </app-tooltip>
      <transition name="fade">
        <div
          v-show="value && !disabled"
          class="input__icon input__icon--delete"
          v-html="require(`@/assets/img/icons/delete.svg`)"
          @click="clear"
        />
      </transition>
    </div>

    <transition name="fade">
      <div v-show="!valid" class="input__error error-message">
        {{ message }}
      </div>
    </transition>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { IMaskDirective } from "vue-imask";
import Tooltip from "@/components/UI/Tooltip";
const urlTest = (str) => {
  if (!str.length) {
    return false;
  }

  str = str.replace(/http(s)?:\/\//, "").replace(/www\./, "");

  if (!str.length) {
    return false;
  }

  const regexp =
    /^(?=[a-zA-Zа-яА-Я0-9]{1,}\.[a-zA-Zа-яА-Я0-9@:%._+~#=]+)(?=[a-zA-Zа-яА-Я0-9@:%._+~#=]{1,}\.[a-zA-Zа-яА-Я0-9@:%._+~#=]{2,})[a-zA-Zа-яА-Я0-9@:%._+~#=]*$/;

  return regexp.test(str);
};
export default {
  name: "Input",
  components: {
    "app-tooltip": Tooltip,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: "text",
      validator(value) {
        return (
          [
            "text",
            "phone",
            "code",
            "email",
            "promocode",
            "inn",
            "bik",
            "account",
            "price",
            "discount",
            "coupons",
            "vouchers",
            "url",
          ].indexOf(value) !== -1
        );
      },
    },
    value: {
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    wrongCode: {
      type: Boolean,
      required: false,
      default: false,
    },
    wrongPromocode: {
      type: Boolean,
      required: false,
      default: false,
    },
    focus: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      validator: (prop) => prop.every((i) => typeof i === "function"),
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    id: uuidv4(),
    mask: {
      mask: "",
      lazy: true,
      placeholderChar: "_",
    },
    max: 1000,
    valid: true,
    message: "",
  }),
  computed: {
    inputMode() {
      if (this.mode === "email") {
        return "email";
      } else if (this.mode === "phone") {
        return "tel";
      } else {
        return "text";
      }
    },
    inputAutocomplete() {
      if (this.mode === "email") {
        return "email";
      } else if (this.mode === "phone") {
        return "tel";
      } else {
        return "off";
      }
    },
    tooltipText() {
      if (this.mode === "vouchers") {
        return "От 35%";
      } else if (this.mode === "coupons") {
        return "До 35%";
      } else {
        return "";
      }
    },
  },
  watch: {
    focus: {
      handler() {
        if (this.focus && this.$refs.input) {
          this.$refs.input.focus();
        }
      },
    },
    wrongCode() {
      this.validate(this.value);
    },
    wrongPromocode() {
      this.validate(this.value);
    },
    valid() {
      this.$emit("update:valid", this.valid);
    },
  },
  mounted() {
    this.setInputBehavior();
    this.$nextTick(function () {
      if (this.focus) {
        this.$refs.input.focus();
      }
      this.$refs.input.value = this.value;
      this.$refs.input.dispatchEvent(new Event("input", { bubbles: true }));
      // if (this.mode !== "phone") {
      //   this.$refs.input.value = this.value;
      //   this.$refs.input.dispatchEvent(new Event("input", { bubbles: true }));
      // }
    });
  },
  methods: {
    setInputBehavior() {
      this.mask.mask = "*".repeat(this.max);

      switch (this.mode) {
        case "phone":
          this.mask = {
            mask: "+{7} (000) 000-00-00",
            lazy: true,
            placeholderChar: "_",
          };
          break;
        case "inn":
          this.mask = {
            mask: "0000 0000 0000",
            lazy: true,
            placeholderChar: "_",
          };
          break;
        case "bik":
          this.mask = {
            mask: "000000000",
            lazy: true,
            placeholderChar: "_",
          };
          break;
        case "account":
          this.mask = {
            mask: "000 00 000 0 0000 0000000",
            lazy: true,
            placeholderChar: "_",
          };
          break;
        case "code":
          this.mask = {
            mask: "0 0 0 0 0",
            lazy: true,
            placeholderChar: "•",
          };
          break;
        case "email":
          this.mask = {
            mask: "*".repeat(this.max),
            lazy: true,
            placeholderChar: "_",
          };
          break;
        default:
          this.mask = {
            mask: "*".repeat(this.max),
            lazy: true,
            placeholderChar: "_",
          };
          break;
      }
    },
    validate(value) {
      this.valid = true;
      if (this.required && !value) {
        this.message = "Поле обязательно для заполнения";
        this.valid = false;
        return;
      }
      if (this.rules.length) {
        this.rules.forEach((item) => {
          const result = item(value);
          if (typeof result === "string") {
            this.message = result;
            this.valid = false;
            return;
          }
        });
      }

      switch (this.mode) {
        case "phone":
          if (value.length < 18) {
            this.message = "Введены не все символы";
            this.valid = false;
          }
          break;
        case "inn":
          if (value.length < 12) {
            this.message = "Введены не все символы";
            this.valid = false;
          }
          break;
        case "bik":
          if (value.length < 9) {
            this.message = "Введены не все символы";
            this.valid = false;
          }
          break;
        case "account":
          if (value.length < 25) {
            this.message = "Введены не все символы";
            this.valid = false;
          }
          break;
        case "price":
          if (!/^[1-9]\d*$/.test(value)) {
            this.message = "Введите корректное значение";
            this.valid = false;
          }
          break;
        case "discount":
          if (!/^[1-9]\d?$|^99$/.test(value)) {
            this.message = "Введите корректное значение";
            this.valid = false;
          }
          break;
        case "coupons":
          if (!/^([1-9]|[1-2]\d|3[0-5])$/.test(value)) {
            this.message = "Введите корректное значение";
            this.valid = false;
          }
          break;
        case "vouchers":
          if (!/^(3[5-9])|[4-9]\d$/.test(value)) {
            this.message = "Введите корректное значение";
            this.valid = false;
          }
          break;
        case "url":
          if (value.length && !urlTest(value)) {
            this.message = "Не корректный адрес ";
            this.valid = false;
          }
          break;
        case "code":
          if (this.wrongCode) {
            this.message = "Неверный код из SMS. Повторите ввод";
            this.valid = false;
          } else if (value.length < 9) {
            this.message = "Введены не все символы";
            this.valid = false;
          }
          break;
        case "email":
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            this.message = "Неверный формат электронной почты";
            this.valid = false;
          }
          break;
        case "promocode":
          if (this.wrongPromocode) {
            this.message = "Несуществующий промокод";
            this.valid = false;
          }
          break;
      }
    },
    clear() {
      // console.log("clear");
      this.$emit("update:value", "");
      this.$refs.input.focus();
    },
    onAccept(e) {
      // console.log("onAccept");
      this.$emit("update:value", e.detail.unmaskedValue);

      setTimeout(() => {
        // костыль
        // иначе получаем this.value без последнего символа
        this.validate(e.detail.value);
        this.$emit("update:valid", this.valid);
      }, 300);
    },
    onComplete() {
      // console.log("onComplete");
    },
  },
  directives: {
    imask: IMaskDirective,
  },
};
</script>

<style lang="scss">
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  transition: opacity $transition;
  &__label {
    position: absolute;
    left: 15px;
    top: 50%;
    z-index: 10;
    margin-top: calc(-1.25rem / 2);
    margin-bottom: 3px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    color: $text-input;
    text-align: left;
    transition: transform $transition, font-size $transition, color $transition;
    pointer-events: none;
    &-icon {
      color: $text;
      transition: color $transition;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    height: 65px;
    background: $input;
    border: 2px solid transparent;
    border-radius: $border-radius;
    box-sizing: border-box;
    transition: background $transition, border $transition;
    &:hover {
      width: 100%;
      background: $input-hover;
      border: 2px solid $input;
    }
  }
  &__input {
    flex-grow: 1;
    min-width: 1px; // для правильной работы в firefox
    width: inherit;
    height: 100%;
    margin-right: 50px;
    padding: 15px 15px 0 15px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    color: $title;
    caret-color: $accent;
    border: none;
    border-radius: $border-radius;
    outline: none;
    background: none !important;
    box-shadow: none !important;
    transition: color $transition;
    box-sizing: border-box;
    &::placeholder {
      font-size: 1.25rem;
      font-weight: 500;
      color: $text-input;
      opacity: 1;
      transition: opacity $transition;
    }
  }
  &__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border-radius: 50%;
    cursor: pointer;
    svg {
      circle {
        fill: rgba(126, 125, 142, 0.25);
        transition: fill $transition;
      }
      path {
        fill: $primary;
      }
    }
    &:hover {
      svg {
        circle {
          fill: rgba(126, 125, 142, 0.5);
        }
      }
    }
  }
  &__error {
    position: absolute;
    bottom: -10px;
    left: 15px;
    right: 15px;
  }
  &.fixed,
  &:focus-within {
    .input__label {
      transform: translateY(-100%);
      font-size: 0.875rem;
    }
  }
  &:focus-within {
    .input__wrapper {
      border: 2px solid $accent;
      background: $primary;
    }
  }
  &.error {
    .input__label {
      color: $error;
      &-icon {
        color: $error;
      }
    }
    .input__wrapper {
      border: 2px solid $error;
      background: $primary;
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &__tooltip {
    margin-right: 45px;
    margin-top: 5px;
    display: flex;
    &-tooltip {
      &-icon {
        margin-left: 10px;
      }
    }
    & .tooltip__content {
      background: $accent;
      color: $primary;
      font-size: 16px;
      border-radius: 10px;
      top: -180%;
      right: -200%;
      width: 75px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
