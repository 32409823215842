<template>
  <label
    :class="[
      'radio',
      {
        checked: value === id,
        group: !!name,
        readonly: readonly,
      },
    ]"
  >
    <input
      :id="id"
      type="radio"
      :value="id"
      :name="name"
      @change="updateInput"
      :checked="value === id"
    />
  </label>
</template>

<script>
export default {
  name: "RadioDefault",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    updateInput() {
      this.$emit("update:value", this.id);
    },
  },
};
</script>

<style lang="scss"></style>
