<template>
  <main class="app-container payment-page">
    <app-breadcrumbs :prevPage="offerToPay" currentPage="Оплата покупки" />

    <h1>Оплата покупки</h1>

    <article>
      <app-payment-method />

      <app-payment-order />
    </article>
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs";
import PaymentMethod from "@/components/Payment/PaymentMethod";
import PaymentOrder from "@/components/Payment/PaymentOrder";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PaymentPage",
  components: {
    "app-payment-order": PaymentOrder,
    "app-payment-method": PaymentMethod,
    "app-breadcrumbs": Breadcrumbs,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("auth", {
      registerAnonUser: "registerAnonUser",
    }),
    ...mapActions("payment", {
      createDraft: "createDraft",
    }),
    async create() {
      await this.createDraft();
    },
  },
  computed: {
    ...mapGetters("offers", {
      offerToPay: "getOfferToPay",
    }),
  },
  async created() {
    if (
      this.offerToPay &&
      this.offerToPay.price &&
      this.offerToPay.price.actualPrice
    ) {
      await this.create();
    }
  },
  beforeUnmount() {
    localStorage.removeItem("selectedToPay");
  },
};
</script>

<style lang="scss">
.payment-page {
  padding-top: 30px;
  padding-bottom: 40px;

  h1 {
    margin-bottom: 20px;
  }

  & > article {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    align-items: start;
  }
}

@media (min-width: $desktop-width) {
  .payment-page {
    padding-top: 0px;
    padding-bottom: 60px;

    & > article {
      grid-template-columns: 410px 450px;
      grid-column-gap: 30px;
    }
  }
}
</style>
