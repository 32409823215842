<template>
  <div
    class="subscribe-block"
    :class="`subscribe-block--${subscription ? 'active' : 'error'}`"
  >
    <div class="subscribe-block__row">
      <h4>Подписка</h4>
      <app-label
        icon="success"
        :label-type="subscription ? 'SUCCESS' : 'ERROR'"
      >
        {{ subscribeLabelText }}
      </app-label>
    </div>
    <div
      v-if="subscription && subscription.duration"
      class="subscribe-block__row"
    >
      <span>Тариф</span>
      <span>
        {{ subscription.duration }}
        {{ $filters.monthDeclension(subscription.duration) }}
      </span>
    </div>
    <div
      v-if="subscription && subscription.startDate && subscription.endDate"
      class="subscribe-block__row"
    >
      <span>Период </span>
      <span>
        {{ subscription.startDate.split("T").shift() }}-{{
          subscription.endDate.split("T").shift()
        }}
        23:59
      </span>
    </div>
    <!--    <div class="subscribe-block__row">-->
    <!--      <span>Привязка</span>-->
    <!--      <span>+7 (999) 239-02-12</span>-->
    <!--    </div>-->
  </div>
</template>

<script>
import Label from "@/components/UI/Labels";
import { mapGetters } from "vuex";
export default {
  name: "SubscribeBlock",
  components: { "app-label": Label },
  computed: {
    ...mapGetters("user", ["user"]),
    subscribeLabelText() {
      return this.subscription ? "Активна" : "Закончилась";
    },
    subscription() {
      return this.user.subscription;
    },
  },
};
</script>

<style lang="scss">
.subscribe-block {
  max-width: 410px;
  margin-bottom: 30px;
  display: grid;
  gap: 5px 30px;
  align-items: center;
  padding: 20px;
  border-radius: $border-radius-desktop;
  color: $input;
  box-shadow: 12px 12px 20px rgba(45, 80, 251, 0.15);
  &--active {
    background: linear-gradient(98.66deg, #2d50fb 0%, #27b354 86.71%);
  }
  &--error {
    background: linear-gradient(98.66deg, #2d50fb 0%, #fe0000 86.71%);
  }
  h4 {
    color: inherit;
    font-size: 22px;
    line-height: 30px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > :first-child {
      margin-right: 10px;
    }
  }
}
@media (max-width: $desktop-width) {
  .subscribe-block {
    border-radius: 16px;
    padding: 15px;
    width: auto;
    max-width: 100%;
  }
}
</style>
