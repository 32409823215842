<template>
  <div class="sbp-block">
    <div class="sbp-block__list-title">1. Загрузите товары на площадку</div>
    <ul class="sbp-block__list">
      <li class="sbp-block__list-item">
        <app-link size="m" @click="$emit('to-products')"
          >Перейдите к форме загрузки</app-link
        >
        и заполните параметры товара.
      </li>
      <li class="sbp-block__list-item">Нажмите на кнопку "Загрузить товар".</li>
      <li class="sbp-block__list-item">
        После успешной загрузки и подписанного приложения к
        <a :href="ofertaLink" target="_blank"
          ><app-link size="m">заявлению-согласию</app-link></a
        >
        мы разместим ваш товар на площадке.
      </li>
      <li class="sbp-block__list-item">
        Добавляйте новые товары, редактируйте и удаляйте. Ваши данные о товарах
        будут обновлены на площадке.
      </li>
    </ul>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Buttons/ButtonLink";

export default {
  name: "CompletePageProducts",
  components: {
    "app-link": ButtonLink,
  },
  props: {
    ofertaLink: {
      type: String,
      required: true,
    },
  },
};
</script>
