<template>
  <div
    id="offers"
    class="offers-grid"
    v-for="(group, index) in offersGroups"
    :key="index"
  >
    <template v-for="item in group" :key="item.id">
      <app-offer-card :cardInfo="item" />
    </template>
    <!--    <app-products-slider-->
    <!--      v-if="group.length === 9 && randomProducts.length"-->
    <!--      :products="randomProducts"-->
    <!--    />-->
    <template v-if="group.length === 9 && randomProducts.length">
      <app-offer-card
        v-for="(product, index) in randomProducts"
        :key="index + product.id"
        :cardInfo="product"
        type="product"
      />
    </template>
  </div>
  <transition name="fade">
    <app-spinner v-if="isLoading && !this.isEnd" size="l" :fixed="false" />
  </transition>
  <load-on-scroll v-if="!isEnd && !isLoading" @intersect="$emit('loadMore')" />
</template>

<script>
import OfferCard from "@/components/Offers/Card/OfferCard";
import Spinner from "@/components/UI/Spinner";
import LoadOnScroll from "@/components/UI/LoadOnScroll";
// import ProductsSlider from "@/components/Products/ProductsSlider";

export default {
  name: "OffersGrid",
  components: {
    "load-on-scroll": LoadOnScroll,
    "app-spinner": Spinner,
    "app-offer-card": OfferCard,
    // "app-products-slider": ProductsSlider,
  },
  props: {
    offers: {
      type: Array,
      required: true,
    },
    randomProducts: {
      type: Array,
      required: true,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEnd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    offersGroups() {
      let subarray = [];
      for (let i = 0; i < Math.ceil(this.offers.length / 9); i++) {
        subarray[i] = this.offers.slice(i * 9, i * 9 + 9);
      }
      return subarray;
    },
  },
};
</script>

<style lang="scss">
.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  gap: 30px;
  margin-bottom: 30px;
  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}
@media (max-width: $desktop-width) {
  .offers-grid {
    gap: 15px;
    margin-bottom: 15px;
  }
}
</style>
