<template>
  <router-link :to="`/offer/${cardInfo.id}`" custom v-slot="{ navigate }">
    <transition name="fade">
      <div class="offer-card" @click="navigate" :class="`offer-card--${mode}`">
        <header class="offer-card__head">
          <div class="offer-card__head-photo">
            <offer-card-photo :images="cardInfo.images" />
          </div>
          <app-offer-labels
            :card="cardInfo"
            :labelType="cardInfo.type"
            class="offer-card__head-labels"
          />
          <!--          <app-like-button-->
          <!--            @click.stop-->
          <!--            :offer="cardInfo"-->
          <!--            class="offer-card__head-like"-->
          <!--            size="s"-->
          <!--          />-->
        </header>
        <div class="offer-card__body">
          <h4 class="offer-card__body-title">
            {{ trimString(this.cardInfo.title) }}
          </h4>
          <router-link :to="`/partner-info/${cardInfo.retailPointId}`">
            <div
              v-if="cardInfo.retailPointName"
              class="offer-card__body-partner"
            >
              {{ trimString(cardInfo.retailPointName) }}
            </div>
            <small class="offer-card__body-address" v-if="address">{{
              trimString(address)
            }}</small>
            <div
              v-if="type === 'product' && count"
              class="offer-card__body-all-products"
            >
              Еще {{ count }} предложения
            </div>
          </router-link>
          <div class="offer-card__body-buy">
            <div
              class="offer-card__body-prices"
              v-if="cardInfo.price && cardInfo.price.actualPrice"
            >
              <span class="offer-card__body-price"
                >{{ cardInfo.price.actualPrice }} ₽
              </span>
              <span v-show="cardInfo.price.oldPrice"
                >{{ cardInfo.price.oldPrice }} ₽</span
              >
            </div>
            <app-button
              v-if="cardInfo.price && cardInfo.price.actualPrice"
              @click.stop="addToCart(cardInfo)"
              class="offer-card__body-btn"
              mode="secondary"
              :disabled="inCart"
            >
              <slot v-if="!inCart">В корзину</slot>
              <slot v-else>В корзине</slot>
            </app-button>
          </div>
        </div>
      </div>
    </transition>
  </router-link>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
import Labels from "@/components/UI/Labels";
import OfferCardPhoto from "@/components/Offers/Card/OfferCardPhoto";
// import LikeButton from "@/components/UI/Buttons/LikeButton";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "OfferCard",
  props: {
    cardInfo: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: "default", // insert
    },
  },
  components: {
    // "app-like-button": LikeButton,
    "offer-card-photo": OfferCardPhoto,
    "app-offer-labels": Labels,
    "app-button": Button,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("offers", {
      setOfferToPay: "setOfferToPay",
    }),
    ...mapActions("cart", {
      addToCart: "addToCart",
    }),

    pay(offer) {
      this.setOfferToPay(offer);
      this.$router.push("/cart");
    },
    trimString(value) {
      if (value.length <= 38 || window.innerWidth >= 1024) return value;
      return value.substring(0, 38) + "...";
    },
  },
  computed: {
    ...mapGetters("cart", ["cart"]),
    ...mapGetters("products", ["getPartnerProductsCount"]),
    address() {
      const address = this.cardInfo.address;
      return `${address.street} ${address.house}`;
    },
    inCart() {
      if (this.cart.findIndex((i) => i.item._id === this.cardInfo._id) !== -1) {
        return true;
      } else return false;
    },
    type() {
      return this.cardInfo.productType;
    },
    count() {
      if (this.type === "product") {
        if (this.getPartnerProductsCount(this.cardInfo.retailPointId)) {
          return this.getPartnerProductsCount(this.cardInfo.retailPointId) - 1;
        } else {
          return this.getPartnerProductsCount(this.cardInfo.retailPointId);
        }
      } else return 0;
    },
  },
};
</script>

<style lang="scss">
.offer-card {
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  transition: $transition;
  position: relative;
  box-shadow: 12px 12px 20px rgba(45, 80, 251, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  height: 100%;
  &:hover {
    box-shadow: 0 16px 30px rgba(45, 80, 251, 0.2);
  }
  &__head {
    &-photo {
      width: 100%;
      height: 200px;
      img {
        object-fit: cover;
        height: inherit;
        width: inherit;
      }
    }
    &-labels {
      position: absolute;
      left: 15px;
      top: 15px;
    }
    &-like {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 10;
    }
  }
  &__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    background-color: $primary;
    &-buy {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
    }
    &-partner {
      color: $title;
      margin: 5px 0;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
    }
    &-address {
      font-weight: 500;
    }
    &-all-products {
      color: $accent;
      font-size: 12px;
      font-weight: 600;
      padding: 3px 4px;
      background-color: $accent-disabled;
      border-radius: 6px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    &-prices {
      display: flex;
      align-items: center;
      width: 100% !important;
      margin-right: 10px;
      span {
        font-size: 26px;
        line-height: 31px;
        &:first-child {
          color: $title;
          font-weight: 600;
        }
        &:last-child {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-left: 10px;
          text-decoration: line-through;
        }
      }
    }
  }
}
@media (max-width: $desktop-width) {
  .offer-card--default {
    &.offer-card {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      //height: 165px;
      box-sizing: border-box;
    }
    .offer-card__head {
      height: 165px;
      &-labels {
        left: 22px;
        top: 22px;
      }
      &-photo {
        width: 120px;
        height: 150px;
        padding: 15px 15px 0;
        margin: 0;
        display: inline-block;
        img {
          width: 120px;
          height: 120px;
          border-radius: 8px;
        }
      }
    }
    .offer-card__body {
      padding: 15px;
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
      &-title {
        font-size: 14px;
        height: 28px;
      }
      &-partner,
      &-address {
        font-size: 12px;
        line-height: 12px;
        //height: 24px;
      }
      &-buy {
        flex-direction: column;
        align-items: flex-start;
      }
      &-prices {
        margin-right: 0;
        margin-bottom: 7px;
        height: 14px;
        span,
        span:last-child {
          font-size: 14px;
          line-height: 14px;
        }
      }
      &-btn {
        height: 34px;
        width: 105px;
        border-radius: 12px;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
