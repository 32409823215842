<template>
  <app-loader v-if="loading" :value="loading" />
  <div v-else class="partner-products">
    <h2 class="partner-products__title">Редактирование товара</h2>
    <div class="partner-products__form">
      <!--      <div class="partner-products__form-header">-->
      <!--        <app-select-->
      <!--          :inputValue="Object.keys(this.points)[0]"-->
      <!--          :options="points"-->
      <!--          placeholder="Торговая точка"-->
      <!--          disabled-->
      <!--          @update:value="product.retailPointId = $event.id"-->
      <!--        />-->
      <!--        <app-simple-select-->
      <!--          :inputValue="types[0]"-->
      <!--          :options="types"-->
      <!--          placeholder="Тип размещения"-->
      <!--          disabled-->
      <!--        />-->
      <!--      </div>-->
      <div class="partner-products__form-general">
        <div class="partner-products__form-general--title">Описание</div>
        <app-input v-model:value="product.title" label="Заголовок" required />
        <app-input
          v-model:value="product.description"
          label="Описание"
          required
          @update:valid="valid = $event"
        />
        <div class="partner-products__form-general--title">Стоимость</div>
        <div class="partner-products__form-general--group">
          <app-input
            v-model:value="productPrice"
            mode="price"
            label="Стоимость без скидки, ₽"
            required
            @update:valid="valid = $event"
          />
          <app-input
            v-model:value="product.price.original"
            mode="discount"
            label="Скидка, %"
            required
            @update:valid="valid = $event"
          />
        </div>
        <div class="partner-products__form-general--title">
          Категория товара
        </div>
        <app-select
          :input-value="product.categoryId"
          :options="categories"
          placeholder="Выберите категорию"
          @update:value="setCategory($event)"
          @update:valid="valid = $event"
        />
        <div class="partner-products__form-general--title">
          Размещение товара
        </div>
        <app-select
          :input-value="product.retailPointId"
          :options="points"
          placeholder="Выберите точку"
          required
          @update:value="product.retailPointId = $event"
          @update:valid="valid = $event"
        />
        <div class="partner-products__form-general--title">Фото</div>
        <app-file-input
          multiple
          @update:files="setImages($event)"
          :uploadedImages="product.images"
        />
      </div>
      <div class="partner-products__form-general-buttons">
        <app-button
          class="partner-products__form-general-btn"
          :disabled="!checkChange || !checkValid"
          @click="edit"
          >Редактировать</app-button
        >
        <app-button
          mode="secondary"
          class="partner-products__form-general-btn"
          @click="
            $router.push({
              name: 'PartnerProducts',
              query: { key: this.$route.query.key },
            })
          "
          >Отмена</app-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
import FileInput from "@/components/UI/Inputs/FileInput";
import Select from "@/components/UI/Selects/Select";
import Button from "@/components/UI/Buttons/Button";
import { mapActions, mapGetters } from "vuex";
import Preloader from "@/components/UI/Preloader";

export default {
  name: "EditProduct",
  components: {
    "app-input": Input,
    "app-file-input": FileInput,
    "app-select": Select,
    "app-button": Button,
    "app-loader": Preloader,
  },
  data: () => ({
    points: null,
    categories: {
      1: "Красота и здоровье",
      2: "Торговля",
      3: "Путешествия и отдых",
      4: "Еда и напитки",
      6: "Автомобили",
      7: "Разное",
    },
    product: {
      title: "",
      description: "",
      price: {
        oldPrice: "",
        original: "",
      },
      categoryId: "",
      images: [],
      retailPointId: "",
      productType: "product",
    },
    types: ["Товар", "Спецпредложение"],
    loading: false,
    valid: false,
  }),
  async created() {
    this.loading = true;
    await this.setPartnerPoints({
      key: this.$route.query.key,
    });
    await this.loadSinglePartnerProduct(this.editItemId);
    this.points = JSON.parse(JSON.stringify(this.getPartnerPoints));
    this.product.retailPointId = Object.keys(this.points)[0];
    this.product = JSON.parse(JSON.stringify(this.getSingleProduct));
    this.loading = false;
  },
  computed: {
    ...mapGetters("partner", ["getPartnerPoints"]),
    ...mapGetters("products", ["getSingleProduct"]),
    checkChange() {
      return (
        JSON.stringify(this.product) !== JSON.stringify(this.getSingleProduct)
      );
    },
    checkValid() {
      return (
        this.valid &&
        this.product.title &&
        this.product.description &&
        this.product.price.actualPrice &&
        this.product.price.original &&
        this.product.categoryId &&
        this.product.images.length
      );
    },
    editItemId() {
      return this.$route.params.id;
    },
    productPrice: {
      get() {
        return this.product.price.original < 25
          ? this.product.price.oldPrice
          : this.product.price.actualPrice;
      },
      set(newVal) {
        this.product.price.actualPrice = newVal;
        this.product.price.oldPrice = newVal;
        // this.product.price.oldPrice < 25
        //   ? (this.product.price.actualPrice = newVal)
        //   : (this.product.price.oldPrice = newVal);
      },
    },
  },
  methods: {
    ...mapActions("partner", ["setPartnerPoints"]),
    ...mapActions("products", ["editOneProduct", "loadSinglePartnerProduct"]),
    async edit() {
      await this.editOneProduct(this.product);
      await this.$router.push({
        name: "PartnerProducts",
        query: { key: this.$route.query.key },
      });
    },
    setImages(e) {
      this.product.images = [...this.product.images, ...e];
    },
    setCategory(e) {
      this.product.categoryId = e;
    },
  },
};
</script>
