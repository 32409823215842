<template>
  <app-tabs
    :items="categories"
    :active="activeCategory"
    @changeActive="changeActiveCategory"
  />
  <app-subscribe-banner v-if="!user.subscription" />
  <app-offers-group
    v-if="getCatalogView === 'list'"
    :offers-group="products"
    :is-loading="isLoading"
    :is-end="isEndProducts"
    @loadMore="loadProducts"
  ></app-offers-group>
  <app-offers-map
    v-if="getCatalogView === 'map'"
    :offers="getProductsList"
    :categories="categories"
    :current-category-id="activeCategory"
    :loading="isLoading"
  ></app-offers-map>
</template>

<script>
import Tabs from "@/components/UI/Tabs/Tabs";
import SubscribeBanner from "@/components/UI/SubscribeBanner";
import { mapActions, mapGetters } from "vuex";
import OffersGroup from "@/components/Offers/OffersGroup";
import Map from "@/components/YMap";

export default {
  name: "Products",
  components: {
    "app-tabs": Tabs,
    "app-subscribe-banner": SubscribeBanner,
    "app-offers-group": OffersGroup,
    "app-offers-map": Map,
  },
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
    ...mapGetters("products", {
      products: "getProducts",
      categories: "getCategories",
      activeCategory: "getActiveCategory",
      isLoading: "getLoadingStatus",
      isEndProducts: "getEndProducts",
      getProductsList: "getProductsList",
    }),
    ...mapGetters(["getCatalogView"]),
  },
  async created() {
    await this.loadCategories("products");
  },
  methods: {
    ...mapActions("products", [
      "loadCategories",
      "changeActiveCategory",
      "loadProducts",
    ]),
  },
};
</script>

<style lang="scss"></style>
