export default {
  companyTitle: "",
  INN: "",
  owner: "",
  OGRN: "",
  KPP: "",
  juridicalAddress: {
    main: "",
    street: "",
    house: "",
    flat: "",
  },
  address: {
    main: "",
    street: "",
    house: "",
    flat: "",
  },
};
