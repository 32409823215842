<template>
  <app-header />

  <app-preloader
    :value="getLoadingStatus === 'pending' || userLoadStatus === 'pending'"
  />

  <app-error v-if="error" />

  <router-view v-else-if="userLoadStatus !== 'pending'" />

  <app-footer />

  <app-button-scroll />

  <app-modal />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/UI/Header/Header";
import Footer from "@/components/UI/Footer";
import Modal from "@/components/UI/Modals/Modal";
import Preloader from "@/components/UI/Preloader";
import ButtonScroll from "@/components/UI/Buttons/ButtonScroll";
import Error from "@/components/Error/Error";
import helpers from "@/helpers";

export default {
  data: () => ({
    userLoadStatus: "pending", // ok pending error
  }),
  components: {
    "app-button-scroll": ButtonScroll,
    "app-modal": Modal,
    "app-footer": Footer,
    "app-header": Header,
    "app-preloader": Preloader,
    "app-error": Error,
  },
  computed: {
    ...mapGetters(["getLoadingStatus"]),
    ...mapGetters("error", ["error", "errorModal"]),
    ...mapGetters("user", ["user"]),
    ...mapGetters("auth", ["isAuthorized"]),
  },
  async created() {
    this.userLoadStatus = "pending";
    let res = await this.checkUserToken();
    this.userLoadStatus = res ? "ok" : "error";

    await this.getUser();

    await this.getCart();

    const query = helpers.methods.parseUrlQuery();
    if (query.referrer) {
      await this.setRefer(query.referrer);
    }

    this.setAppEmails();
    this.setPolicyUrls();
  },
  watch: {
    async isAuthorized() {
      if (this.isAuthorized) {
        await this.getCart();
      }
    },
    $route() {
      this.toggleError({ name: "", value: false });
    },
  },
  methods: {
    ...mapActions("auth", ["checkUserToken"]),
    ...mapActions("refer", ["setRefer"]),
    ...mapActions("user", ["getUser"]),
    ...mapActions("cart", ["getCart"]),
    ...mapActions(["setAppEmails", "setPolicyUrls"]),
    ...mapActions("error", ["toggleError"]),
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  & > main {
    flex-grow: 1;
  }
}
</style>
