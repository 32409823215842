<template>
  <div class="partner-stepper">
    <div class="partner-stepper__form">
      <div class="partner-stepper__steps">
        <div
          v-for="(step, index) in steps"
          :key="index"
          :class="[
            'step',
            activeStep === step.id ? 'active' : '',
            activeStep > step.id ? 'completed' : '',
          ]"
        >
          <span
            v-if="activeStep <= step.id"
            v-html="require(`@/assets/img/icons/circle.svg`)"
          />
          <span
            v-else
            v-html="require(`@/assets/img/icons/success-fill.svg`)"
          />
          {{ step.text }}
        </div>
      </div>
      <component
        v-if="activeStep < 5"
        :is="steps[activeStep - 1].value"
        :partner="info"
        :key="steps[activeStep - 1].id"
        @update:info="setPartner($event)"
        @update:valid="setValid($event)"
      />
      <app-check-page
        :partner="info"
        v-else-if="activeStep === 5"
        @update:step="complete"
        @gotostart="activeStep = 1"
      />
    </div>
    <div class="partner-stepper__buttons" v-if="activeStep < 5">
      <app-button-link
        v-if="!mobView"
        :class="['partner-stepper__btn', activeStep === 1 ? 'hidden' : '']"
        @click="prevStep"
        arrow
        left
      >
        К предыдущему шагу</app-button-link
      >
      <app-button
        class="partner-stepper__btn"
        icon="slim-arrow"
        :disabled="!valid"
        @click="nextStep"
        >{{ nextButton }}</app-button
      >
    </div>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Partner/Form/CompanyInfo";
import Button from "@/components/UI/Buttons/Button";
import CheckAccount from "@/components/Partner/Form/CheckAccount";
import Contacts from "@/components/Partner/Form/Contacts";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import { mapActions, mapGetters, mapMutations } from "vuex";
import PointInfo from "@/components/Partner/Form/PointInfo";
import CheckPage from "@/components/Partner/CheckPage";
import _debounce from "lodash/debounce";

export default {
  name: "PartnerStepper",
  components: {
    "app-company-info": CompanyInfo,
    "app-check-account": CheckAccount,
    "app-contacts": Contacts,
    "app-point-info": PointInfo,
    "app-button": Button,
    "app-button-link": ButtonLink,
    "app-check-page": CheckPage,
  },
  data: () => ({
    steps: [
      {
        id: 1,
        value: "app-company-info",
        text: "Компания",
      },
      {
        id: 2,
        value: "app-check-account",
        text: "Расчетный счет",
      },
      {
        id: 3,
        value: "app-point-info",
        text: "Торговая точка",
      },
      {
        id: 4,
        value: "app-contacts",
        text: "Контакты",
      },
    ],
    activeStep: 1,
    valid: true,
    partnerInfo: {},
    stepperInfo: {},
  }),
  computed: {
    ...mapGetters("partner", ["getPartnerInfo", "getLoading"]),
    info() {
      switch (this.activeStep) {
        case 1:
          return this.getPartnerInfo.companyInfo;
        case 2:
          return this.getPartnerInfo.bankInfo;
        case 3:
          return this.getPartnerInfo.structure;
        case 4:
          return this.getPartnerInfo.contacts;
        default:
          return this.getPartnerInfo;
      }
    },
    mobView() {
      return window.screen.width <= 768;
    },
    nextButton() {
      return this.activeStep === 4 ? "Проверить данные" : "Следующий шаг";
    },
  },
  methods: {
    ...mapMutations("partner", { setPartnerInfo: "SET_PARTNER_INFO" }),
    ...mapActions("partner", ["updatePartner"]),
    nextStep() {
      this.setPartnerInfo(this.stepperInfo);
      this.activeStep++;
      this.updatePartner();
    },
    prevStep() {
      this.activeStep--;
    },
    setPartner(e) {
      this.stepperInfo = JSON.parse(JSON.stringify({ [e.key]: e.value }));
    },
    setValid(valid) {
      this.valid = valid;
    },
    complete() {
      this.activeStep = 6;
    },
  },
  created() {
    this.updatePartner = _debounce(this.updatePartner, 500);
  },
};
</script>

<style lang="scss">
.partner-stepper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 850px;
  height: auto;
  padding: 40px 30px;
  border-radius: 30px;
  background: $primary;
  box-shadow: $popup-shadow;
  .stepper-form {
    &__group {
      display: flex;
      margin-bottom: 20px;
      .input {
        margin-right: 30px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__subgroup {
      display: flex;
      &-input {
        margin-right: 30px;
        &--flat {
          margin-right: 0;
          max-width: 175px;
        }
      }
    }
  }
  &__steps {
    width: 100%;
    display: flex;
    .step {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: 700;
      color: #eaeef4;
      margin-right: 30px;
      margin-bottom: 37px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: $bonus;
        span {
          margin-right: 10px;
          svg {
            rect {
              fill: $bonus;
            }
          }
        }
      }
      &.completed {
        color: $label-success;
        opacity: 0.5;
        span {
          svg {
            rect {
              fill: $label-success;
            }
          }
        }
      }
      span {
        margin-right: 10px;
        svg {
          rect {
            fill: #eaeef4;
          }
        }
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    .hidden {
      visibility: hidden;
    }
  }
  &__btn {
    max-width: 250px;
    & .button__icon {
      svg {
        fill: $bonus !important;
        margin-left: 15px;
      }
    }
  }
  //.file-input__wrapper {
  //  margin-bottom: 20px;
  //  width: 270px;
  //}
  //.images-item {
  //  margin-bottom: 20px;
  //}
  //.images-item__wrapper {
  //  width: 270px;
  //}
}
@media (max-width: $tablet-width) {
  .partner-stepper {
    padding: 20px 15px;
    &__steps {
      flex-wrap: wrap;
      & .step {
        width: 175px;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 11px;
        margin-right: 15px;
      }
    }
    &__buttons {
      display: block;
      .hidden {
        visibility: hidden;
      }
    }
    &__btn {
      margin-top: 2rem;
      max-width: 100%;
    }
  }
  .stepper-form {
    & .input {
      margin-bottom: 15px !important;
    }
    &__header {
      margin-top: 0;
      margin-bottom: 0;
      flex-direction: column;
    }
    &__group {
      flex-direction: column;
      margin-bottom: 0 !important;
      & .input {
        margin-bottom: 15px !important;
      }
    }
    &__subgroup {
      flex-direction: column;
      &-input--flat {
        max-width: 100% !important;
      }
    }
  }
}
</style>
