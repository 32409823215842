<template>
  <button class="sbp-button" :disabled="disabled">
    <span v-html="require(`@/assets/img/icons/sbp-white.svg`)"></span>
  </button>
</template>

<script>
export default {
  name: "sbp",
  props: {
    disabled: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.sbp-button {
  width: 100%;
  min-width: 220px;
  min-height: 64px;
  background: #1d1346;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  cursor: pointer;
  &:hover {
    background: rgba(29, 19, 70, 0.8);
  }
  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  span {
    width: 74px;
    height: 40px;
    svg {
      width: inherit;
      height: inherit;
    }
  }
}
</style>
