import { authApi } from "@/api/auth";
import router from "@/router";

const projectID = process.env.VUE_APP_PROJECT_ID;

const checkPhone = (phone) => {
  return /[0-9]{11}/g.test(phone);
};

const getDefaultState = () => {
  return {
    isAuthorized:
      !!localStorage.getItem(`${projectID}SpecialsRefresh`) &&
      checkPhone(localStorage.getItem(`${projectID}SpecialsUser`)),
    login: localStorage.getItem(`${projectID}SpecialsUser`) || "",
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    REGISTER(state, data) {
      // console.log(data);
      localStorage.setItem(`${projectID}SpecialsUser`, data.login);
      localStorage.setItem(`${projectID}SpecialsToken`, data.token);
      localStorage.setItem(`${projectID}SpecialsRefresh`, data.refresh);
    },
    LOGIN(state, data) {
      localStorage.setItem(`${projectID}SpecialsUser`, data.login);
      localStorage.setItem(`${projectID}SpecialsToken`, data.token);
      localStorage.setItem(`${projectID}SpecialsRefresh`, data.refresh);

      state.isAuthorized = true;
      state.login = data.login;
    },
    LOGOUT(state) {
      localStorage.removeItem(`${projectID}SpecialsUser`);
      localStorage.removeItem(`${projectID}SpecialsToken`);
      localStorage.removeItem(`${projectID}SpecialsRefresh`);

      state.isAuthorized = false;
      state.login = "";
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async checkUserToken({ commit }) {
      try {
        const token = localStorage.getItem(`${projectID}SpecialsToken`);
        const refresh = localStorage.getItem(`${projectID}SpecialsRefresh`);

        if (!token || !refresh) {
          const res = await authApi.register();
          commit("REGISTER", res.data);
        }

        return true;
      } catch (e) {
        console.log(e, "registerAnonUser error");
        return false;
      }
    },
    async sendToken(_, phone) {
      return await authApi.sendToken({ phone });
    },
    async approveToken({ commit }, data) {
      const res = await authApi.approveToken(data);
      commit("LOGIN", res.data);
      commit("user/SET_USER", res.data, { root: true });
      return res;
    },
    async logout({ commit, dispatch, rootGetters }) {
      // если есть реферальный ключ
      // сохраняем в бд для нового анонимного пользователя
      if (rootGetters["refer/refer"]) {
        await dispatch("refer/setRefer", rootGetters["refer/refer"], {
          root: true,
        });
      }
      commit("LOGOUT");
      dispatch("resetState", null, { root: true });
      dispatch("checkUserToken");

      // перенаправляем на главную
      router.push({ name: "Offers" });
    },
  },
  getters: {
    login: (state) => state.login,
    isAuthorized: (state) => state.isAuthorized,
  },
};
