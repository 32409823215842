const getDefaultState = () => {
  return {
    value: false,
    name: "",
    permanent: false,
    redirect: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    TOGGLE_MODAL(state, data) {
      state.value = data.value;
      state.name = data.name;
      state.permanent = data.permanent || false;
      state.redirect = data.redirect || null;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    toggleModal({ commit }, data) {
      commit("TOGGLE_MODAL", data);
    },
  },
  getters: {
    value: (state) => state.value,
    name: (state) => state.name,
    permanent: (state) => state.permanent,
    redirect: (state) => state.redirect,
  },
};
