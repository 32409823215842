<template>
  <div
    :class="['partner-products__form-general', isCollapse ? 'collapse' : '']"
  >
    <div class="partner-products__form-general-header">
      <div class="partner-products__form-general-header-text">
        <div
          v-if="checkValid"
          class="partner-products__form-general-header-text-icon"
          v-html="require(`@/assets/img/icons/success-fill.svg`)"
        />
        <div>{{ product.title ? product.title : "Товар" }}</div>
      </div>
      <div class="partner-products__form-general-header-icons">
        <div
          class="partner-products__form-general-header-icon"
          v-html="require(`@/assets/img/icons/trash.svg`)"
          @click="$emit('delete')"
        />
        <div
          class="partner-products__form-general-header-icon"
          v-html="require(`@/assets/img/icons/arrow-top.svg`)"
          @click="isCollapse = !isCollapse"
        />
      </div>
    </div>
    <div v-show="!isCollapse">
      <div class="partner-products__form-general--title">Описание</div>
      <app-input v-model:value="productItem.title" label="Заголовок" required />
      <app-input
        v-model:value="productItem.description"
        label="Описание"
        required
        @update:valid="valid = $event"
      />
      <div class="partner-products__form-general--title">Стоимость</div>
      <div class="partner-products__form-general--group">
        <app-input
          v-model:value="productItem.price.oldPrice"
          mode="price"
          label="Стоимость без скидки, ₽"
          required
          @update:valid="valid = $event"
        />
        <app-input
          v-model:value="productItem.price.original"
          mode="discount"
          label="Скидка, %"
          required
          @update:valid="valid = $event"
        />
      </div>
      <div class="partner-products__form-general--title">Категория товара</div>
      <app-select
        :options="categories"
        placeholder="Выберите категорию"
        @update:value="setCategory($event)"
        @update:valid="valid = $event"
      />
      <div class="partner-products__form-general--title">Размещение товара</div>
      <app-select
        :options="points"
        placeholder="Выберите точку"
        required
        @update:value="productItem.retailPointId = $event"
        @update:valid="valid = $event"
      />
      <div class="partner-products__form-general--title">Фото</div>
      <app-file-input @update:files="setImages($event)" multiple />
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
import FileInput from "@/components/UI/Inputs/FileInput";
import Select from "@/components/UI/Selects/Select";

export default {
  name: "ProductForm",
  components: {
    "app-input": Input,
    "app-file-input": FileInput,
    "app-select": Select,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    retailPointId: {
      type: [Number, String],
      required: true,
    },
    collapse: {
      type: Boolean,
      required: false,
      default: false,
    },
    points: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.isCollapse = this.collapse;
    this.productItem = JSON.parse(JSON.stringify(this.product));
    this.$emit("update:valid", this.checkValid);
  },
  data() {
    return {
      isCollapse: false,
      categories: {
        1: "Красота и здоровье",
        2: "Торговля",
        3: "Путешествия и отдых",
        4: "Еда и напитки",
        6: "Автомобили",
        7: "Разное",
      },
      products: [],
      productItem: {
        title: "",
        description: "",
        price: {
          oldPrice: "",
          original: "",
        },
        categoryId: "",
        images: [],
        retailPointId: "",
        productType: "product",
      },
      valid: false,
    };
  },
  computed: {
    checkValid() {
      return (
        this.valid &&
        this.product.title &&
        this.product.description &&
        this.product.price.oldPrice &&
        this.product.price.original &&
        this.product.categoryId &&
        this.product.images.length &&
        this.product.retailPointId
      );
    },
  },
  methods: {
    setImages(e) {
      this.productItem.images = e;
    },
    setCategory(e) {
      this.productItem.categoryId = e;
    },
  },
  watch: {
    productItem: {
      deep: true,
      handler() {
        this.$emit("update:product", this.productItem);
      },
    },
    checkValid() {
      this.$emit("update:valid", this.checkValid);
    },
    collapse() {
      this.isCollapse = this.collapse;
    },
  },
};
</script>
