<template>
  <button class="logout">
    <span v-html="require(`@/assets/img/icons/logout.svg`)"></span>
  </button>
</template>

<script>
export default {
  name: "Logout",
};
</script>

<style lang="scss">
.logout {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: $primary;
  border: none;
  border-radius: 10px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  transition: background-color $transition, box-shadow $transition;
  span {
    display: inline-flex;
    svg {
      width: 20px;
      height: 20px;
      fill: $accent;
      transition: fill $transition;
    }
  }
  &:hover {
    span {
      svg {
        fill: $accent-hover;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .logout {
    width: 60px;
    height: 60px;
    background-color: $primary;
    border-radius: $border-radius;
    box-shadow: 12px 12px 20px rgba(45, 80, 251, 0.15);
    span {
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &:hover {
      background-color: $input-hover;
      box-shadow: 0px 4px 25px rgba(45, 80, 251, 0.35);
    }
  }
}
</style>
