<template>
  <h3 class="stepper-form__title">Компания</h3>
  <div class="stepper-form">
    <div class="stepper-form__header">
      <app-input
        v-model:value="inn"
        mode="inn"
        @update:valid="validInput = $event"
        label="Поиск по ИНН"
        required
      />
    </div>

    <transition name="fade">
      <div v-if="showAllForm">
        <app-input
          v-model:value="companyInfo.owner"
          @update:valid="validInput = $event"
          label="ФИО генерального директора"
          required
        />
        <app-input
          v-model:value="companyInfo.juridicalAddress.main"
          @update:valid="validInput = $event"
          label="Юридический адрес"
          required
        />
        <div class="stepper-form__subgroup">
          <app-input
            class="stepper-form__subgroup-input"
            v-model:value="companyInfo.juridicalAddress.street"
            @update:valid="validInput = $event"
            label="Улица"
            required
          />
          <app-input
            class="stepper-form__subgroup-input"
            v-model:value="companyInfo.juridicalAddress.house"
            @update:valid="validInput = $event"
            label="Дом"
            required
          />
          <app-input
            class="stepper-form__subgroup-input--flat"
            v-model:value="companyInfo.juridicalAddress.flat"
            label="Кв./оф."
          />
        </div>
        <app-checkbox
          class="stepper-form__checkbox"
          v-model:value="addressMatch"
          >Юридический адрес совпадает с фактическим</app-checkbox
        >
        <app-input
          class="stepper-form__subgroup-input"
          :disabled="addressMatch"
          v-model:value="companyInfo.address.main"
          @update:valid="validInput = $event"
          label="Фактический адрес"
          required
        />
        <div class="stepper-form__subgroup">
          <app-input
            class="stepper-form__subgroup-input"
            :disabled="addressMatch"
            v-model:value="companyInfo.address.street"
            @update:valid="validInput = $event"
            label="Улица"
            required
          />
          <app-input
            class="stepper-form__subgroup-input"
            :disabled="addressMatch"
            v-model:value="companyInfo.address.house"
            @update:valid="validInput = $event"
            label="Дом"
            required
          />
          <app-input
            class="stepper-form__subgroup-input--flat"
            :disabled="addressMatch"
            v-model:value="companyInfo.address.flat"
            label="Кв./оф."
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
import { mapActions, mapGetters } from "vuex";
import Checkbox from "@/components/UI/Inputs/Checkbox";
import _debounce from "lodash/debounce";
// import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import companyInfoInterface from "@/store/modules/partner/interface/companyInfoInterface";
export default {
  name: "CompanyInfo",
  components: {
    "app-input": Input,
    "app-checkbox": Checkbox,
  },
  props: {
    partner: {
      type: Object,
      required: false,
      default: () => {
        return companyInfoInterface;
      },
    },
  },
  data: () => ({
    inn: "",
    showAllForm: false,
    validInput: false,
    addressMatch: false,
    companyInfo: companyInfoInterface,
  }),
  computed: {
    ...mapGetters("partner", ["getCompanyInfo"]),
  },
  methods: {
    ...mapActions("partner", ["searchByINN"]),

    innSearch: async function () {
      try {
        if (this.inn.length < 10) {
          this.showAllForm = false;
          return;
        }
        if (this.companyInfo.INN === this.inn) {
          this.showAllForm = true;
          return;
        }
        this.showAllForm = false;

        await this.searchByINN(this.inn);

        this.companyInfo = { ...this.companyInfo, ...this.getCompanyInfo };

        this.companyInfo.INN = this.inn;

        this.showAllForm = true;
      } catch (err) {
        this.showAllForm = true;
      }
    },
    checkValid: function () {
      const requiredAddressValues = ["main", "street", "house"];
      // обязательные поля
      const juridicalAddress = _pick(
        this.companyInfo.juridicalAddress,
        requiredAddressValues
      );
      const address = _pick(this.companyInfo.address, requiredAddressValues);

      const isValidAddress = [
        ...Object.values(address),
        ...Object.values(juridicalAddress),
      ].every((i) => !!i);

      const isValid =
        !!this.validInput &&
        !!this.companyInfo.INN &&
        !!this.companyInfo.owner &&
        isValidAddress;

      this.$emit("update:valid", isValid);

      if (isValid) {
        this.$emit("update:info", {
          key: "companyInfo",
          valid: true,
          value: this.companyInfo,
        });
      }

      return isValid;
    },
    copyJuridicalAddress: function () {
      this.companyInfo.address = Object.assign(
        this.companyInfo.juridicalAddress
      );
    },
  },
  created() {
    this.companyInfo = { ...this.companyInfo, ...this.partner };
    this.inn = this.companyInfo.INN || "";

    this.checkValid();

    this.innSearch = _debounce(this.innSearch, 500);
  },
  mounted() {
    this.addressMatch = _isEqual(
      this.companyInfo.juridicalAddress,
      this.companyInfo.address
    );
  },
  watch: {
    validInput: {
      handler() {
        this.checkValid();
      },
    },
    addressMatch: {
      handler(val) {
        if (val) {
          this.copyJuridicalAddress();
        } else {
          this.companyInfo.address = {
            main: "",
            street: "",
            house: "",
            flat: "",
          };
        }

        this.checkValid();
      },
    },
    inn: {
      deep: true,
      handler() {
        this.innSearch();
      },
    },
    companyInfo: {
      deep: true,
      handler() {
        if (this.addressMatch) {
          this.copyJuridicalAddress();
        }
        if (this.companyInfo.INN.length >= 10) {
          this.$emit("update:info", {
            key: "companyInfo",
            valid: this.checkValid(),
            value: this.companyInfo,
          });
        }
      },
    },
    partner: {
      deep: true,
      handler() {
        if (!_isEmpty(this.partner)) {
          this.companyInfo = Object.assign(this.partner);
          this.inn = this.companyInfo.INN;
          this.addressMatch = _isEqual(
            this.companyInfo.juridicalAddress,
            this.companyInfo.address
          );
        }
      },
    },
  },
};
</script>

<style lang="scss">
.stepper-form {
  &__header {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    .input {
      margin-right: 30px;
      margin-bottom: 0 !important;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .input {
    margin-bottom: 20px;
  }
  &__checkbox {
    margin-bottom: 20px;
    & .checkbox__label {
      color: $title;
      font-size: 20px;
    }
  }
}
</style>
