<template>
  <section class="payment-method form">
    <header>
      <h3>Способ оплаты</h3>
    </header>

    <main>
      <app-radio
        v-for="item in paymentTypes"
        :key="item"
        v-model:value="selectedPayment"
        :mode="item"
      />

      <!--      <app-button-link size="m" arrow @click="showModal">-->
      <!--        <slot>Изменить способ оплаты</slot>-->
      <!--      </app-button-link>-->

      <app-input
        v-model:value="emailValue"
        v-model:valid="emailValueValid"
        mode="email"
        label="Почта для отправки чека*"
      />
    </main>
  </section>
</template>

<script>
// import ButtonLink from "../UI/Buttons/Link.vue";
import Radio from "../UI/Inputs/Radio.vue";
import Input from "../UI/Inputs/Input.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "paymentMethod",
  components: {
    // "app-button-link": ButtonLink,
    "app-radio": Radio,
    "app-input": Input,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("user", ["setEmail", "setEmailValid"]),
    ...mapActions("paymentMethods", ["setPaymentType"]),
    ...mapActions("payment", ["createDraft"]),

    showModal() {
      // this.toggleModal({
      //   name: "payment",
      //   value: true,
      // });
    },
  },
  computed: {
    ...mapGetters("user", {
      email: "getEmail",
      emailValid: "getEmailValid",
    }),
    ...mapGetters("paymentMethods", {
      paymentType: "paymentType",
      paymentTypes: "paymentTypes",
    }),
    ...mapGetters("offers", {
      offer: "getOfferToPay",
    }),
    emailValue: {
      get() {
        return this.email;
      },
      set(value) {
        this.setEmail(value);
      },
    },
    emailValueValid: {
      get() {
        return this.emailValid;
      },
      set(value) {
        this.setEmailValid(value);
      },
    },
    selectedPayment: {
      get() {
        return this.paymentType;
      },
      set(val) {
        this.setPaymentType(val);
      },
    },
  },
  watch: {
    async paymentType() {
      await this.createDraft();
    },
  },
};
</script>

<style lang="scss">
.payment-method {
  & > main {
    .radio {
      margin: 5px 0;
    }

    .input {
      margin-top: 15px;
    }
  }
}

@media (min-width: $desktop-width) {
  .payment-method {
    & > main {
      .input {
        margin-top: 25px;
      }
    }
  }
}
</style>
