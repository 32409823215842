<template>
  <teleport to="body">
    <transition name="fade">
      <div v-if="value" class="overlay" />
    </transition>
  </teleport>
</template>

<script>
const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default {
  name: "Overlay",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    value: {
      handler() {
        const body = document.querySelector("body");
        if (this.value) {
          disableBodyScroll(body);
        } else {
          enableBodyScroll(body);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(126, 125, 142, 0.35);
  backdrop-filter: blur(8px);
}
</style>
