<template>
  <button
    class="button-scroll"
    v-scroll="onScroll"
    v-show="scrollButton"
    @click="toTop"
  >
    <div
      class="button-scroll__icon"
      v-html="require(`@/assets/img/icons/arrow.svg`)"
    />
  </button>
</template>

<script>
export default {
  name: "ButtonScroll",
  data() {
    return {
      scrollButton: false,
    };
  },
  watch: {
    visible() {
      this.scrollButton = true;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.scrollButton = top > 100;
    },
    toTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.button-scroll {
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  padding: 0;
  background-color: $primary;
  outline: none;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 0px 30px rgba(45, 80, 251, 0.15);
  cursor: pointer;
  transition: background-color $transition, box-shadow $transition;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: rotate(270deg);
    svg {
      transition: $transition;
      width: 12px;
      height: 18px;
      fill: $accent;
    }
  }
  &:hover {
    background-color: $accent;
    .button-scroll__icon {
      svg {
        fill: $primary;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .button-scroll {
    width: 64px;
    height: 64px;
    bottom: 50px;
    right: 50px;
    &__icon {
      svg {
        width: 16px;
        height: 24px;
      }
    }
  }
}
</style>
