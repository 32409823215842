<template>
  <div class="complete-error">
    <span
      class="complete-error__icon"
      v-html="require('@/assets/img/icons/warning.svg')"
    />

    <h1>Платеж не проведен</h1>

    <span class="complete-error__subtext">
      При обработке платежа произошла ошибка. <br />
      Попробуйте повторить попытку.
    </span>

    <app-button @click="goToCart"> Вернуться в корзину </app-button>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "CompleteError",
  components: {
    "app-button": Button,
  },
  methods: {
    goToCart() {
      this.$router.push({ name: "Cart" });
    },
  },
};
</script>

<style lang="scss">
.complete-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__icon {
    width: 80px;
    height: 80px;
    svg {
      fill: $label-hot;
      width: inherit;
      height: inherit;
    }
  }
  h1 {
    margin: 10px 0;
  }
  &__subtext {
    margin-bottom: 20px;
  }
  .button {
    width: fit-content;
    padding: 20px 30px;
  }
}
</style>
