<template>
  <section class="offer-description">
    <h3>Описание</h3>
    <ol class="offer-description__rules">
      <li v-for="rule in rules" :key="rule">
        {{ rule }}
      </li>
    </ol>
    <p>{{ slicedDescription }}</p>
    <button class="offer-description__more" v-if="isShowMore" @click="showAll">
      Читать полностью
    </button>
  </section>
</template>

<script>
export default {
  name: "OfferDescription",
  props: {
    rules: {
      type: Array,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sliceDescLength: 350,
      isShowMore: false,
    };
  },
  methods: {
    showAll() {
      this.sliceDescLength = this.description.length;
      this.isShowMore = false;
    },
  },
  computed: {
    slicedDescription: {
      get() {
        let res = "";
        if (this.description) {
          if (this.description.length >= this.sliceDescLength) {
            res = this.description.slice(0, this.sliceDescLength);
          } else {
            res = this.description;
          }
        }
        return res;
      },
    },
  },
  watch: {
    slicedDescription: {
      immediate: true,
      handler(val) {
        if (val && this.description) {
          this.isShowMore = val.length < this.description.length;
        }
      },
    },
  },
  created() {},
};
</script>

<style lang="scss">
.offer-description {
  margin-top: 30px;
  max-width: 900px;
  width: 100%;
  &__rules {
    color: $title;
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    padding-left: 20px;
  }
  &__more {
    color: $accent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: $transition;
    background-color: transparent;
    &:hover {
      color: $title;
    }
  }
}
</style>
