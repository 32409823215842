<template>
  <label
    :class="[
      'radio radio--tariff',
      {
        checked: active.id === value.id,
        group: !!group,
        readonly: readonly,
      },
    ]"
  >
    <input
      :id="value.id"
      type="radio"
      :value="value.id"
      :name="group"
      @change="update"
      :checked="active.id === value.id"
    />
    <div class="radio__tariff">
      <span>
        Подписка на {{ value.duration }}
        {{ $filters.monthDeclension(value.duration) }}
      </span>
      <span v-show="value.oldPrice">
        {{ value.oldPrice ? $filters.price(value.oldPrice) : "" }} ₽
      </span>
      <span>{{ $filters.price(value.price) }} ₽</span>
    </div>
  </label>
</template>

<script>
export default {
  name: "CartTariffRadio",
  props: {
    active: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    group: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    update() {
      this.$emit("update:active", this.value);
    },
  },
};
</script>

<style lang="scss">
.radio {
  &--tariff {
    gap: 10px;
  }

  &__tariff {
    display: grid;
    grid-template-columns: min-content min-content;
    grid-template-rows: auto;
    grid-row-gap: 6px;
    grid-column-gap: 6px;
    grid-template-areas:
      "title title title title"
      "price fullPrice . .";
    align-items: baseline;
    justify-items: start;

    span {
      white-space: nowrap;

      &:first-child {
        grid-area: title;
        font-size: 1.143rem;
        line-height: 1.375rem;
        font-weight: 600;
        color: $title;
      }

      &:nth-child(2) {
        grid-area: fullPrice;
        font-size: 0.857rem;
        line-height: 1rem;
        font-weight: 500;
        color: $text;
        text-decoration-line: line-through;
      }

      &:last-child {
        grid-area: price;
        font-size: 1.143rem;
        line-height: 1.375rem;
        font-weight: 600;
        color: $text;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .radio {
    &--tariff {
      gap: 15px;
    }

    &__tariff {
      grid-template-columns: 1fr max-content max-content;
      grid-column-gap: 12px;
      grid-template-areas: "title fullPrice price";

      span {
        &:first-child {
          font-size: 1rem;
          line-height: 1.375rem;
          color: $title;
        }

        &:nth-child(2) {
          font-size: 1rem;
          line-height: 1.375rem;
          color: $text;
        }

        &:last-child {
          font-size: 1.25rem;
          line-height: 1.375rem;
          color: $title;
        }
      }
    }
  }
}
</style>
