<template>
  <div class="sbp-block">
    <div class="sbp-block__list-title">
      1. Попробуйте совершить первую оплату через СБП
    </div>
    <app-select
      v-if="Object.keys(options).length > 1"
      class="sbp-block__list-payment-link"
      :options="options"
      placeholder="Выберите точку"
      v-model:value="selectedPointId"
    />
    <ul class="sbp-block__list">
      <li class="sbp-block__list-item">
        Для этого
        <a :href="qrPdfUrl" target="_blank"
          ><app-link :disabled="!selectedPointId" size="m"
            >отсканируйте QR-код</app-link
          ></a
        >
        или
        <a :href="`${paymentLink}`" target="_blank"
          ><app-link :disabled="!selectedPointId" size="m"
            >перейдите по ссылке</app-link
          ></a
        >
        на платежную страницу. QR-код необходимо будет разместить в кассовой
        зоне, чтобы вашим клиентам было удобно его отсканировать для проведения
        платежей.
      </li>
      <li class="sbp-block__list-item">
        Введите сумму покупки, выберите банк и нажмите кнопку "СБП". Вы будете
        перенаправлены в мобильное приложение банка, где и будет произведена
        оплата.
      </li>
      <li class="sbp-block__list-item">
        Для настольной версии по нажатию на кнопку будет предоставлен QR-код.
        Отсканируйте его камерой смартфона или в мобильном приложении банка для
        проведения оплаты.
      </li>
      <li class="sbp-block__list-item">
        Введите электронную почту для получения чека или откройте его со
        страницы успешной оплаты (необязательно).
        <a href="https://youtu.be/T_yNgJ1wRoQ" target="_blank"
          ><app-link size="m">Подробнее в нашем видео</app-link></a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import Select from "@/components/UI/Selects/Select";

export default {
  name: "CompletePageProducts",
  components: {
    "app-link": ButtonLink,
    "app-select": Select,
  },
  props: {
    points: {
      type: Array,
      required: true,
    },
  },
  created() {
    if (this.points.length === 1) {
      this.selectedPointId = this.points[0].id;
    }
  },
  data: () => ({
    selectedPointId: "",
  }),
  computed: {
    location() {
      return process.env.NODE_ENV === "development"
        ? { protocol: "http:", host: "localhost:3000" }
        : document.location;
    },
    options() {
      return this.points.reduce((acc = {}, item, index) => {
        if (item.id) {
          acc[item.id] = item.retailPointName || `Точка № ${index}`;
        }
        return acc;
      }, {});
    },
    paymentLink() {
      const point = this.points.find((p) => p.id === +this.selectedPointId);
      if (point) return point.paymentLink;
      else return "";
    },
    qrPdfUrl() {
      return `${this.location.protocol}//${this.location.host}/qr-pdf/${this.selectedPointId}`;
    },
  },
};
</script>
