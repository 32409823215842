<template>
  <label class="switch">
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="updateInput"
    />
    <span />
  </label>
</template>

<script>
export default {
  name: "Switch",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    updateInput(event) {
      this.$emit("update:value", event.target.checked);
    },
  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;

  input {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked + span {
      background-color: $accent;
    }

    &:disabled + span {
      background-color: $input;
      pointer-events: none;
    }

    &:checked + span:before {
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $text;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: $transition;
    transition: $transition;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 1px;
      bottom: 1px;
      background-color: $primary;
      border-radius: 50%;
      -webkit-transition: $transition;
      transition: $transition;
    }
  }
}
</style>
