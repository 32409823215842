<template>
  <a
    class="link-a"
    :class="{ disabled: !link.length }"
    :href="link"
    target="_blank"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: "Link",
  props: {
    link: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.link-a {
  color: $accent;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: none;
  transition: color $transition;

  &:hover {
    color: $title;
  }

  &.disabled {
    color: $text;
    pointer-events: none;
  }
}
</style>
