export default {
  namespaced: true,
  state: {
    favouritesIds: JSON.parse(localStorage.getItem("favourites")) || [],
    favouriteItems: [],
  },
  mutations: {
    SET_FAVOURITES(state, idsArr) {
      state.favouritesIds = idsArr;
    },
  },
  actions: {
    setFavourites({ commit, state }, offer) {
      let favourites = new Set(state.favouritesIds);
      const id = offer.id;

      if (!favourites.has(id)) {
        favourites.add(id);
      } else {
        favourites.delete(id);
      }
      commit("SET_FAVOURITES", [...favourites]);
      localStorage.setItem("favourites", JSON.stringify([...favourites]));
    },
  },
  getters: {
    favouritesItems: (state) => state.favouriteItems,
    favouritesIds: (state) => state.favouritesIds,
  },
};
