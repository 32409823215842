<template>
  <div class="offer-info" v-if="offer">
    <div
      class="offer-info__prices"
      v-if="
        $route.name !== 'PartnerInfo' && offer.price && offer.price.actualPrice
      "
    >
      <h3 class="offer-info__prices--actual">
        {{ offer.price.actualPrice }} ₽
      </h3>
      <span class="offer-info__prices--old" v-show="offer.price.oldPrice"
        >{{ offer.price.oldPrice }} ₽</span
      >
    </div>

    <div class="offer-info__buttons">
      <app-button
        class="offer-info__buttons-cart"
        :disabled="inCart"
        v-if="
          $route.name !== 'PartnerInfo' &&
          offer.price &&
          offer.price.actualPrice
        "
        @click.stop="addToCart(offer)"
      >
        <slot v-if="!inCart">В корзину</slot>
        <slot v-else>В корзине</slot>
      </app-button>
      <app-like-button
        v-if="$route.name !== 'PartnerInfo'"
        :offer="offer"
        class="offer-info__buttons-heart"
      />
      <app-button
        v-if="$route.name === 'PartnerInfo'"
        class="offer-info__buttons-cart"
        @click.stop="$router.push({ hash: '#offers' })"
      >
        Предложения партнера
      </app-button>
    </div>

    <div class="offer-info__contacts">
      <div class="offer-info__contacts-links">
        <app-link
          v-if="$route.name !== 'PartnerInfo'"
          :routerLink="`/partner-info/${offer.retailPointId}`"
        >
          <slot>{{ offer.retailPointName }}</slot>
        </app-link>
        <small v-if="$route.name !== 'PartnerInfo'"></small>
        <app-link
          v-if="cat(offer.categoryId)"
          routerLink="/"
          @clickLink="clickLink(offer.categoryId)"
        >
          <slot>{{ cat(offer.categoryId).title }}</slot>
        </app-link>
      </div>

      <div class="offer-info__contacts-address">
        {{ address }}
      </div>

      <div
        v-if="$route.name !== 'PartnerInfo'"
        class="offer-info__contacts-map"
      >
        <app-link routerLink="#offer-map" :underline="true">
          <slot>Посмотреть на карте</slot>
        </app-link>
      </div>

      <div class="offer-info__contacts-phone" v-if="offer.phones">
        <app-link
          v-for="phone in offer.phones"
          :key="phone"
          :link="`tel:${phone}`"
          icon="phone"
        >
          <slot>{{ phone }}</slot>
        </app-link>
      </div>
      <div class="offer-info__contacts-phone" v-if="offer.phone">
        <app-link :key="offer.phone" :link="`tel:${offer.phone}`" icon="phone">
          <slot>{{ offer.phone }}</slot>
        </app-link>
      </div>

      <app-link
        v-if="$route.name === 'PartnerInfo' && offer.linkToSite"
        :link="`mailto:${offer.linkToSite}`"
        icon="site"
      >
        <slot>{{ offer.linkToSite }}</slot>
      </app-link>

      <div
        v-if="$route.name === 'PartnerInfo'"
        class="offer-info__contacts-hours"
      >
        <span v-html="require(`@/assets/img/icons/clock.svg`)" />
        С 10 до 22 в будние дни и с 09 до 23 в выходные дни
      </div>

      <div
        class="offer-info__contacts-dates"
        v-if="$route.name !== 'PartnerInfo' && (offer.fromDate || offer.toDate)"
      >
        <div class="offer-info__contacts-dates--start" v-if="offer.fromDate">
          <small>Начало акции</small>
          <span>{{ parseDate(offer.fromDate) }}</span>
        </div>
        <div class="offer-info__contacts-dates--finish" v-if="offer.toDate">
          <small>Окончание акции</small>
          <span>{{ parseDate(offer.toDate) }}</span>
        </div>
      </div>
    </div>

    <div class="offer-info__similar"></div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
import { mapGetters, mapActions } from "vuex";
import Link from "@/components/UI/Buttons/Link";
import LikeButton from "@/components/UI/Buttons/LikeButton";

export default {
  name: "OfferInfo",
  components: {
    "app-like-button": LikeButton,
    "app-link": Link,
    "app-button": Button,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("offers", {
      cat: "getCategoryById",
    }),
    ...mapGetters("cart", ["cart"]),
    inCart() {
      if (this.cart.findIndex((i) => i.item._id === this.offer._id) !== -1) {
        return true;
      } else return false;
    },
    address() {
      if (this.offer && this.offer.address) {
        const { city, street, house } = this.offer.address;
        return `${city}, ${street} ${house}`;
      } else {
        return "";
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("offers", {
      changeCategory: "changeActiveCategory",
      setOfferToPay: "setOfferToPay",
    }),
    ...mapActions("cart", {
      addToCart: "addToCart",
    }),
    clickLink(id) {
      this.changeCategory(id);
    },
    parseDate(date) {
      return new Date(date).toLocaleString("ru", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
};
</script>

<style lang="scss">
.offer-info {
  &__prices {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &--actual {
      margin-right: 15px;
    }
    &--old {
      color: $text;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: line-through;
    }
  }
  &__buttons {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    max-width: 300px;
    margin-right: 30px;
    &-cart {
      margin-right: 30px;
    }
    &-heart {
    }
  }
  &__contacts {
    &-links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      small {
        display: inline-block;
        width: 2px;
        height: 15px;
        background-color: $text;
        margin: 0 5px;
      }
    }
    &-address,
    &-hours {
      margin: 8px 0;
    }
    &-hours {
      display: flex;
      align-items: center;
      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;
        margin-right: 8px;
        & > svg {
          fill: $text;
        }
      }
    }
    &-map {
      a {
        font-weight: 500;
      }
    }
    &-phone {
      margin: 15px 0 20px;
      display: flex;
      align-items: center;
      .link {
        &:not(:last-child) {
          margin-right: 10px;
        }
        a {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    &-dates {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: fit-content;
      gap: 0 30px;
      div {
        display: flex;
        flex-direction: column;
        small {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
        span {
          font-weight: 500;
          color: $title;
        }
      }
    }
  }
}
</style>
