<template>
  <div class="offers-group">
    <app-offers-slider
      v-for="point in offersGroup"
      :key="point.retailPointId"
      class="products-grid__slider"
      :point="point"
    />
  </div>
  <transition name="fade">
    <app-spinner v-if="isLoading && !isEnd" size="l" :fixed="false" />
  </transition>
  <load-on-scroll v-if="!isEnd && !isLoading" @intersect="$emit('loadMore')" />
</template>

<script>
import Spinner from "@/components/UI/Spinner";
import LoadOnScroll from "@/components/UI/LoadOnScroll";
import OffersSlider from "@/components/Offers/OffersSlider";
export default {
  name: "OffersGroup",
  components: {
    "load-on-scroll": LoadOnScroll,
    "app-spinner": Spinner,
    "app-offers-slider": OffersSlider,
  },
  props: {
    offersGroup: {
      type: Array,
      required: true,
      default: () => [
        {
          count: 0,
          products: [],
          retailPointId: null,
          retailPointName: "",
        },
      ],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isEnd: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
