<template>
  <main class="error">
    <main>
      <span
        v-if="name === 'empty-cart'"
        v-html="require(`@/assets/img/icons/empty-cart.svg`)"
      ></span>
      <span v-else v-html="require(`@/assets/img/icons/error.svg`)" />
      <component :is="`app-${name}`" />
      <app-button v-if="name === 'empty-cart'">
        <router-link to="/">Перейти в каталог</router-link>
      </app-button>
    </main>
    <footer v-if="['draft', 'payment-type'].indexOf(name) !== -1">
      <app-button @click="close"> Понятно, закрыть </app-button>
    </footer>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Button from "@/components/UI/Buttons/Button.vue";
import Internal from "@/components/Error/components/Internal.vue";
import NotFound from "@/components/Error/components/NotFound.vue";
import PaymentType from "@/components/Error/components/PaymentType.vue";
import Draft from "@/components/Error/components/Draft.vue";
import EmptyCard from "@/components/Error/components/EmptyCart.vue";

export default {
  name: "Error",
  components: {
    "app-button": Button,
    "app-internal": Internal,
    "app-not-found": NotFound,
    "app-payment-type": PaymentType,
    "app-draft": Draft,
    "app-empty-cart": EmptyCard,
  },
  computed: {
    ...mapGetters("error", ["name", "value"]),
    showError: {
      get() {
        return this.value;
      },
      set(value) {
        this.toggleError({
          name: "",
          value,
        });
      },
    },
  },
  methods: {
    ...mapActions("error", ["toggleError"]),
    close() {
      this.showError = false;
    },
  },
};
</script>

<style lang="scss">
.error {
  display: flex;
  align-self: center;
  width: 100%;

  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .error-message {
    max-width: 600px;
  }
  main {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      width: 127px;
      height: 120px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    h2 {
      margin: 15px 0 10px;
      text-align: center;
    }
    p {
      margin: 0;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: $text;
      span {
        white-space: nowrap;
      }
    }
  }
  .button {
    width: 236px;
    margin-top: 25px;
    a {
      color: #ffffff;
    }
  }
}

@media (min-width: $desktop-width) {
  .error {
    main {
      span {
        width: 180px;
        height: 170px;
      }
      h2 {
        margin: 25px 0 10px;
      }
      p {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }
}
</style>
