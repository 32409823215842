<template>
  <div class="products__main">
    <app-loader
      v-if="getLoadingStatus === 'pending'"
      :value="getLoadingStatus === 'pending'"
    />
    <!--    <h2 class="partner-products__title">-->
    <!--      Товары на площадке {{ projectName }}-->
    <!--    </h2>-->
    <div class="products__main-header">
      <div class="products__main-header-title">Параметры загрузки</div>
      <div class="products__main-header-actions">
        <app-select
          v-model:value="currentPoint"
          :options="points"
          placeholder="Торговая точка"
        />
        <app-button @click="addProduct">+ Добавить товары</app-button>
      </div>
    </div>
    <div class="products__main-content">
      <div class="products__main-content-header">
        <div class="products__main-content-header-title">
          Товары
          <span class="products__main-content-header-title-count">{{
            products.length
          }}</span>
        </div>
        <app-link size="l" @click="remove(products.map((i) => i.id))"
          >Удалить все товары</app-link
        >
      </div>
      <div class="products__main-content__table" v-if="!mobView">
        <div class="products__main-content__table-row table-header">
          <div class="products__main-content__table-row-image"></div>
          <div
            class="products__main-content__table-row-title table-header__title"
          >
            Заголовок
          </div>
          <div class="products__main-content__table-row-price">
            Стоимость без скидки, ₽
          </div>
          <div class="products__main-content__table-row-discount">
            Скидка, %
          </div>
          <div class="products__main-content__table-row-actions"></div>
        </div>
        <div
          v-for="(product, index) in products"
          :key="index"
          class="products__main-content__table-row"
          :class="addRowClass(product)"
        >
          <div class="products__main-content__table-row-image">
            <img
              class="products__main-content__table-row-image-item"
              :src="product.images[0]"
              alt="product_image"
            />
          </div>
          <div
            class="products__main-content__table-row-title table-title"
            @click="goToProduct(product)"
          >
            {{ product.title }}
          </div>
          <div class="products__main-content__table-row-price">
            {{
              product.price.ratio
                ? product.price.actualPrice
                : product.price.oldPrice
            }}
          </div>
          <div class="products__main-content__table-row-discount">
            {{ product.price.original }}
          </div>
          <div class="products__main-content__table-row-actions">
            <span
              class="products__main-content__table-row-actions-icon"
              @click="toEdit(product.id)"
              v-html="require(`@/assets/img/icons/edit.svg`)"
            />
            <span
              class="products__main-content__table-row-actions-icon"
              @click="remove([product.id])"
              v-html="require(`@/assets/img/icons/trash.svg`)"
            />
          </div>
        </div>
      </div>
      <div v-else class="products__main-content__table">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="products__main-content__table-row"
          :class="addRowClass(product)"
        >
          <div class="products__main-content__table-row-image">
            <img
              class="products__main-content__table-row-image-item"
              :src="product.images[0]"
              alt="product_image"
            />
          </div>
          <div class="products__main-content__table-row-items">
            <div class="products__main-content__table-row-head">Заголовок</div>
            <div
              class="products__main-content__table-row-title table-title"
              @click="goToProduct(product)"
            >
              {{ product.title }}
            </div>
            <div class="products__main-content__table-row-head">
              Стоимость товара, ₽
            </div>
            <div class="products__main-content__table-row-price">
              {{
                product.price.ratio
                  ? product.price.actualPrice
                  : product.price.oldPrice
              }}
            </div>
            <div class="products__main-content__table-row-head">Скидка, %</div>
            <div class="products__main-content__table-row-discount">
              {{ product.price.original }}
            </div>
            <div class="products__main-content__table-row-actions">
              <span
                class="products__main-content__table-row-actions-icon"
                @click="toEdit(product.id)"
                v-html="require(`@/assets/img/icons/edit.svg`)"
              />
              <span
                class="products__main-content__table-row-actions-icon"
                @click="remove([product.id])"
                v-html="require(`@/assets/img/icons/trash.svg`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/UI/Selects/Select";
import Button from "@/components/UI/Buttons/Button";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import { mapActions, mapGetters } from "vuex";
import Preloader from "@/components/UI/Preloader";

export default {
  name: "ProductsMain",
  components: {
    "app-select": Select,
    "app-button": Button,
    "app-link": ButtonLink,
    "app-loader": Preloader,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    points: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentPoint: "",
  }),
  computed: {
    ...mapGetters("products", ["getLoadingStatus"]),
    projectName() {
      return process.env.VUE_APP_PROJECT_ID === "chotoff" ? "IBonus" : "BBonus";
    },
    mobView() {
      return window.screen.width <= 768;
    },
  },
  methods: {
    ...mapActions("products", ["removeProductsByIds"]),
    addRowClass(id) {
      let rowType = this.products.indexOf(id) % 2 ? "odd" : "even";
      return [
        `products__main-content__table-row`,
        `products__main-content__table-row--${rowType}`,
      ];
    },
    addProduct() {
      this.$router.push({
        name: "NewProduct",
        query: { key: this.$route.query.key },
      });
    },
    goToProduct(product) {
      let routeData = this.$router.resolve({
        name: "Offer",
        params: { id: product.products[0] },
      });
      window.open(routeData.href, "_blank");
    },
    toEdit(id) {
      this.$router.push({
        name: "EditProduct",
        params: { id },
        query: { key: this.$route.query.key },
      });
    },
    async remove(ids) {
      await this.removeProductsByIds(ids);
      this.$emit("removed");
    },
  },
  watch: {
    currentPoint() {
      this.$emit("update:point", this.currentPoint);
    },
  },
};
</script>

<style lang="scss">
.products__main {
  width: 850px;
  &-header {
    box-shadow: $popup-shadow;
    padding: 20px 30px;
    background: $primary;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    &-title {
      color: $title;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    &-actions {
      display: flex;
      & .select-wrapper {
        margin-right: 30px;
      }
      & .button {
        width: 300px;
      }
    }
  }
  &-content {
    background: $primary;
    box-shadow: $popup-shadow;
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 40px;
    overflow: hidden;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
      &-title {
        font-size: 22px;
        font-weight: 700;
        color: $title;
        position: relative;
        &-count {
          font-size: 18px;
          font-weight: 700;
          color: $text;
          position: absolute;
          top: -7px;
          right: -13px;
        }
      }
    }
    &__table {
      background: #f9fafc;
      width: calc(100% + 60px);
      margin-left: -30px;
      &-row {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
        column-gap: 20px;
        padding: 10px 30px;
        &--even {
          background: #f4f5f7;
        }
        &.table-header {
          font-size: 12px;
          max-height: 20px;
        }
        &.table-header__title {
          color: $text !important;
        }
        &-image {
          width: 90px;
          height: 60px;
          &-item {
            width: 100%;
            border-radius: 8px;
          }
        }
        &-title {
          &.table-title {
            cursor: pointer;
            color: $title;
          }
        }
        &-actions {
          display: flex;
          justify-content: end;
          &-icon {
            cursor: pointer;
          }
          &-icon:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .products__main {
    width: 100%;
    &-header {
      padding: 15px;
      &-actions {
        flex-direction: column;
        & .select-wrapper {
          margin-right: 0;
          margin-bottom: 15px;
        }
        & .button {
          width: 100%;
        }
      }
    }
    &-content {
      padding: 20px 15px;
      &__table {
        width: calc(100% + 30px);
        margin-left: -15px;
        &-row {
          display: flex;
          color: $title;
          font-size: 16px;
          padding: 15px;
          &-head {
            color: $text;
          }
          &-image {
            width: 50%;
          }
          &-items {
            width: 100%;
          }
          &-actions {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
