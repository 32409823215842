<template>
  <button
    :class="`button button--${size} button--${mode}`"
    :disabled="isDisabled"
  >
    <app-spinner v-if="loading" mode="light" size="s" />
    <p class="button-text" v-else>
      <slot></slot>
    </p>
    <span
      v-if="icon"
      class="button__icon"
      v-html="require(`@/assets/img/icons/${icon}.svg`)"
    />
  </button>
</template>

<script>
import Spinner from "@/components/UI/Spinner";
export default {
  name: "Button",
  components: {
    "app-spinner": Spinner,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: "m",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      required: false,
      default: "primary",
    },
    icon: {
      type: String,
      required: false,
    },
  },
  computed: {
    isDisabled() {
      return this.disabled || this.loading;
    },
  },
};
</script>

<style lang="scss">
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  white-space: nowrap;
  border: none;
  overflow: hidden;
  cursor: pointer;
  transition: all $transition;
  border-radius: $border-radius;
  &:focus {
    outline: none;
  }
  &--primary {
    color: $primary;
    padding: 0 15px;
    height: 64px;
    background-color: $accent;
    box-shadow: $button-shadow;

    &:disabled {
      background-color: $accent-disabled;
      box-shadow: $button-shadow-disabled;
      pointer-events: none;
      cursor: default;
    }
    &:hover {
      background: $accent-hover;
      box-shadow: $button-shadow-hover;
    }
  }

  &--secondary {
    color: $accent;
    height: 50px;
    border: 2px solid rgba(45, 80, 251, 0.6) !important;
    background-color: transparent;
    padding: 15px 20px;
    &:disabled {
      opacity: 0.25;
      pointer-events: none;
      cursor: default;
    }
    &:hover {
      color: $primary;
      background-color: $accent;
    }
  }

  &--s {
    height: 36px;
    border-radius: $border-radius-half;
    & .button-text {
      font-size: 1rem;
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
}
</style>
