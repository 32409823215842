<template>
  <h3>Контактные данные</h3>
  <div class="stepper-form">
    <div class="stepper-form__header">
      <app-input
        v-model:value="contacts.contactPerson"
        label="Контактное лицо"
        required
        @update:valid="validInput = $event"
      />
    </div>
    <div class="stepper-form__group">
      <app-input
        v-model:value="contacts.email"
        mode="email"
        label="Электронная почта"
        required
        @update:valid="validInput = $event"
      />
      <app-input
        v-model:value="contacts.phone"
        mode="phone"
        label="Телефон"
        required
        @update:valid="validInput = $event"
      />
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
import { mapGetters } from "vuex";

export default {
  name: "Contacts",
  components: {
    "app-input": Input,
  },
  props: {
    partner: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    contacts: {
      contactPerson: "",
      email: "",
      phone: "",
    },
    validInput: false,
  }),
  methods: {
    checkValid() {
      const isValidPhone = this.contacts.phone.length === 11;
      const isValid =
        this.validInput &&
        isValidPhone &&
        Object.values(this.contacts).every((i) => !!i);
      this.$emit("update:valid", isValid);
      return isValid;
    },
  },
  created() {
    this.contacts = Object.assign(this.contacts, this.partner);
    this.checkValid();
  },
  computed: {
    ...mapGetters("partner", ["getContacts"]),
  },
  watch: {
    contacts: {
      deep: true,
      handler() {
        const isValid = this.checkValid();
        this.$emit("update:info", {
          key: "contacts",
          valid: isValid,
          value: this.contacts,
        });
      },
    },
    validInput: {
      handler() {
        this.checkValid();
      },
    },
  },
};
</script>
