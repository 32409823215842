import { paymentApi } from "@/api/payment";
import router from "../../router";

const getDefaultState = () => {
  return {
    draft: {
      accountChanges: [],
    },
    completeResponse: {},
    pointIdForPay: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    CREATE_DRAFT(state, payload) {
      state.draft = payload;
    },
    SET_COMPLETE_RESPONSE(state, data) {
      state.completeResponse = data;
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_POINT_ID_FOR_PAY(state, data) {
      state.pointIdForPay = data;
    },
  },
  actions: {
    async createDraft({ commit, rootGetters, dispatch, state }) {
      let data = {
        paymentType: rootGetters["paymentMethods/paymentType"],
        id: null,
        productType: null,
      };
      const user = rootGetters["user/user"];
      try {
        commit("SET_LOADING_STATUS", "pending", { root: true });
        if (!user.subscription) {
          data.id = rootGetters["tariffs/tariff"].id;
          data.productType = "SUBSCRIPTION";
        } else {
          data.id = state.pointIdForPay;
          data.productType = "PAYMENT";
        }

        console.log(data);
        const res = await paymentApi.createDraft(data);
        commit("CREATE_DRAFT", res.data.order.draftInfo);
        commit("order/SET_ORDER", res.data.order, { root: true });

        commit("SET_LOADING_STATUS", "ok", { root: true });
      } catch (e) {
        commit("SET_LOADING_STATUS", "error", { root: true });

        await dispatch(
          "error/toggleError",
          {
            name: "internal",
            value: true,
          },
          { root: true }
        );
      }
    },
    async processDraft({ rootGetters, commit, dispatch }) {
      try {
        commit("SET_LOADING_STATUS", "pending", { root: true });

        const id = rootGetters["order/order"].id;

        const data = {
          email: rootGetters["user/getEmail"],
          returnUrl: `${window.location.origin}/complete?id=${id}`,
        };
        const res = await paymentApi.processDraft(id, data);

        commit("SET_COMPLETE_RESPONSE", res.data.data);
        commit("SET_LOADING_STATUS", "ok", { root: true });

        if (res.data.data.formUrl)
          window.location.replace(res.data.data.formUrl);
        else router.push({ name: "Complete" });
      } catch (e) {
        commit("SET_LOADING_STATUS", "error", { root: true });

        await dispatch(
          "error/toggleError",
          {
            name: "internal",
            value: true,
          },
          { root: true }
        );
      }
    },
    async completeDraft({ commit }, id) {
      try {
        commit("SET_LOADING_STATUS", "pending", { root: true });

        const res = await paymentApi.completeDraft(id);
        commit("SET_COMPLETE_RESPONSE", res.data);

        commit("SET_LOADING_STATUS", "ok", { root: true });
      } catch (e) {
        commit("SET_LOADING_STATUS", "error", { root: true });
      }
    },
  },
  getters: {
    getDraft: (state) => state.draft,
    draft: (state) => state.draft,
    draftAccount: (state) => (accountType) => {
      const account = state.draft.accountChanges.find(
        (el) => el.accountType === accountType
      );
      if (account) {
        return account;
      } else {
        return {
          accountType: accountType,
          spend: 0,
          earn: 0,
        };
      }
    },
    draftExtra: (state, getters) => {
      return getters.draftAccount("EXTRA");
    },
    draftGlobal: (state, getters) => {
      return getters.draftAccount("GLOBAL");
    },
    completeResponse: (state) => state.completeResponse,
    pointIdForPay: (state) => state.pointIdForPay,
  },
};
