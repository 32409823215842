<template>
  <section class="auth">
    <header>
      <h2>Вход | Регистрация</h2>
    </header>

    <main>
      <p class="auth__info">
        Выполните вход или зарегистрируйтесь для применения подписки или ее
        приобретения.
      </p>

      <app-input
        v-model:value="form.phone"
        v-model:valid="phoneValid"
        mode="phone"
        label="Телефон"
        placeholder="+7"
        required
        :disabled="loader"
        :focus="true"
      />

      <div v-if="sended">
        <app-input
          v-model:value="form.code"
          v-model:valid="codeValid"
          mode="code"
          label="Код из SMS"
          placeholder="•  •  •  •  •"
          required
          :disabled="loader"
          :wrong-code="errors.code"
          :focus="true"
        />

        <app-timer
          class="auth__timer"
          :resended="resended"
          :disabled="loader"
          @send="send"
        />
      </div>

      <app-checkbox
        v-if="!sended"
        v-model:value="form.agreedOffer"
        :disabled="loader"
      >
        Я согласен с
        <app-link :link="getPolicyURLs.participantOffer"
          >условиями оферты</app-link
        >
      </app-checkbox>
      <app-checkbox
        v-if="!sended"
        v-model:value="form.agreedPolicy"
        :disabled="loader"
      >
        Я согласен с
        <app-link :link="getPolicyURLs.privatePolicy">
          политикой конфиденциальности</app-link
        >
      </app-checkbox>
    </main>

    <footer v-if="!sended">
      <app-button :disabled="!formValid || loader" @click="send">
        Отправить код
      </app-button>
    </footer>

    <p v-if="errors.server" class="auth__error error-message">
      Ошибка сервера. Повторите попытку.
    </p>

    <app-spinner v-if="loader" mode="dark" size="m" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Input from "@/components/UI/Inputs/Input.vue";
import Checkbox from "@/components/UI/Inputs/Checkbox.vue";
import Button from "@/components/UI/Buttons/Button.vue";
import Link from "@/components/UI/Links/Link.vue";
import Spinner from "@/components/UI/Spinner.vue";
import Timer from "@/components/UI/Modals/components/Auth/components/Timer.vue";

export default {
  name: "Auth",
  components: {
    "app-input": Input,
    "app-checkbox": Checkbox,
    "app-button": Button,
    "app-link": Link,
    "app-spinner": Spinner,
    "app-timer": Timer,
  },
  data: () => ({
    form: {
      phone: "",
      code: "",
      agreedOffer: false,
      agreedPolicy: false,
    },
    phoneValid: false,
    codeValid: false,
    sended: false, // true, если первый запрос на отправку токена отправлен успешно
    resended: false, // true, если повторный запрос на отправку токена отправлен успешно
    loader: false,
    errors: {
      server: false,
      code: false,
    },
  }),
  computed: {
    ...mapGetters("modal", ["redirect"]),
    ...mapGetters(["getPolicyURLs"]),
    formValid() {
      return (
        this.form.phone &&
        this.phoneValid &&
        this.form.agreedPolicy &&
        this.form.agreedOffer
      );
    },
  },
  watch: {
    async "form.code"() {
      if (this.form.code.length === 5 && this.codeValid) {
        try {
          this.loader = true;
          await this.approveToken({
            phone: this.form.phone,
            token: this.form.code,
          });

          if (this.redirect) {
            this.$router.push(this.redirect);
          }

          this.toggleModal({
            name: "auth",
            value: false,
          });
        } catch (e) {
          if (e && e.data && e.data.statusCode === 1204) {
            this.errors.code = true;
          } else {
            this.errors.server = true;
          }
        } finally {
          this.loader = false;
        }
      }
    },
    form: {
      handler() {
        this.clearErrors();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("auth", ["sendToken", "approveToken"]),
    ...mapActions("modal", ["toggleModal"]),
    async send() {
      this.resended = false;
      this.clearErrors();
      try {
        this.loader = true;
        await this.sendToken(this.form.phone);
        this.sended = true;
        this.resended = true;
      } catch (e) {
        this.errors.server = true;
      } finally {
        this.loader = false;
      }
    },
    clearErrors() {
      for (const error in this.errors) {
        this.errors[error] = false;
      }
    },
  },
};
</script>

<style lang="scss">
.auth {
  main {
    & > p {
      margin-bottom: 15px;
    }

    .input {
      margin-bottom: 15px;
    }

    .checkbox {
      margin-bottom: 5px;
    }

    .auth__info {
      margin-bottom: 13px;
    }
  }
  &__timer {
    margin-bottom: 15px !important;
    padding: 0 15px;
  }
  &__error {
    position: absolute;
    bottom: 5px;
    left: 15px;
    right: 15px;
  }
}

@media (min-width: $desktop-width) {
  .auth {
    main {
      .checkbox {
        margin-bottom: 10px;
      }

      .auth__info {
        margin-bottom: 15px;
      }
    }
    &__timer {
      margin-bottom: 18px !important;
      padding: 0 20px;
    }
    &__error {
      bottom: 10px;
      left: 30px;
      right: 30px;
    }
  }
}
</style>
