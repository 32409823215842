<template>
  <div class="breadcrumbs">
    <router-link to="/">Главная</router-link>
    <router-link v-if="prevPage" :to="`/offer/${prevPage.id}`">{{
      prevPage.title
    }}</router-link>
    <a class="active">{{ currentPage }}</a>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    currentPage: {
      type: String,
      required: false,
    },
    prevPage: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  padding: 30px 0;
  display: flex;
  align-items: center;
  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    &.active {
      opacity: 0.5;
      cursor: default;
    }
    &:not(.active) {
      &::after {
        content: "";
        width: 6px;
        height: 6px;
        background-color: $text;
        display: inline-block;
        margin-left: 5px;
        clip-path: polygon(100% 50%, 0 0, 0 100%);
      }
    }
  }
  @media (max-width: $desktop-width) {
    display: none;
  }
}
</style>
