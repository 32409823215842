<template>
  <h3>Расчетный счет</h3>
  <div class="stepper-form">
    <div class="stepper-form__header">
      <app-input
        v-model:value="checkAccount.BIK"
        mode="bik"
        label="Поиск по БИК банка"
        required
        @update:valid="validInput = $event"
      />
    </div>
    <transition name="fade">
      <div v-if="showAllForm">
        <h5 v-if="checkAccount.bankName.length">{{ checkAccount.bankName }}</h5>
        <div class="stepper-form__group">
          <app-input
            v-model:value="checkAccount.receiverINN"
            mode="inn"
            label="ИНН банка получателя"
            required
            @update:valid="validInput = $event"
          />
          <app-input
            v-model:value="checkAccount.account"
            mode="account"
            label="Расчетный счет"
            required
            @update:valid="validInput = $event"
          />
        </div>
        <div class="stepper-form__group">
          <app-input
            v-model:value="checkAccount.KPP"
            label="КПП"
            required
            mode="bik"
          />
          <app-input
            v-model:value="checkAccount.correspondentAcc"
            label="Корреспондентский счет"
            mode="account"
            required
            @update:valid="validInput = $event"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
import { mapActions, mapGetters } from "vuex";
import _debounce from "lodash/debounce";
import bankInfoInterface from "@/store/modules/partner/interface/bankInfoInterface";
export default {
  name: "CheckAccount",
  components: {
    "app-input": Input,
  },
  props: {
    partner: {
      type: Object,
      required: false,
      default: () => {
        return bankInfoInterface;
      },
    },
  },

  data: () => ({
    // bik: "",
    showAllForm: false,
    checkAccount: bankInfoInterface,
    validInput: false,
  }),
  created() {
    this.checkAccount = { ...this.checkAccount, ...this.partner };

    this.checkValid();

    this.searchBankData = _debounce(this.searchBankData, 500);
  },
  methods: {
    ...mapActions("partner", ["searchByBIK"]),
    checkValid() {
      let isValid = this.validInput;

      if (!isValid) {
        this.$emit("update:valid", isValid);
        return isValid;
      }

      if (this.checkAccount.BIK.length < 9) {
        isValid = false;
        this.$emit("update:valid", isValid);
        return isValid;
      }

      const requiredValues = [
        "BIK",
        "receiverINN",
        "account",
        "KPP",
        "correspondentAcc",
      ];

      const checkRequiredArr = requiredValues.map((item) => {
        return !!this.checkAccount[item];
      });

      isValid = !checkRequiredArr.includes(false);

      this.$emit("update:valid", isValid);

      if (isValid) {
        this.$emit("update:info", {
          key: "bankInfo",
          valid: isValid,
          value: this.checkAccount,
        });
      }

      return isValid;
    },
    searchBankData: async function () {
      try {
        if (this.checkAccount.BIK.length < 9) {
          this.showAllForm = false;
          return;
        }

        if (this.partner.BIK === this.checkAccount.BIK) {
          this.showAllForm = true;
          return;
        }

        await this.searchByBIK(this.checkAccount.BIK);

        for (let key in this.getBankInfo) {
          const value = this.getBankInfo[key];
          if (value) {
            this.checkAccount[key] = value;
          }
        }

        this.showAllForm = true;
      } catch (err) {
        this.showAllForm = true;
      } finally {
        this.checkValid();
      }
    },
  },
  computed: {
    ...mapGetters("partner", ["getBankInfo"]),
  },
  watch: {
    validInput: {
      handler() {
        this.checkValid();
      },
    },
    checkAccount: {
      deep: true,
      handler() {
        this.searchBankData();
      },
    },
  },
};
</script>

<style lang="scss"></style>
