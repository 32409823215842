<template>
  <h2>Сбой в перерасчете суммы</h2>
  <p>Попробуйте перезагрузить страницу и повторить попытку</p>
</template>

<script>
export default {
  name: "DraftError",
};
</script>
