<template>
  <div class="voucher-popup">
    <div
      class="voucher-popup__icon"
      v-html="require('@/assets/img/icons/message.svg')"
    />
    <div class="voucher-popup__title">Ваучер на скидку</div>
    <div class="voucher-popup__text">
      Дает право воспользоваться скидкой при покупке в торговой точке партнера.
    </div>
    <div class="voucher-popup__subtitle">Как воспользоваться?</div>
    <div class="voucher-popup__text">
      <ol class="voucher-popup__text-list">
        <li>Выбрать точку, в которой действует ваучер;</li>
        <li>Посетить точку партнера;</li>
        <li>Оплатить товар или услугу с учетом скидки.</li>
      </ol>
    </div>
    <app-button class="voucher-popup__button" @click="close"
      >Понятно закрыть</app-button
    >
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
import { mapActions } from "vuex";

export default {
  name: "VoucherPopup",
  components: {
    "app-button": Button,
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    close() {
      this.toggleModal({
        name: "voucher",
        value: false,
      });
    },
  },
};
</script>

<style lang="scss">
.voucher-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 15px 0;
  &__icon {
    margin: 0 auto 15px;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    color: $title;
    margin-bottom: 10px;
  }
  &__text {
    line-height: 21px;
    margin-bottom: 10px;
    &-list {
      padding-inline-start: 15px;
    }
  }
  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    color: $title;
  }
}
</style>
