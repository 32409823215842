<template>
  <label
    :class="[
      'radio radio--payment',
      {
        checked: value === mode,
        group: !!name,
        readonly: readonly,
      },
    ]"
  >
    <input
      :id="mode"
      type="radio"
      :value="mode"
      :name="name"
      @change="updateInput"
      :checked="value === mode"
    />
    <span
      :class="['radio__card', { 'radio__card--new-card': mode === 'NEW_CARD' }]"
    >
      <span class="radio__card-type">
        {{ cardType }}
      </span>
      <span v-if="mode !== 'NEW_CARD' && cardNum" class="radio__card-num">
        &bull;&bull; {{ cardNum }}
      </span>
      <span
        v-if="mode !== 'NEW_CARD'"
        :class="`radio__card-icon radio__card-icon--${cardIcon}`"
        v-html="require(`@/assets/img/icons/${cardIcon}.svg`)"
      />
      <span v-if="mode === 'NEW_CARD'" class="radio__card-icons">
        <span
          class="radio__card-icon radio__card-icon--mastercard"
          v-html="require(`@/assets/img/icons/mastercard.svg`)"
        />
        <span
          class="radio__card-icon radio__card-icon--maestro"
          v-html="require(`@/assets/img/icons/maestro.svg`)"
        />
        <span
          class="radio__card-icon radio__card-icon--visa"
          v-html="require(`@/assets/img/icons/visa.svg`)"
        />
        <span
          class="radio__card-icon radio__card-icon--mir"
          v-html="require(`@/assets/img/icons/mir.svg`)"
        />
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: "Radio",
  props: {
    mode: {
      type: String,
      required: true,
      validator(value) {
        return (
          [
            "NEW_CARD",
            "SBP_QR",
            "VISA",
            "MASTERCARD",
            "MAESTRO",
            "MIR",
            "APPLE",
            "GOOGLE",
          ].indexOf(value) !== -1
        );
      },
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    cardNum: {
      type: String,
      required: false,
      default: "",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cardType() {
      switch (this.mode) {
        case "VISA":
          return "Visa";
        case "MASTERCARD":
          return "Mastercard";
        case "MAESTRO":
          return "Maestro";
        case "MIR":
          return "Mir";
        case "APPLE":
          return "Apple Pay";
        case "GOOGLE":
          return "Google Pay";
        case "NEW_CARD":
          return "Оплата картой";
        case "SBP_QR":
          return "СБП";
        default:
          return "";
      }
    },
    cardIcon() {
      switch (this.mode) {
        case "VISA":
          return "visa";
        case "MASTERCARD":
          return "mastercard";
        case "MAESTRO":
          return "maestro";
        case "MIR":
          return "mir";
        case "APPLE":
          return "apple";
        case "GOOGLE":
          return "google";
        case "SBP_QR":
          return "sbp-black";
        case "NEW_CARD":
        default:
          return "";
      }
    },
  },
  methods: {
    updateInput() {
      this.$emit("update:value", this.mode);
    },
  },
};
</script>

<style lang="scss">
.radio {
  &--payment {
    gap: 10px;
    height: 64px;
    padding: 10px 15px;
    font-size: 1.25rem;
    line-height: 30px;
    font-weight: 500;
    color: $title;
    border-radius: $border-radius;
    background-color: $primary;
    box-shadow: $popup-shadow;

    &:hover {
      background-color: $input-hover;
    }
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-type {
      color: $title;
    }

    &-num {
      margin: 0 6px;
      color: $text;
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-left: auto;
      &--visa svg {
        width: calc(61px / 1.5);
        height: calc(21px / 1.5);
      }

      &--mastercard svg {
        width: calc(48px / 1.5);
        height: calc(30px / 1.5);
      }

      &--maestro svg {
        width: calc(48px / 1.5);
        height: calc(30px / 1.5);
      }

      &--mir svg {
        width: calc(74px / 1.5);
        height: calc(21px / 1.5);
      }

      &--apple svg {
        width: calc(61px / 1.5);
        height: calc(26px / 1.5);
      }

      &--google svg {
        width: calc(64px / 1.5);
        height: calc(34px / 1.5);
      }
    }

    &--new-card {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    &-icons {
      display: grid;
      grid-template-columns: repeat(4, auto);
      gap: 8px;
      .radio__card-icon {
        display: flex;
        &--visa svg {
          width: calc(61px / 2);
          height: calc(21px / 2);
        }

        &--mastercard svg {
          width: calc(48px / 2);
          height: calc(30px / 2);
        }

        &--maestro svg {
          width: calc(48px / 2);
          height: calc(30px / 2);
        }

        &--mir svg {
          width: calc(74px / 2);
          height: calc(21px / 2);
        }
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .radio {
    &--payment {
      gap: 15px;
      padding: 10px 20px;
    }

    &__card {
      &-icon {
        &--visa svg {
          width: 61px;
          height: 21px;
        }

        &--mastercard svg {
          width: 48px;
          height: 30px;
        }

        &--maestro svg {
          width: 48px;
          height: 30px;
        }

        &--mir svg {
          width: 74px;
          height: 21px;
        }

        &--apple svg {
          width: 61px;
          height: 26px;
        }

        &--google svg {
          width: 64px;
          height: 34px;
        }
      }
    }
  }
}
</style>
