<template>
  <section class="goods-group-item">
    <app-checkbox v-model:value="checkboxValue" />

    <img
      v-if="!groupItem.images.length"
      src="@/assets/img/empty-photo.png"
      alt="cart good image"
    />
    <img v-else :src="groupItem.images[0]" alt="cart good image" />

    <section class="goods-group-item__descr">
      <p class="goods-group-item__title">
        {{
          groupItem.title.length > 55
            ? groupItem.title.substring(0, 55) + "..."
            : groupItem.title
        }}
      </p>

      <p class="goods-group-item__actual-price">
        {{ groupItem.price.actualPrice }} ₽
      </p>

      <p class="goods-group-item__old-price">
        {{ groupItem.price.oldPrice }} ₽
      </p>
    </section>

    <section class="goods-group-item__action">
      <app-count v-model:value="countValue" />

      <app-button-link size="m" @click="del">Удалить</app-button-link>
    </section>
  </section>
</template>

<script>
import Checkbox from "@/components/UI/Inputs/Checkbox";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import Count from "@/components/UI/Inputs/Count";

export default {
  name: "GoodsGroupItem",
  components: {
    "app-checkbox": Checkbox,
    "app-button-link": ButtonLink,
    "app-count": Count,
  },
  props: {
    picked: {
      type: Boolean,
      required: true,
    },
    groupItem: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data: () => ({
    checkboxValue: false,
    countValue: 1,
  }),
  methods: {
    del() {
      this.$emit("del", this.groupItem);
    },
  },
  watch: {
    picked(val) {
      this.checkboxValue = val;
    },
    count: {
      handler(val) {
        this.countValue = val;
      },
      immediate: true,
    },
    countValue(val) {
      this.$emit("changeCount", val);
    },
  },
};
</script>

<style lang="scss">
.goods-group-item {
  display: grid;
  grid-template-columns: 30px 90px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "checkbox img descr"
    "checkbox img action";
  align-items: start;
  column-gap: 8px;
  row-gap: 10px;
  margin-bottom: 10px;

  & > .checkbox {
    grid-area: checkbox;
  }

  & > img {
    grid-area: img;
    width: 100%;
    height: 100%;
    max-height: 50px;
    border-radius: 6px;
    object-fit: cover;
  }

  &__descr {
    display: grid;
    //grid-template-columns: repeat(2, minmax(1fr, max-content));
    grid-template-columns: minmax(min-content, max-content);
    grid-template-rows: auto;
    grid-template-areas:
      "title title"
      "actual-price old-price";
    align-items: center;
    justify-content: start;
    column-gap: 8px;
    row-gap: 6px;
  }

  &__action {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "count"
      "del";
    align-items: center;
    column-gap: 15px;
    row-gap: 15px;
  }

  &__title {
    grid-area: title;
    font-size: 0.857rem;
    line-height: 1.143rem;
    font-weight: 600;
    color: $title;
  }

  &__actual-price {
    grid-area: actual-price;
    font-size: 1.143rem;
    line-height: 1.357rem;
    font-weight: 600;
    color: $title;
  }

  &__old-price {
    grid-area: old-price;
    font-size: 0.857rem;
    line-height: 1rem;
    font-weight: 500;
    color: $text;
    text-decoration-line: line-through;
  }

  .count {
    grid-area: count;
  }

  .button-link {
    grid-area: del;
  }
}

@media (min-width: $desktop-width) {
  .goods-group-item {
    grid-template-columns: 30px 130px 1fr;
    column-gap: 15px;
    justify-content: center;

    & > .checkbox {
      align-self: center;
    }

    & > img {
      max-height: 75px;
    }

    &__descr {
      grid-template-columns: 1fr max-content max-content;
      grid-template-areas: "title old-price actual-price";
      align-items: start;
      justify-content: end;
      column-gap: 12px;
      row-gap: 0;
    }

    &__action {
      grid-template-columns: max-content max-content;
      grid-template-areas: "count del";
      column-gap: 30px;
      row-gap: 0;
    }

    &__title {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &__actual-price {
      font-size: 1.25rem;
      line-height: 1.375rem;
    }

    &__old-price {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    & > .count {
    }

    & > .button-link {
    }
  }
}
</style>
