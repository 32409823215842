<template>
  <div class="offersSlider">
    <div class="offersSlider__header">
      <router-link :to="`/products/${point.retailPointId}`">
        <h2 class="offersSlider__title">
          {{ point.retailPointName }}<sup>{{ point.products.length }}</sup>
        </h2>
      </router-link>
      <div class="offersSlider__right">
        <router-link
          class="offersSlider__all-link"
          :to="`/products/${point.retailPointId}`"
        >
          Все
        </router-link>
        <router-link
          class="offersSlider__all-btn"
          :to="`/products/${point.retailPointId}`"
        >
          <app-button class="offersSlider__btn" mode="secondary">
            <slot>Смотреть все</slot>
          </app-button>
        </router-link>
        <div class="offersSlider__buttons">
          <app-slider-button
            @click="prevSlide"
            direction="left"
            :disabled="swiperVariables.isStart"
          />
          <app-slider-button
            @click="nextSlide"
            direction="right"
            :disabled="swiperVariables.isEnd"
          />
        </div>
      </div>
    </div>
    <swiper
      class="offersSlider__swiper"
      slidesPerView="1"
      @swiper="onSwiper"
      :space-between="15"
      :pagination="{ clickable: true }"
      :breakpoints="{
        768: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }"
    >
      <swiper-slide v-for="product in point.products" :key="product._id">
        <app-offer-card :cardInfo="product" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/swiper.min.css";
import SliderButton from "@/components/UI/Buttons/Slider";
import OfferCard from "@/components/Offers/Card/OfferCard";
import Button from "@/components/UI/Buttons/Button";
import SwiperCore, { Pagination } from "swiper";
export default {
  name: "OffersSlider",
  components: {
    "app-slider-button": SliderButton,
    Swiper,
    SwiperSlide,
    "app-offer-card": OfferCard,
    "app-button": Button,
  },
  props: {
    point: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    swiper: null,
    swiperVariables: {
      isStart: true,
      isEnd: false,
    },
  }),
  created() {
    SwiperCore.use([Pagination]);
  },
  watch: {
    swiper: {
      deep: true,
      handler(swiper) {
        this.swiperVariables.isEnd = swiper.isEnd;
        this.swiperVariables.isStart = swiper.isBeginning;
      },
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    nextSlide() {
      this.swiper.slideNext();
    },
    prevSlide() {
      this.swiper.slidePrev();
    },
  },
};
</script>

<style scoped lang="scss">
.offersSlider {
  max-width: 100vw;
  margin: 0 -15px;
  &__swiper {
    padding: 0 15px 30px;
    .swiper-slide-prev {
      &::v-deep {
        .offer-card {
          box-shadow: none;
        }
      }
    }
    &::v-deep {
      .swiper-pagination-bullet {
        background-color: $text-light;
        border: none;
        &-active {
          background-color: $accent;
        }
      }
      .swiper-wrapper {
        margin-bottom: 15px;
        align-items: stretch;
      }
      .swiper-slide {
        height: auto;
      }
    }
  }
  &__title {
    color: $accent;
    font-size: 24px;
  }
  &__buttons {
    display: none;
  }
  &__all-link {
    color: $accent;
    font-size: 16px;
  }
  &__all-btn {
    display: none;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;
    padding: 0 15px;
  }
  &__btn {
    width: 156px;
  }
  &::v-deep {
    .offersSlider__btn p {
      font-size: 1rem;
    }
  }
}
@media (min-width: $desktop-width) {
  .offersSlider {
    max-width: none;
    margin: 0 -30px;
    &__swiper {
      padding: 0 30px 60px;
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
    }
    &__all-btn {
      display: block;
    }
    &__all-link {
      display: none;
    }
    &__right {
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }
    &__btn {
      margin-right: 30px;
    }
    &__header {
      padding: 0 30px 15px;
      margin-bottom: 15px;
      align-items: center;
    }
    &__title {
      font-size: 38px;
    }
  }
}
</style>
