import { server } from "@/api/index";

export const productsApi = {
  getProducts(data, pointId) {
    if (pointId) {
      return server.post(`/special/web/search/${pointId}`, data);
    }
    return server.post("/special/web/search", data);
  },
  getRandomProducts(count) {
    return server.get(`/special/web/search/random?count=${count}`);
  },
  categories(projectId, type) {
    return server.get(`/categories/${projectId}/?type=${type}`);
  },
  singleProduct(id) {
    return server.get(`/special/product/${id}`);
  },
  addProduct(data) {
    return server.post("/special/product/add", data);
  },
  addProducts(data) {
    return server.post("/special/product/addMany", data);
  },
  addProductImages(data) {
    return server.post("/special/product/image", data);
  },
  getProductsByPoints(ids) {
    return server.post("/special/search/products", ids);
  },
  removeProductsByIds(ids) {
    return server.post("/special/product/delete", ids);
  },
  editOneProduct(data) {
    return server.patch("/special/product/edit", data);
  },
  getRatioProducts(data) {
    return server.post(`/special/web/search/ratio`, data);
  },
};
