<template>
  <footer class="footer">
    <div class="app-container">
      <div class="footer__wrap">
        <div class="footer__apps-wrapper">
          <router-link to="/">
            <span
              class="footer__apps-logo"
              v-html="
                require(`@/assets/img/icons/${projectId}/logo-desktop.svg`)
              "
            />
          </router-link>

          <div class="footer__apps">
            <a
              href="#"
              target="_blank"
              class="footer__apps-icon"
              v-html="require(`@/assets/img/icons/appstore.svg`)"
            ></a>
            <a
              :href="GooglePlayLink"
              target="_blank"
              class="footer__apps-icon"
              v-html="require(`@/assets/img/icons/googleplay.svg`)"
            ></a>
          </div>
        </div>

        <ul class="footer__links contacts">
          <li><a href="tel:8-800-444-10-10">8 (800) 444-10-10</a></li>
          <li>
            <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
          </li>
        </ul>

        <ul class="footer__links">
          <li v-for="item of docsUrlLinks" :key="item.index">
            <a :href="item.url">{{ item.label }}</a>
          </li>
          <li>
            <router-link to="/partner/create"
              >Регистрация партнера на площадке</router-link
            >
          </li>
        </ul>
      </div>

      <div class="footer__bottom">
        <span
          >© {{ projectId === "hotoff" ? "IBonus" : "BBonus" }} , 2022 -
          Спецпредложения и скидки</span
        >
        <span>Программа лояльности под вашим брендом</span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters({
      getPolicyURLs: "getPolicyURLs",
    }),
    ...mapState({ supportEmail: "supportEmail" }),
    GooglePlayLink() {
      let refQuery = this.user.referId
        ? `?referrer=inviter${this.user.referId}`
        : "";
      if (process.env.VUE_APP_PROJECT_ID === "hotoff") {
        return `https://play.google.com/store/apps/details${refQuery}&id=com.bmscard.hotoffers`;
      }
      if (process.env.VUE_APP_PROJECT_ID === "chotof") {
        return `https://play.google.com/apps/testing/com.bmscard.сhotoffers/${refQuery}`;
      }
      return "";
    },
    docsUrlLinks() {
      return [
        {
          label: `Политика конфиденциальности ${
            this.projectId === "hotoff" ? "IBonus" : "BBonus"
          }`,
          url: this.getPolicyURLs.privatePolicy,
        },
        {
          label: ` Оферта для участника ${
            this.projectId === "hotoff" ? "IBonus" : "BBonus"
          }`,
          url: this.getPolicyURLs.participantOffer,
        },
        {
          label: `Оферта для партнера ${
            this.projectId === "hotoff" ? "IBonus" : "BBonus"
          }`,
          url: this.getPolicyURLs.partnerOffer,
        },
      ];
    },
    projectId() {
      return process.env.VUE_APP_PROJECT_ID;
    },
  },
};
</script>

<style lang="scss">
.footer {
  font-size: $font-size-root;
  min-height: 200px;
  padding: 15px 20px;
  background-color: $text;
  color: $input;
  display: flex;
  justify-content: center;
  align-items: center;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__apps-logo {
    display: inline-block;
    margin-bottom: 10px;
  }
  &__apps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-icon {
      height: 40px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  &__links {
    padding: 0;
    & li {
      list-style: none;
      margin-bottom: 0.5rem;
    }
    & a {
      padding: 0.1rem 0;
      color: $input;
      transition: $transition;
      border-bottom: 1.5px solid rgba(255, 255, 255, 0);
    }
    & a:hover,
    & a:focus {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  &__links.contacts {
    order: 3;
    text-align: right;
    font-style: normal;
    font-weight: 600;

    & li:first-child {
      font-size: 20px;
      margin-bottom: 1rem;
    }
  }
  &__bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }
}
@media (max-width: 1240px) {
  .footer {
    &__wrap {
      flex-direction: column;
      justify-content: center;
    }
    &__apps-logo {
      display: none;
    }
    &__apps {
    }
    &__links.contacts {
      order: 0;
      text-align: left;
    }
    &__bottom {
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
