<template>
  <h2>Корзина пуста</h2>
  <p>
    Воспользуйтесь каталогом спецпредложений и товаров, чтобы найти выгодные
    решения
  </p>
</template>

<script>
export default {
  name: "EmptyCart",
};
</script>
