<template>
  <div class="vouchers-page">
    <app-voucher-card
      v-for="(item, index) in getVouchers"
      :key="index"
      :item="item"
      type="voucher"
    />
    <app-voucher-card
      v-for="(item, index) in getCoupons"
      :key="index"
      :item="item"
      type="coupon"
    />
  </div>
</template>

<script>
import VoucherCard from "@/components/Vouchers/VoucherCard";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Vouchers",
  components: {
    "app-voucher-card": VoucherCard,
  },
  async created() {
    await this.loadVouchers();
    await this.loadCoupons();
  },
  computed: {
    ...mapGetters("vouchers", ["getVouchers", "getCoupons"]),
  },
  methods: {
    ...mapActions("vouchers", ["loadVouchers", "loadCoupons"]),
  },
};
</script>

<style lang="scss">
.vouchers-page {
  width: 100%;
}
@media (min-width: $desktop-width) {
  .vouchers-page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
  }
}
</style>
