import { vouchersApi } from "@/api/vouchers";

export default {
  namespaced: true,
  state: {
    vouchers: [],
    coupons: [],
  },
  mutations: {
    SET_VOUCHERS(state, data) {
      state.vouchers = data;
    },
    SET_COUPONS(state, data) {
      state.coupons = data;
    },
  },
  actions: {
    async loadVouchers({ commit }) {
      try {
        const vouchers = await vouchersApi.loadVouchers(
          localStorage.getItem(`${process.env.VUE_APP_PROJECT_ID}SpecialsUser`)
        );
        commit("SET_VOUCHERS", vouchers.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async loadCoupons({ commit }) {
      try {
        const coupons = await vouchersApi.loadCoupons();
        commit("SET_COUPONS", coupons.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
  getters: {
    getVouchers: (state) => state.vouchers,
    getCoupons: (state) => state.coupons,
    getVouchersAndCouponsLength: (state) =>
      state.vouchers.length + state.coupons.length,
  },
};
