<template>
  <div class="images">
    <div v-for="(item, index) in images" :key="index" class="images-item">
      <div class="images-item__wrapper">
        <img :src="item" alt="img" />
      </div>
      <div
        class="images-item__close"
        v-html="require(`@/assets/img/icons/close.svg`)"
        @click="removeImg(index)"
      />
    </div>
    <div class="file-input__wrapper">
      <input
        type="file"
        multiple
        class="file-input"
        @change="setFiles($event)"
      />
      <div
        class="file-input__wrapper-icon"
        v-html="require(`@/assets/img/icons/camera.svg`)"
      />
      <div class="file-input__text">
        Перетащите сюда или выберите jpeg, jpg, png. 1280х720 px
      </div>
      <app-button mode="secondary">+ Добавить</app-button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";

export default {
  name: "FileInput",
  components: {
    "app-button": Button,
  },
  props: {
    uploadedImages: {
      type: Array,
      required: false,
    },
  },
  created() {
    if (this.uploadedImages) {
      this.images = this.uploadedImages;
    }
  },
  data: () => ({
    files: [],
    images: [],
  }),
  methods: {
    setFiles(e) {
      // Array.from(e.target.files).forEach((item) => {
      //   this.files.push(item);
      // });
      this.files = [...this.files, ...Array.from(e.target.files)];
      this.images = [
        ...Array.from(this.files).map((item) => URL.createObjectURL(item)),
      ];
    },
    removeImg(idx) {
      this.images.splice(idx, 1);
      this.files.splice(idx, 1);
    },
  },
  watch: {
    files: {
      deep: true,
      handler() {
        this.$emit("update:files", this.files);
      },
    },
  },
};
</script>

<style lang="scss">
.file-input__wrapper {
  position: relative;
  width: 236px;
  height: 156px;
  box-sizing: border-box;
  border: 2px dashed $text-light;
  border-radius: 20px;
  padding: 20px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .button {
    max-width: 150px;
  }
}

.file-input__text {
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin-bottom: 5px;
}

.file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.images {
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 30px;
    margin-bottom: 30px;
    &:nth-child(3) {
      margin-right: 0;
    }
  }
  &-item {
    position: relative;
    &:first-child {
      border-radius: 20px;
      border: 2px solid $accent;
    }
    &__wrapper {
      width: 236px;
      height: 156px;
      overflow: hidden;
      border-radius: 17px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__close {
      position: absolute;
      z-index: 10;
      top: -5px;
      right: -5px;
      background: $label-hot;
      width: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      & svg {
        width: 10px;
        height: 20px;
      }
    }
  }
}
</style>
