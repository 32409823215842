import { userApi } from "@/api/user";

export default {
  namespaced: true,
  state: {
    refer: "",
  },
  mutations: {
    SET_REFER(state, data) {
      state.refer = data;
    },
  },
  actions: {
    async setRefer({ commit }, data) {
      try {
        const res = await userApi.setRefer(data);
        console.log("ref res", res);
        commit("SET_REFER", data);
      } catch (e) {
        console.log("ref e", e);
      }
    },
  },
  getters: {
    refer: (state) => state.refer,
  },
};
