<template>
  <section class="promocode">
    <app-button-link v-if="!refer" size="l" @click="showModal">
      У меня есть промокод
    </app-button-link>
    <app-button-link
      v-if="refer"
      size="l"
      color="success"
      readonly
      icon="success"
    >
      Промокод применен
    </app-button-link>
    <!--    <app-button-link v-if="refer" size="l" @click="showModal">-->
    <!--      Ввести другой-->
    <!--    </app-button-link>-->
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ButtonLink from "@/components/UI/Buttons/ButtonLink.vue";

export default {
  name: "Promocode",
  components: {
    "app-button-link": ButtonLink,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("refer", ["refer"]),
  },
  methods: {
    ...mapActions("modal", ["toggleModal"]),
    showModal() {
      this.toggleModal({
        name: "promocode",
        value: true,
      });
    },
  },
};
</script>

<style lang="scss">
.promocode {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 15px 0;
}
</style>
