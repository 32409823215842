<template>
  <section class="cart-tariff form">
    <header>
      <h3>Подписка</h3>
    </header>

    <p>
      Выберите период подключения. Если у вас уже есть подписка, то
      <app-button-link size="m">выполните вход</app-button-link>. Для покупки
      товаров сначала требуется оформить подписку. После оформления вы сможете
      вернуться в корзину и произвести оплату выбранных товаров.
    </p>

    <main>
      <app-cart-tariff-radio
        v-for="el in tariffs"
        :key="el.id"
        :value="el"
        group="group"
        v-model:active="value"
      />
    </main>
  </section>
</template>

<script>
import CartTariffRadio from "@/components/Cart/Tariff/Components/Radio.vue";
import ButtonLink from "@/components/UI/Buttons/ButtonLink.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "CartTariff",
  components: {
    "app-cart-tariff-radio": CartTariffRadio,
    "app-button-link": ButtonLink,
  },
  data: () => ({}),
  computed: {
    ...mapGetters("tariffs", ["tariffs", "tariff"]),
    ...mapGetters("refer", ["refer"]),
    ...mapGetters("user", ["user"]),
    value: {
      get() {
        return this.tariff;
      },
      async set(v) {
        await this.setTariff(v);
      },
    },
  },
  watch: {
    async refer() {
      await this.getTariffs();
    },
  },
  async created() {
    if (!this.user.subscription) {
      await this.getTariffs();
    }
  },
  methods: {
    ...mapActions("tariffs", ["getTariffs", "setTariff"]),
    ...mapActions("modal", ["toggleModal"]),
    login() {
      this.toggleModal({
        name: "auth",
        value: false,
      });
    },
  },
};
</script>

<style lang="scss">
.cart-tariff {
  p {
    margin: 15px 0;
  }

  main {
    display: grid;
    grid-row-gap: 15px;
  }
}
</style>
