import { cartApi } from "@/api/cart";

const getDefaultState = () => {
  return {
    // cart: [
    //   {
    //     _id: "1",
    //     address: {
    //       city: "Санкт-Петербург",
    //       house: "1",
    //       latitude: "59.842689",
    //       longitude: "30.318527",
    //       street: "пл. Победы",
    //     },
    //     categoryId: 1,
    //     customType: "",
    //     description:
    //       "Аэройога отличается от классической йоги тем, что упражнения выполняются в специальных" +
    //       " гамаках. Они представляют собой широкие ленты из эластичной и прочной ткани, закрепленные под потолком. Аэройога включает в работу все группы мышц, так как приходится дополнительно удерживать равновесие, выполняя асаны в гамаке.",
    //     fromDate: "2022-01-22T21:00:00.000Z",
    //     id: 73,
    //     images: [
    //       "https://stor02.srv.bms.group/special-offers/16461448059350.7619517175132313",
    //       "https://stor02.srv.bms.group/special-offers/16461448059350.6698007778981703",
    //       "https://stor02.srv.bms.group/special-offers/16461448059350.25590782086740993",
    //     ],
    //     phones: ["8 (812) 987-53-21"],
    //     price: {
    //       actualPrice: "1",
    //       oldPrice: "2000",
    //       benefit: "1999",
    //       percent: 99,
    //     },
    //     priority: 73,
    //     projectId: "bmsdev",
    //     retailPointId: 127911,
    //     retailPointName: "First",
    //     rules: [
    //       "Перед активацией купона необходимо уточнить расписание занятий и доступные направления в группе «ВКонтакте» или по телефону студии.",
    //     ],
    //     title:
    //       "До 12 занятий аэройогой либо индивидуальное занятие с инструктором в студии Aeroyoga Ananta",
    //     toDate: "2022-05-02T21:00:00.000Z",
    //     type: "DISCOUNT",
    //     webAddress: "https://vk.com/anantaspb",
    //     count: 1,
    //   },
    //   {
    //     _id: "2",
    //     address: {
    //       city: "Санкт-Петербург",
    //       house: "1",
    //       latitude: "59.842689",
    //       longitude: "30.318527",
    //       street: "пл. Победы",
    //     },
    //     categoryId: 1,
    //     customType: "",
    //     description:
    //       "Аэройога отличается от классической йоги тем, что упражнения выполняются в специальных" +
    //       " гамаках. Они представляют собой широкие ленты из эластичной и прочной ткани, закрепленные под потолком. Аэройога включает в работу все группы мышц, так как приходится дополнительно удерживать равновесие, выполняя асаны в гамаке.",
    //     fromDate: "2022-01-22T21:00:00.000Z",
    //     id: 73,
    //     images: [
    //       "https://stor02.srv.bms.group/special-offers/16461448059350.7619517175132313",
    //       "https://stor02.srv.bms.group/special-offers/16461448059350.6698007778981703",
    //       "https://stor02.srv.bms.group/special-offers/16461448059350.25590782086740993",
    //     ],
    //     phones: ["8 (812) 987-53-21"],
    //     price: {
    //       actualPrice: "1000",
    //       oldPrice: "2000",
    //       benefit: "1000",
    //       percent: 50,
    //     },
    //     priority: 73,
    //     projectId: "bmsdev",
    //     retailPointId: 127912,
    //     retailPointName: "Second",
    //     rules: [
    //       "Перед активацией купона необходимо уточнить расписание занятий и доступные направления в группе «ВКонтакте» или по телефону студии.",
    //     ],
    //     title:
    //       "До 12 занятий аэройогой либо индивидуальное занятие с инструктором в студии Aeroyoga Ananta",
    //     toDate: "2022-05-02T21:00:00.000Z",
    //     type: "DISCOUNT",
    //     webAddress: "https://vk.com/anantaspb",
    //     count: 1,
    //   },
    // ],
    cart: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_CART(state, data) {
      state.cart = data;
    },
  },
  actions: {
    async getCart({ commit }) {
      try {
        const res = await cartApi.getCart();
        commit("SET_CART", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async addToCart({ commit, state }, data) {
      let cart = [];
      const idx = state.cart.findIndex((i) => i.item._id === data._id);

      if (idx !== -1) {
        cart.push({
          item: state.cart[idx].item._id,
          count: state.cart[idx].count + 1,
        });
      } else {
        cart = state.cart.map((i) => {
          return {
            item: i.item._id,
            count: i.count,
          };
        });
        cart.push({ item: data._id, count: 1 });
      }
      try {
        const res = await cartApi.editCart(cart);
        commit("SET_CART", res.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async removeOne({ dispatch, state }, _id) {
      const cart = state.cart.filter((el) => {
        if (el.item._id !== _id) {
          return el;
        }
      });
      dispatch("editCart", cart);
    },
    async removeMany({ dispatch, state }, _ids) {
      const cart = state.cart.filter((el) => {
        let needDelete = false;
        _ids.forEach((_id) => {
          if (el.item._id === _id) {
            needDelete = true;
          }
        });
        if (!needDelete) return el;
      });
      dispatch("editCart", cart);
    },
    async changeItemCount({ state, dispatch }, { _id, count }) {
      const cart = state.cart.map((el) => {
        if (el.item._id === _id) {
          el.count = count;
        }
        return el;
      });
      dispatch("editCart", cart);
    },
    async editCart({ commit, dispatch }, cart) {
      try {
        const res = await cartApi.editCart(cart);
        commit("SET_CART", res.data);
        if (res.data.length !== 0) {
          dispatch("payment/createDraft", null, { root: true });
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
  getters: {
    cart: (state) => state.cart,
    count: (state) => state.cart.length,
  },
};
