<template>
  <main class="app-container cabinet">
    <app-breadcrumbs currentPage="Личный кабинет" />
    <h1>Личный кабинет</h1>
    <app-subscribe-banner v-if="!user.subscription" />
    <app-subscribe-block v-else />
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs";
import SubscribeBlock from "@/components/Cabinet/SubscribeBlock";
import SubscribeBanner from "@/components/UI/SubscribeBanner";
import { mapGetters } from "vuex";

export default {
  name: "Cabinet",
  components: {
    "app-subscribe-banner": SubscribeBanner,
    "app-subscribe-block": SubscribeBlock,
    "app-breadcrumbs": Breadcrumbs,
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
};
</script>

<style lang="scss">
.cabinet {
  .subscribe-banner {
    margin-top: 30px;
  }
}
</style>
