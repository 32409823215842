<template>
  <section class="count">
    <button :disabled="inputValue <= 1" @click="inputValue--" />
    <input type="text" v-model="inputValue" maxlength="3" readonly />
    <button :disabled="inputValue >= 999" @click="inputValue++" />
  </section>
</template>

<script>
export default {
  name: "Count",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:value", v);
      },
    },
  },
};
</script>

<style lang="scss">
.count {
  width: fit-content;
  display: grid;
  grid-template-columns: 38px 32px 38px;
  grid-template-rows: 38px;
  padding: 1px;
  border-radius: 20px;
  background: $input;
  overflow: hidden;

  & > input {
    font-size: 1.429rem;
    line-height: 1.714rem;
    font-weight: 500;
    text-align: center;
    border: none;
    outline: none;
    background: transparent;
  }

  & > button {
    position: relative;
    background: transparent;
    outline: none;
    border-radius: 50%;
    transition: background $transition;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 2px;
      border-radius: 2px;
      background: $accent;
      transform: translate(-50%, -50%);
      transition: backgrpund $transition, opacity $transition;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &:hover {
      cursor: pointer;
      background: $input-hover;
    }

    &[disabled] {
      pointer-events: none;

      &::after,
      &::before {
        background: $text;
        opacity: 0.3;
      }
    }
  }
}

@media (min-width: $desktop-width) {
  .count {
    & > input {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
}
</style>
