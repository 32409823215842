import { createStore } from "vuex";
import offers from "./modules/offers";
import payment from "./modules/payment";
import auth from "./modules/auth";
import user from "./modules/user";
import paymentMethods from "./modules/paymentMethods";
import favourites from "./modules/favourites";
import cart from "./modules/cart";
import refer from "./modules/refer";
import tariffs from "./modules/tariffs";
import order from "./modules/order";
import error from "./modules/error";
import modal from "./modules/modal";
import products from "@/store/modules/products";
import partner from "@/store/modules/partner/index";
import vouchers from "@/store/modules/vouchers";

export default createStore({
  state: {
    loadingStatus: "ok", // ok, error, pending
    defaultEmail: "",
    supportEmail: "",
    accountingEmail: "",
    policyURLs: {},
    catalogView: "list",
  },
  mutations: {
    SET_LOADING_STATUS(state, payload) {
      state.loadingStatus = payload;
    },
    SET_APP_EMAILS(state, payload = {}) {
      const {
        VUE_APP_DEFAULT_EMAIL,
        VUE_APP_SUPPORT_EMAIL,
        VUE_APP_ACCOUNTING_EMAIL,
      } = payload;

      state.defaultEmail = VUE_APP_DEFAULT_EMAIL || "";
      state.supportEmail = VUE_APP_SUPPORT_EMAIL || "";
      state.accountingEmail = VUE_APP_ACCOUNTING_EMAIL || "";
    },
    SET_POLICY_URLS(state, payload) {
      state.policyURLs = payload;
    },
    SET_CATALOG_VIEW(state, view) {
      state.catalogView = view;
    },
  },
  actions: {
    resetState({ commit }) {
      commit("auth/RESET_STATE", null, { root: true });
      commit("modal/RESET_STATE", null, { root: true });
      commit("payment/RESET_STATE", null, { root: true });
      commit("error/RESET_STATE", null, { root: true });
      commit("user/RESET_STATE", null, { root: true });
    },
    setAppEmails({ commit }) {
      commit("SET_APP_EMAILS", process.env);
    },
    setPolicyUrls({ commit }) {
      if (process.env.VUE_APP_PROJECT_ID === "chotof") {
        commit("SET_POLICY_URLS", {
          privatePolicy:
            "https://bbonus.ru/docs/Politika-konfidencialnosti-BBONUS.pdf",
          participantOffer:
            "https://bbonus.ru/docs/Oferta-dlya-uchastnika-BBONUS.pdf",
          partnerOffer:
            "https://bbonus.ru/docs/Oferta-dlya-Partnera-BBONUS.pdf",
        });
      }
      if (process.env.VUE_APP_PROJECT_ID === "hotoff") {
        commit("SET_POLICY_URLS", {
          privatePolicy:
            "https://ibonus.info/docs/Politika-konfidencialnosti-IBONUS.pdf",
          participantOffer:
            "https://ibonus.info/docs/Oferta-dlya-uchastnika-IBONUS.pdf",
          partnerOffer:
            "https://ibonus.info/docs/Oferta-dlya-Partnera-IBONUS.pdf",
        });
      }
    },
  },
  getters: {
    getLoadingStatus(state) {
      return state.loadingStatus;
    },
    getPolicyURLs(state) {
      return state.policyURLs;
    },
    getCatalogView(state) {
      return state.catalogView;
    },
  },

  modules: {
    offers,
    payment,
    auth,
    user,
    paymentMethods,
    favourites,
    cart,
    refer,
    tariffs,
    order,
    error,
    modal,
    products,
    partner,
    vouchers,
  },
});
