<template>
  <div class="qr-code">
    <img class="img" :src="qrSrc" alt="payment-qr-code" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "QrCode",
  computed: {
    ...mapGetters("partner", ["getPartnerInfo"]),
    projectUrl() {
      return process.env.NODE_ENV === "development"
        ? { protocol: "http:", host: "localhost:3000" }
        : document.location;
    },
    qrSrc() {
      return `${this.projectUrl.protocol}//${this.projectUrl.host}/qr-with-logo/${this.getPartnerInfo.companyInfo.INN}`;
    },
  },
};
</script>

<style lang="scss">
.qr-code {
  .img {
    width: 100%;
  }
}
</style>
