<template>
  <div class="voucher-table" v-if="Object.keys(items).length">
    <div class="voucher-table__header">
      <div class="voucher-table__header-title">Параметры загрузки</div>
      <div class="voucher-table__header-actions">
        <app-select :options="points" placeholder="Торговая точка" />
        <app-button @click="toVoucherForm">Настроить выпуск</app-button>
      </div>
    </div>
    <div class="voucher-table__content">
      <div class="voucher-table__content-header">
        <div class="voucher-table__content-header-top">
          <div class="voucher-table__content-header-top-title">
            <div class="voucher-table__content-header-top-title-text">
              Привилегии
            </div>
            <div
              v-if="items.nextUpdate"
              class="voucher-table__content-header-top-title-date"
            >
              До {{ toDate }}
            </div>
          </div>
          <app-button-link
            class="voucher-table__content-header-top-delete"
            size="l"
            >Удалить все</app-button-link
          >
        </div>
        <div
          v-if="items.nextUpdate"
          class="voucher-table__content-header-warning"
        >
          <div class="voucher-table__content-header-warning-text">
            Новый период будет доступен {{ toDate }} для категорий, у которых
            включено автопродление.
          </div>
        </div>
      </div>
      <div class="voucher-table__content-table">
        <div class="voucher-table__content-table-row table-header">
          <div
            class="voucher-table__content-table-row-title table-header__title"
          >
            Привилегия
          </div>
          <div class="voucher-table__content-table-row-discount">Скидка, %</div>
          <div class="voucher-table__content-table-row-issued">Выдано, шт.</div>
          <div class="voucher-table__content-table-row-auto">Автопродление</div>
          <div class="voucher-table__content-table-row-actions"></div>
        </div>
        <div v-for="(item, index) in items.vouchers" :key="index">
          <div
            class="voucher-table__content-table-row"
            v-if="item.active"
            :class="addRowClass(index)"
          >
            <div class="voucher-table__content-table-row-title table-title">
              <div class="table-title__content">
                {{ item.title }}
                <app-tooltip>
                  <template v-slot:label>
                    <span class="table-title__content-tooltip-icon">
                      <span
                        v-html="require('@/assets/img/icons/question.svg')"
                      />
                    </span>
                  </template>
                  <template v-slot:content>
                    <div>
                      {{ setTooltipText(item.value) }}
                    </div>
                  </template>
                </app-tooltip>
              </div>
              <div
                v-if="item.globalCount"
                class="voucher-table__content-table-row-title-subtitle"
              >
                {{ item.globalCount }} шт. | {{ item.minSum }}₽
              </div>
            </div>
            <div
              class="voucher-table__content-table-row-discount table-discount"
            >
              {{ item.discount }}
            </div>
            <div class="voucher-table__content-table-row-issued table-issued">
              {{ item.blockCount }}
            </div>
            <div class="voucher-table__content-table-row-auto">
              <app-switch
                v-if="item.value !== 'loyalty'"
                @update:value="changeAuto($event, item.value)"
              />
            </div>
            <div class="voucher-table__content-table-row-actions">
              <span
                class="voucher-table__content-table-row-actions-icon"
                v-html="require(`@/assets/img/icons/trash.svg`)"
                @click="remove(item.value)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import Button from "@/components/UI/Buttons/Button";
import Select from "@/components/UI/Selects/Select";
import Switch from "@/components/UI/Inputs/Switch";
import { mapActions, mapGetters } from "vuex";
import Tooltip from "@/components/UI/Tooltip";

export default {
  name: "VoucherTable",
  components: {
    "app-button": Button,
    "app-button-link": ButtonLink,
    "app-select": Select,
    "app-switch": Switch,
    "app-tooltip": Tooltip,
  },
  props: {
    points: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    keys: [
      {
        key: "loyalty",
        title: "Скидка для постоянных клиентов",
      },
      {
        key: "vouchers",
        title: "Персональные ваучеры на скидку от 35%",
      },
      {
        key: "coupons",
        title: "Купоны на скидку до 35%",
      },
    ],
  }),
  async created() {
    await this.loadPointVouchers(this.pointId);
  },
  computed: {
    ...mapGetters("partner", ["getPointVouchers"]),
    pointId() {
      return Object.keys(this.points)[0];
    },
    items() {
      return this.getPointVouchers;
    },
    toDate() {
      if (this.items) {
        return new Date(this.items.nextUpdate).toLocaleDateString();
      } else return "";
    },
  },
  methods: {
    ...mapActions("partner", ["loadPointVouchers", "editPointVouchers"]),
    addRowClass(id) {
      let rowType =
        Object.keys(this.items.vouchers).indexOf(id) % 2 ? "odd" : "even";
      return [
        `products__main-content__table-row`,
        `products__main-content__table-row--${rowType}`,
      ];
    },
    setTooltipText(key) {
      if (key === "loyalty") {
        return "Размер скидки для постоянных клиентов, которые совершили хотя бы 1 покупку.";
      } else if (key === "coupons") {
        return "Выдаются для тех клиентов, кто совершил покупок на сумму минимального порога.";
      } else {
        return "Выдаются персонально для тех клиентов, кто совершил покупок на сумму минимального порога.";
      }
    },
    toVoucherForm() {
      this.$router.push({
        name: "EditVoucher",
        params: { point_id: this.pointId },
      });
    },
    changeAuto(e, key) {
      console.log(e);
      console.log(key);
    },
    async remove(key) {
      const data = { ...this.items.vouchers, retailPointId: this.pointId };
      data[key].active = false;
      await this.editPointVouchers({
        data: [{ ...data }],
      });
    },
  },
  watch: {
    async pointId() {
      await this.loadPointVouchers(this.pointId);
    },
  },
};
</script>

<style lang="scss">
.voucher-table {
  width: 850px;
  &__header {
    box-shadow: $popup-shadow;
    padding: 20px 30px;
    background: $primary;
    border-radius: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    &-title {
      color: $title;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    &-actions {
      display: flex;
      & .select-wrapper {
        margin-right: 30px;
      }
      & .button {
        width: 300px;
      }
    }
  }
  &__content {
    background: $primary;
    box-shadow: $popup-shadow;
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 40px;
    overflow: hidden;
    &-header {
      padding-bottom: 15px;
      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        &-title {
          display: flex;
          align-items: center;
          &-text {
            font-size: 22px;
            font-weight: 700;
            color: $title;
            position: relative;
            margin-right: 10px;
          }
          &-date {
            font-size: 14px;
            font-weight: 600;
            color: $label-success;
            background: rgba(39, 179, 84, 0.15);
            padding: 3px;
            border-radius: 6px;
          }
        }
        &-delete.button-link {
          color: red;
        }
      }
      &-warning {
        padding: 10px;
        display: flex;
        align-items: center;
        border: 1px solid $bonus;
        border-radius: 12px;
        background: rgba(255, 182, 72, 0.03);
        &-icon {
          margin-right: 15px;
          svg {
            width: 40px;
            height: 40px;
            fill: $bonus;
          }
        }
        &-text {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    &-table {
      background: #f9fafc;
      width: calc(100% + 60px);
      margin-left: -30px;
      &-row {
        display: grid;
        grid-template-columns: 7fr 2fr 2fr 2fr 1fr;
        column-gap: 20px;
        padding: 20px 30px;
        &--even {
          background: #f4f5f7;
        }
        &.table-header {
          font-size: 12px;
          max-height: 20px;
        }
        &-title {
          &.table-title {
            cursor: pointer;
            color: $title;
            & .table-title__content {
              display: flex;
              &-tooltip {
                &-icon {
                  margin-left: 10px;
                }
              }
              & .tooltip__content {
                top: -380%;
                right: -1070%;
                width: 300px;
                padding: 15px;
              }
            }
          }
          &-subtitle {
            color: $text;
            font-size: 16px;
          }
        }
        &-discount {
          &.table-discount {
            color: $title;
          }
        }
        &-issued {
          &.table-issued {
            color: $label-success;
          }
        }
        &-actions {
          display: flex;
          justify-content: end;
          &-icon {
            cursor: pointer;
          }
          &-icon:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
