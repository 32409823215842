import axios from "axios";
import store from "../store";

let baseURL = "";
if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:3000";
}

const projectID = process.env.VUE_APP_PROJECT_ID;

async function updateRefresh() {
  const login = localStorage.getItem(`${projectID}SpecialsUser`);
  const refresh = localStorage.getItem(`${projectID}SpecialsRefresh`);
  const res = await server.post("/auth/refresh", { login, refresh });
  localStorage.setItem(`${projectID}SpecialsToken`, res.data.token);
  localStorage.setItem(`${projectID}SpecialsRefresh`, res.data.refresh);
}

const basicToken = process.env.VUE_APP_REQUEST_TOKEN;

const server = axios.create({
  baseURL,
  timeout: 600000,
  headers: {
    Authorization: `Basic ${basicToken}`,
  },
});

server.interceptors.request.use((request) => {
  const publicMethods = ["auth/registration", "/auth/refresh", "auth/logout"];
  const token = localStorage.getItem(`${projectID}SpecialsToken`);

  if (
    token &&
    !request.url.includes("/special") &&
    !request.url.includes("/juridical") &&
    publicMethods.indexOf(request.url) === -1
  ) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

server.interceptors.response.use(undefined, async (error) => {
  console.log("e", error);
  const originalRequest = error.response.config;

  if (error.response && error.response.status === 401) {
    if (
      error.response.data.message === "jwt expired" ||
      error.response.data.message === "jwt invalid"
    ) {
      try {
        await updateRefresh();
        return await server(originalRequest);
      } catch (e) {
        if (error.response.status === 401) {
          await store.dispatch("auth/logout");
          console.log(e);
        } else {
          throw e;
        }
      }
    } else {
      await store.dispatch("auth/logout");
      throw error.response || error;
    }
  } else {
    throw error.response || error;
  }
});

export { server, updateRefresh };
