<template>
  <div class="select-wrapper">
    <app-select
      v-model="value"
      :placeholder="placeholder"
      :options="options"
      :searchable="false"
      :disabled="disabled"
      :multiple="true"
      label="title"
      track-by="id"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "Select",
  components: {
    "app-select": Multiselect,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    inputValue: {
      type: [String, Number],
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    value: {
      get() {
        return this.inputValue;
      },
      set(newVal) {
        this.$emit("update:value", newVal);
        return newVal;
      },
    },
  },
};
</script>

<style lang="scss">
@import url("~@vueform/multiselect/themes/default.css");
.select-wrapper {
  width: 100%;
}
.multiselect {
  height: 65px;
  margin-left: 0;
  border-radius: 20px;
  border: 2px solid transparent;
  background: $input;
  padding: 20px;
  font-size: 1.25rem;
  color: $title;
  outline: none;
  transition: background $transition, border $transition;
  &.is-active {
    box-shadow: none;
  }
  &:hover {
    background: $input-hover;
    border: 2px solid $input;
  }
  &-dropdown::-webkit-scrollbar {
    width: 0;
  }
  &-dropdown {
    border-radius: 0 0 20px 20px;
    border: 2px solid $accent;
    border-top: none;
    background: $input-hover;
    left: -2px !important;
    right: 20px;
    bottom: -2px;
    width: 100%;
    color: $title;
  }
  &:focus-within {
    border-radius: 20px 20px 0 0;
    border: 2px solid $accent;
    background: $primary;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  & .multiselect-options {
    & .multiselect-option {
      &.is-selected {
        background: $accent;
        &.is-pointed {
          background: $accent;
        }
      }
    }
  }
}
</style>
