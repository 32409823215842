<template>
  <h3 class="title">Торговая точка</h3>
  <!--  <app-file-input :uploadedImages="[image]" @update:files="setImages($event)" />-->
  <app-input
    class="point-form__network-title"
    v-model:value="retailNetworkName"
    label="Название торговой сети"
    :required="true"
    @update:valid="valid = $event"
  />

  <app-point-form
    v-for="point in points"
    :key="point.pointId"
    :point="point"
    @update:info="updatePointsInfo($event)"
    @update:valid-point="checkValidPoints($event)"
    @delete="deleteItem($event)"
    :sourcePointsData="points"
  />
  <app-button class="point-form__button" mode="secondary" @click="addPoint"
    >+ Добавить точку</app-button
  >
</template>

<script>
import Input from "@/components/UI/Inputs/Input";
// import PointsRadioButton from "@/components/Partner/Form/PointsRadioButton";
import Button from "@/components/UI/Buttons/Button";
import PointForm from "@/components/Partner/Form/PointForm";
// import FileInput from "@/components/UI/Inputs/FileInput";
import _get from "lodash/get";
import _findIndex from "lodash/findIndex";
import pointDataInterface from "../../../store/modules/partner/interface/pointDataInterface";
import _cloneDeep from "lodash/cloneDeep";
export default {
  name: "PointInfo",
  components: {
    "app-input": Input,
    // "app-radio-button": PointsRadioButton,
    "app-button": Button,
    // "app-file-input": FileInput,
    "app-point-form": PointForm,
  },
  props: {
    partner: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    retailNetworkName: "",
    points: [],
    // image: null,
    valid: false,
  }),
  created() {
    this.points = _get(this, "partner.points", []);

    if (!this.points.length) {
      this.points.push(_cloneDeep(pointDataInterface));
    }
    this.retailNetworkName = _get(
      this,
      "partner.network.retailNetworkName",
      "Новая торговая сеть"
    );

    if (this.points.length > 1) {
      this.points = this.points.map((item) => {
        item.collapsed = true;
        return item;
      });
    } else {
      this.points[0].collapsed = false;
    }

    this.setPointsId();
    this.checkValidPoints();
  },
  watch: {
    retailNetworkName: {
      handler() {
        this.checkValidPoints();
      },
    },
  },
  methods: {
    setPointsId: function () {
      const result = this.points.map((item, index) => {
        item.pointId = index + 1;
        return item;
      });

      this.points = result;
    },
    //   setImages(e) {
    //     this.pointInfo.image = e[0];
    //     const reader = new FileReader();
    //     reader.readAsDataURL(e[0]);
    //     reader.onload = (res) => {
    //       this.image = res.target.result;
    //     };
    //   },
    findPointIndex: function (point = {}) {
      const id = _get(point, "pointId", -1);
      return _findIndex(this.points, (item) => {
        return item.pointId === id;
      });
    },

    updatePointsInfo(e) {
      const targetIndex = this.findPointIndex(e);

      if (targetIndex > -1) {
        this.points[targetIndex] = { ...this.points[targetIndex], ...e };
      }

      this.$emit("update:info", {
        key: "structure",
        valid: this.valid,
        value: {
          network: {
            retailNetworkName: this.retailNetworkName,
          },
          points: this.points,
        },
      });
    },

    checkValidPoints() {
      if (!this.retailNetworkName.length) {
        this.valid = false;
        this.$emit("update:valid", false);
        return;
      }

      const notValidPoints = this.points.filter((item) => {
        return !item.isValidForm;
      });

      if (notValidPoints.length) {
        this.valid = false;
        this.$emit("update:valid", false);
        return;
      }

      this.valid = true;
      this.$emit("update:valid", true);
      this.updatePointsInfo();
    },
    addPoint() {
      this.setPointsId();
      this.points = this.points.map((item) => {
        item.collapsed = true;
        return item;
      });

      const newItem = _cloneDeep(pointDataInterface);
      newItem.pointId = this.points.length + 1;

      this.points.push(newItem);
      this.checkValidPoints();
    },
    deleteItem(point) {
      if (this.points.length <= 1) {
        return;
      }
      const targetIndex = this.findPointIndex(point);
      if (targetIndex >= 0) {
        this.points.splice(targetIndex, 1);
      }
      this.checkValidPoints();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 35px;
}
.subtitle {
  color: $text;
  font-size: $font-size-root;
  font-weight: 500;
  margin-bottom: 8px;
}
.point-form {
  &__network-title {
    margin-bottom: 35px;
  }
}
</style>
