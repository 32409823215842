import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home/Home";
import Offer from "@/views/Offer";
import Payment from "@/views/Payment";
import Cart from "@/views/Cart";
import Favourites from "@/views/Favourites";
import TestMap from "@/views/TestMap";
import Complete from "@/views/Complete";
import Cabinet from "@/views/Cabinet";
import Products from "@/views/Home/Products";
import Offers from "@/views/Home/Offers";
import ProductsInPoint from "@/views/Home/ProductsInPoint";
import Partner from "@/views/Partner";
import PartnerComplete from "@/views/PartnerComplete";
import PartnerProducts from "@/views/PartnerProducts/PartnerProducts.vue";
import PartnerStartPage from "@/views/PartnerStartPage";
import New from "@/views/PartnerProducts/New";
import Edit from "@/views/PartnerProducts/Edit";
import VoucherEdit from "@/views/PartnerProducts/VoucherEdit";
import Vouchers from "@/views/Home/Vouchers";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "Offers",
        component: Offers,
      },
      {
        path: "/products",
        name: "Products",
        component: Products,
      },
      {
        path: "/products/:retailPointId",
        name: "ProductsInPoint",
        component: ProductsInPoint,
      },
      {
        path: "/vouchers",
        name: "Vouchers",
        component: Vouchers,
      },
    ],
  },
  {
    path: "/offer/:id",
    name: "Offer",
    component: Offer,
    props: true,
  },
  {
    path: "/partner-info/:id",
    name: "PartnerInfo",
    component: Offer,
    props: true,
  },
  {
    path: "/payment/:id",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/map",
    component: TestMap,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/favourites",
    name: "Favourites",
    component: Favourites,
  },
  {
    path: "/complete",
    name: "Complete",
    component: Complete,
  },
  {
    path: "/partner/create/complete",
    name: "PartnerComplete",
    component: PartnerComplete,
    meta: {
      header: false,
    },
  },
  {
    path: "/partner/products/main",
    name: "PartnerProducts",
    component: PartnerProducts,
    meta: {
      header: false,
    },
  },
  {
    path: "/partner/products/add",
    name: "NewProduct",
    component: New,
    meta: {
      header: false,
    },
  },
  {
    path: "/partner/products/edit/:id",
    name: "EditProduct",
    component: Edit,
    meta: {
      header: false,
    },
  },
  {
    path: "/partner/products/vouchers",
    name: "EditVoucher",
    component: VoucherEdit,
    meta: {
      header: false,
    },
  },
  {
    path: "/cabinet",
    name: "Cabinet",
    component: Cabinet,
    meta: {
      header: false,
    },
  },
  {
    path: "/partner",
    name: "PartnerStartPage",
    component: PartnerStartPage,
    meta: {
      header: false,
    },
  },
  {
    path: "/partner/create",
    name: "Partner",
    component: Partner,
    meta: {
      header: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { left: 0, top: 0, behavior: "smooth" };
    }
  },
});

export default router;
