<template>
  <div class="start-page-partners">
    <div class="start-page-partners__title">Наши партнеры</div>
    <div class="start-page-partners__items">
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/alfa.png"
          alt="alfabank"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/RNCB.png"
          alt="rnkb"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/CHE.png"
          alt="che"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/chelentano.png"
          alt="chelentano"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/fishChips.png"
          alt="fish & chips"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/mollies.png"
          alt="mollies"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/RA.png"
          alt="RA hotels"
        />
      </div>
      <div class="start-page-partners__items-item">
        <img
          class="start-page-partners__items-item-img"
          src="@/assets/img/barslona.png"
          alt="barslona"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartPagePartners",
};
</script>
