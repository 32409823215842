<template>
  <button
    @click="addToFavourite"
    v-if="isLiked !== undefined"
    class="button-like"
    :class="[
      isLiked ? 'button-like__active' : '',
      size ? `button-like--${size}` : '',
    ]"
  >
    <span v-html="require(`@/assets/img/icons/heart.svg`)"></span>
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LikeButton",
  props: {
    size: {
      type: String,
      required: false,
      default: "",
    },
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("favourites", {
      setFavourites: "setFavourites",
    }),
    addToFavourite() {
      this.setFavourites(this.offer);
    },
  },
  computed: {
    ...mapGetters("favourites", ["favouritesIds"]),
    isLiked() {
      return this.favouritesIds.includes(this.offer.id);
    },
  },
};
</script>

<style lang="scss">
.button-like {
  min-width: 64px;
  width: 64px;
  height: 64px;
  border-radius: $border-radius;
  background-color: $like-button;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition;
  span {
    width: 20px;
    height: 20px;
    display: block;
    svg {
      transition: $transition;
      fill: transparent;
      stroke: $primary;
    }
  }
  &--s {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }
  &__active {
    background-color: $like-button-hover;
    span {
      svg {
        fill: $primary !important;
      }
    }
  }
  &:hover {
    background-color: $like-button-hover;
  }
}
</style>
