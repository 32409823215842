import { categoriesConfig } from "@/configs/categories";
import { offersApi } from "@/api/offers";

const projectId = () => {
  return process.env.VUE_APP_PROJECT_ID;
};

export default {
  namespaced: true,
  state: {
    offers: [],
    singleOffer: {},
    offerToPay: JSON.parse(localStorage.getItem("selectedToPay")) || null,
    offersQuantity: 0,
    categories: [],
    activeCategory: 0,
    activePage: 0,
    isLastPage: false,
    isLoading: false,
    projectId: projectId(),
  },
  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categories = payload;
    },
    SET_OFFERS(state, payload) {
      if (payload.offers) {
        if (payload.num === 0) {
          state.offers = payload.offers;
        } else {
          state.offers = [...state.offers, ...payload.offers];
        }
      }
    },
    SET_CURRENT_PAGE(state, page) {
      state.activePage = page;
    },
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status;
    },
    SET_END_OFFERS(state, endStatus) {
      state.isLastPage = endStatus;
    },
    SET_SINGLE_OFFER(state, offer) {
      state.singleOffer = offer;
    },
    SET_OFFER_TO_PAY(state, offer) {
      state.offerToPay = offer;
    },
    SET_ACTIVE_CATEGORY(state, id) {
      state.activeCategory = id;
    },
    SET_OFFERS_QUANTITY(state, num) {
      state.offersQuantity = num;
    },
  },
  actions: {
    async loadOffers({ commit, state, rootGetters }, retailPointId) {
      if (state.isLastPage) return false;
      commit("SET_LOADING_STATUS", true);
      let page = {
        num: state.activePage,
        limit: 9,
      };
      if (rootGetters["getCatalogView"] === "map") {
        page = {
          num: 0,
          limit: 1000000,
        };
        commit("SET_END_OFFERS", true);
      }
      const data = {
        projectId: state.projectId,
        page,
        categoryId: state.activeCategory,
        productType: "offer",
      };

      try {
        let offers;
        if (retailPointId) {
          offers = await offersApi.searchByRetailPointId(retailPointId, data);
          if (offers && offers.data.length) {
            commit("SET_OFFERS", { offers: offers.data, num: page.num });
          } else {
            commit("SET_END_OFFERS", true);
          }
        } else {
          offers = await offersApi.all(data);
          if (offers && offers.data.data.offers.length) {
            commit("SET_OFFERS", {
              offers: offers.data.data.offers,
              num: page.num,
            });
          } else {
            commit("SET_END_OFFERS", true);
          }
        }
        commit("SET_CURRENT_PAGE", state.activePage + 1);
      } catch (e) {
        console.log(e);
      } finally {
        commit("SET_LOADING_STATUS", false);
      }
    },
    async loadSingleOffer({ commit }, id) {
      try {
        const offer = await offersApi.singleOffer(id);
        commit("SET_SINGLE_OFFER", offer.data);
      } catch (e) {
        console.log(e);
      }
    },
    setOfferToPay({ commit }, data) {
      localStorage.setItem("selectedToPay", JSON.stringify(data));
      commit("SET_OFFER_TO_PAY", data);
    },
    resetSingleOffer({ commit }) {
      commit("SET_SINGLE_OFFER", {});
    },
    async loadCategories({ commit, state }) {
      let qtyCounter = 0;
      const cats = await offersApi.categories(state.projectId);

      const newCategories = categoriesConfig.map((item) => {
        const newItem = { ...item };
        cats.data.forEach((cat) => {
          if (item.id === cat.id) {
            newItem.title = cat.title;
            newItem.quantity = cat.quantity;
          }
          qtyCounter += cat.quantity;
        });
        if (item.id === 0) {
          newItem.isActive = true;
          newItem.quantity = qtyCounter;
          commit("SET_OFFERS_QUANTITY", qtyCounter);
        }
        return newItem;
      });

      commit("SET_CATEGORIES", newCategories);
    },
    async changeActiveCategory({ commit, state, dispatch }, id) {
      const updatedCategories = state.categories.map(({ ...cat }) => {
        cat.isActive = cat.id === id;
        return cat;
      });
      commit("SET_CATEGORIES", updatedCategories);
      commit("SET_CURRENT_PAGE", 0);
      commit("SET_OFFERS", { offers: [], num: 0 });
      commit("SET_END_OFFERS", false);
      commit("SET_ACTIVE_CATEGORY", id);
      await dispatch("loadOffers");
    },
  },
  getters: {
    // Offers
    getOffers: (state) => state.offers,
    getLoadingStatus: (state) => state.isLoading,
    getEndOffers: (state) => state.isLastPage,
    getSingleOffer: (state) => state.singleOffer,
    getOfferToPay: (state) => state.offerToPay,
    getOffersQuantity: (state) => state.offersQuantity,
    // Categories
    getCategories: (state) => state.categories,
    getActiveCategory: (state) => state.activeCategory,
    getCategoryById: (state) => (id) => {
      return state.categories.find((cat) => cat.id === +id);
    },
    getOffersByPointId: (state) => (id) => {
      return state.offers.filter((item) => item.retailPointId === +id);
    },
  },
};
