<template>
  <img v-if="!images.length" src="@/assets/img/empty-photo.png" alt="" />
  <img v-else-if="images.length === 1" :src="images[0]" alt="" />
  <swiper
    v-else
    class="offer-card__swiper"
    slidesPerView="1"
    @swiper="onSwiper"
    :pagination="{ clickable: true }"
    :autoplay="true"
  >
    <swiper-slide v-for="img in images" :key="img">
      <img :src="img" alt="" />
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
export default {
  name: "OfferCardPhoto",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
  },
  created() {
    SwiperCore.use([Pagination]);
  },
};
</script>

<style lang="scss" scoped>
.offer-card__swiper {
  height: inherit;
  width: 100%;
}
@media (max-width: $desktop-width) {
  .offer-card__swiper {
    &::v-deep {
      .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
      }
      .swiper-pagination-bullet-active {
        width: 15px;
      }
    }
  }
}
</style>
