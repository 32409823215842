const categoriesConfig = [
  {
    id: 0,
    title: "Все",
    icon: "tab-all",
    color: "rgba(221, 253, 238, 1)",
    borderColor: "rgba(39, 179, 84, 1)",
    active: true,
  },
  {
    id: 1,
    icon: "tab-health",
    color: "rgba(252, 245, 226, 1)",
    borderColor: "rgba(248, 195, 145, 1)",
  },
  {
    id: 2,
    icon: "tab-shop",
    color: "rgba(249, 235, 255, 1)",
    borderColor: "rgba(178, 99, 216, 1)",
  },
  {
    id: 3,
    icon: "tab-travel",
    color: "rgba(243, 238, 253, 1)",
    borderColor: "rgba(165, 134, 210, 1)",
  },
  {
    id: 4,
    icon: "tab-food",
    color: "rgba(219, 235, 252, 1)",
    borderColor: "rgba(58, 171, 252, 1)",
  },
  {
    id: 6,
    icon: "tab-car",
    color: "rgba(253, 233, 231, 1)",
    borderColor: "rgba(254, 0, 0, 1)",
  },
  {
    id: 7,
    icon: "tab-another",
    color: "rgba(221, 253, 238, 1)",
    borderColor: "rgba(126, 203, 48, 1)",
  },
];

export { categoriesConfig };
