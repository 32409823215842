<template>
  <div class="start-page">
    <h1 class="start-page__title">Регистрация на площадке {{ projectName }}</h1>
    <div class="start-page__steps">
      <div
        class="start-page__steps-step"
        v-for="(step, index) in steps"
        :key="index"
      >
        <div class="start-page__steps-step-num">{{ step.id }}</div>
        <div class="start-page__steps-step-text">
          <a v-if="index === 2" :href="ofertaLink" target="_blank"
            ><app-link size="m"
              >Подпишите заявление-согласие с офертой</app-link
            ></a
          >
          {{ step.text }}
          <a v-if="index === 0" :href="instruction" target="_blank"
            ><app-link size="m">Инструкция по регистрации</app-link></a
          >
        </div>
      </div>
    </div>
    <app-start-page-block-top />
    <app-start-page-block-bottom />
    <app-start-page-partners />
  </div>
</template>

<script>
import StartPageBlockTop from "@/components/Partner/StartPageBlockTop";
import StartPageBlockBottom from "@/components/Partner/StartPageBlockBottom";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
import StartPagePartners from "@/components/Partner/StartPagePartners";

export default {
  name: "PartnerStartPage",
  components: {
    "app-start-page-block-top": StartPageBlockTop,
    "app-start-page-block-bottom": StartPageBlockBottom,
    "app-start-page-partners": StartPagePartners,
    "app-link": ButtonLink,
  },
  created() {
    localStorage.setItem(
      `${process.env.VUE_APP_PROJECT_ID}SpecialsInviter`,
      this.inviter
    );
  },
  data: () => ({
    steps: [
      {
        id: 1,
        text: `Зарегистрируйте юридическое лицо`,
      },
      {
        id: 2,
        text: "Добавьте торговую точку и телефон кассира",
      },
      {
        id: 3,
        text: "и пришлите скан на почту",
      },
    ],
  }),
  computed: {
    instruction() {
      return process.env.VUE_APP_PROJECT_ID === "chotof"
        ? "https://bbonus.ru/docs/Instrukciya_po_registracii_Partnera_na_ploshchadke_bb.pdf"
        : "https://ibonus.info/docs/Instrukciya_po_registracii_Partnera_na_ploshchadke_ib.pdf";
    },
    ofertaLink() {
      return process.env.VUE_APP_PROJECT_ID === "hotoff"
        ? "https://ibonus.info/docs/Oferta-dlya-Partnera-IBONUS.pdf"
        : "https://bbonus.ru/docs/Oferta-dlya-Partnera-BBONUS.pdf";
    },
    projectName() {
      return process.env.VUE_APP_PROJECT_ID === "chotof" ? "BBonus" : "IBonus";
    },
    inviter() {
      return this.$route.query.inviter || "";
    },
  },
};
</script>

<style lang="scss">
.start-page {
  padding: 100px 0 60px;
  max-width: 850px;
  margin: 0 auto;
  min-height: calc(100vh - 441px);
  display: flex;
  flex-direction: column;
  align-items: center;
  &__steps {
    width: 100%;
    margin: 0 auto;
    padding: 80px 0 60px;
    display: flex;
    justify-content: space-between;
    &-step {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 220px;
      &-num {
        font-weight: 700;
        font-size: 26px;
        color: $accent;
        border: 1px dashed $accent-disabled;
        border-radius: 50%;
        padding: 28px 34px;
        margin-bottom: 10px;
      }
      &-text {
        font-size: 16px;
        line-height: 24px;
        max-width: 240px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__button {
    max-width: 265px;
  }
  &__block {
    width: 100%;
    padding: 20px 30px 50px;
    background: $primary;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    box-shadow: $popup-shadow;
    margin-bottom: 20px;
    &-title {
      font-size: 35px;
      font-weight: 700;
      color: $title;
      margin-bottom: 70px;
    }
    &-separator {
      height: 1px;
      background: $input;
      margin: 40px 0;
    }
    &-warning {
      display: flex;
      align-items: center;
      color: $bonus;
      padding: 10px;
      border: 1px solid rgba(255, 182, 72, 0.7);
      border-radius: 12px;
      margin-bottom: 30px;
      &-icon {
        fill: $bonus;
        margin-right: 15px;
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }
    &-bg {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.6;
      z-index: 0;
    }
    &-logo {
      margin-right: 0;
      margin-left: auto;
      max-width: 75px;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 60px;
      &-title {
        font-size: 54px;
        line-height: 60px;
        font-weight: 700;
        max-width: 350px;
        color: $title;
        span {
          color: $accent;
        }
      }
      &-subtitle {
        font-size: 80px;
        color: $accent;
        font-weight: 700;
        line-height: 96px;
        &--text {
          font-size: 20px;
          line-height: 30px;
          max-width: 320px;
        }
      }
      &-img {
        z-index: 1;
      }
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 40px;
      &-item {
        max-width: 243px;
        text-align: center;
        position: relative;
        &-step {
          position: absolute;
          left: 60px;
          top: -30px;
          color: $primary;
          font-size: 20px;
          font-weight: 700;
          padding: 1px 8px;
          background: $label-hot;
          border-radius: 50%;
          &.bottom-step {
            left: 120px;
            top: -20px;
          }
        }
      }
      &.content-connection {
        justify-content: space-around;
      }
    }
    &-button {
      display: flex;
      justify-content: space-around;
    }
  }
  &-partners {
    width: 100%;
    padding: 50px 30px;
    background: $primary;
    border-radius: 30px;
    box-shadow: $popup-shadow;
    &__title {
      font-size: 35px;
      font-weight: 700;
      color: $title;
      margin-bottom: 50px;
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: 60px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media (max-width: $tablet-width) {
  .start-page {
    width: 85%;
    &__title {
      max-width: 250px;
    }
    &__steps {
      flex-direction: column;
      align-items: center;
    }
    &__block {
      &-header {
        flex-direction: column-reverse;
        margin-bottom: 30px;
        &-img {
          svg {
            max-width: 250px;
          }
        }
        &-title {
          font-size: 40px;
          line-height: 50px;
          text-align: center;
        }
        &-subtitle {
          font-size: 40px;
          line-height: 50px;
          text-align: center;
          &--text {
            text-align: center;
          }
        }
      }
      &-title {
        text-align: center;
        line-height: 40px;
      }
      &-content {
        flex-direction: column;
        align-items: center;
        &-item {
          margin-bottom: 40px;
          &-text {
            font-size: 18px;
          }
          &.content-item {
            max-width: 280px !important;
            margin-bottom: 40px !important;
          }
          &-step {
            padding: 2px 8px;
            &.bottom-step {
              left: 60px;
            }
          }
        }
      }
      &-button {
        display: block;
        .button {
          max-width: 95%;
          margin: 0 auto;
          &-text {
            font-size: 23px;
          }
        }
      }
    }
    &-partners {
      &__title {
        text-align: center;
      }
      &__items {
        grid-template-columns: 1fr 1fr;
        row-gap: 60px;
      }
    }
  }
}
</style>
