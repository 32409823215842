<template>
  <main class="app-container home-page">
    <div class="home-page__header">
      <template v-if="$route.name !== 'ProductsInPoint'">
        <div class="home-page__header-titles">
          <router-link to="/">
            <h1
              class="home-page__title"
              :class="{
                'home-page__title--active': $route.name === 'Offers',
              }"
            >
              <span>Товары</span><sup>{{ offers.length }}</sup>
            </h1>
          </router-link>

          <router-link to="/vouchers" v-if="isAuthorized">
            <h1
              class="home-page__title"
              :class="{
                'home-page__title--active': $route.name === 'Vouchers',
              }"
            >
              <span>Купоны</span><sup>{{ getVouchersAndCouponsLength }}</sup>
            </h1>
          </router-link>
        </div>
        <ul class="home-page__view" v-if="$route.name !== 'Vouchers'">
          <li
            :class="{ active: getCatalogView === 'list' }"
            @click="changeView('list')"
          >
            Списком
          </li>
          <li
            :class="{ active: getCatalogView === 'map' }"
            @click="changeView('map')"
          >
            На карте
          </li>
        </ul>
      </template>
    </div>
    <router-view></router-view>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Home",
  data: () => ({
    view: "list", //map
  }),
  computed: {
    ...mapGetters("auth", {
      isAuthorized: "isAuthorized",
    }),
    ...mapGetters("offers", {
      offers: "getOffers",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
    ...mapGetters("products", {
      products: "getProducts",
    }),
    ...mapGetters(["getCatalogView"]),
    ...mapGetters("vouchers", ["getVouchersAndCouponsLength"]),
    mobView() {
      return window.screen.width <= 768;
    },
    randomProductCount() {
      return this.mobView ? 1 : 3;
    },
  },
  async created() {
    await this.loadOffers();
    await this.loadProducts();
    await this.loadRandomProducts(this.randomProductCount);
    await this.loadVouchers();
    await this.loadCoupons();
  },
  methods: {
    ...mapActions("offers", ["loadOffers"]),
    ...mapActions("products", ["loadProducts", "loadRandomProducts"]),
    ...mapMutations(["SET_CATALOG_VIEW"]),
    ...mapActions("vouchers", ["loadVouchers", "loadCoupons"]),
    async changeView(view) {
      this.SET_CATALOG_VIEW(view);
      if (view === "map") {
        if (this.$route.name === "Offers") {
          await this.loadOffers();
        }
        if (this.$route.name === "Products") {
          await this.loadProducts();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.home-page {
  padding-top: 30px;
  padding-bottom: 40px;
  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  &__title {
    display: inline-block;
    margin-right: 40px;
    cursor: pointer;
    margin-bottom: 10px;
    color: $accent;
    border-bottom: 1px dashed $accent;
    &:hover {
      span {
        color: $accent-hover;
      }
    }
    &--active {
      border-bottom: none;
      pointer-events: none;
      span {
        color: $title;
      }
      &:hover {
        span {
          color: $title;
        }
      }
    }
  }
  &__view {
    margin: 0;
    padding: 0;
    justify-self: flex-end;
    li {
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      font-weight: 700;
      font-size: 20px;
      margin-left: 20px;
      color: $accent;
      &.active {
        color: $text;
      }
    }
  }
}
@media (min-width: $desktop-width) {
  .home-page {
    padding-top: 60px;
    padding-bottom: 60px;
    &__title {
      margin-bottom: 0;
    }
  }
}
@media (max-width: $tablet-width) {
  .home-page {
    &__header {
      flex-direction: column;
      &-titles {
        display: flex;
      }
    }
    &__view {
      li {
        font-size: 16px;
      }
      li:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
