<template>
  <!--  <app-loader v-if="getLoading" :value="getLoading" />-->
  <div class="partner-complete">
    <div class="partner-complete__content">
      <div
        class="partner-complete__content-img"
        v-html="require(`@/assets/img/icons/success.svg`)"
      />
      <h1>Данные отправлены</h1>
      <div class="partner-complete__content-text">
        Выберите формат сотрудничества
      </div>
      <div class="partner-complete__content-info">
        <div class="partner-complete__content-info-tabs">
          <div
            :class="[
              'partner-complete__content-info-tabs-tab',
              activeTab !== tab.id ? 'inactive' : '',
            ]"
            v-for="(tab, index) in tabs"
            :key="index"
            @click="setTab(tab.id)"
          >
            <h1>{{ tab.text }}</h1>
            <div
              v-if="tab.id === 1"
              v-html="require(`@/assets/img/icons/SBP.svg`)"
            />
          </div>
        </div>
        <app-sbp-info
          v-if="activeTab === 1"
          @show-qr="showQR"
          :points="getPartnerInfo.structure.points"
          :qrPdfUrl="qrPdfUrl"
        />
        <app-products-info
          v-if="activeTab === 2"
          :ofertaLink="ofertaLink"
          @to-products="goToProducts"
        />
        <div class="sbp-block">
          <div class="sbp-block__list-title">
            2. Управляйте операциями в telegram боте
          </div>
          <ul class="sbp-block__list">
            <li class="sbp-block__list-item">
              <a :href="getPartnerInfo.botLink" target="_blank"
                ><app-link size="m">Установите бота</app-link></a
              >
            </li>
            <li class="sbp-block__list-item">
              Авторизуйтесь по номеру телефона кассира, который ввели в форме
              регистрации. Вы также сможете приглашать других кассиров по
              реферальной ссылке.
            </li>
            <li class="sbp-block__list-item">
              Отслеживайте платежи по своей торговой точке: подтверждайте и
              отменяйте их, производите поиск, формируйте отчетность за период,
              делитесь реферальной ссылкой с персоналом и клиентами и выводите
              денежные средства, накопленные за период участия в реферальной
              программе.
              <a :href="botInstructionLink" target="_blank"
                ><app-link size="m">Подробнее в инструкции</app-link></a
              >
            </li>
          </ul>
        </div>
        <div class="sbp-block">
          <div class="sbp-block__list-title">
            3. Подпишите приложение к заявлению-согласию
          </div>
          <ul class="sbp-block__list">
            <li class="sbp-block__list-item">
              <a
                :href="`${location.protocol}//${location.host}/attachment-to-agreement/${getPartnerInfo.companyInfo.INN}`"
                target="_blank"
                ><app-link size="m">Откройте форму</app-link></a
              >. Данные юр. лица заполнены автоматически.
            </li>
            <li class="sbp-block__list-item">Распечатайте и подпишите.</li>
            <li class="sbp-block__list-item">
              Отправьте подписанное приложение на почту
              <a :href="`mailto:${accountingEmail}`"
                ><app-link size="m">{{ accountingEmail }}</app-link></a
              >. Мы также отправили письмо на
              <app-link size="m">{{ getPartnerInfo.contacts.email }}</app-link
              >.
            </li>
            <li class="sbp-block__list-item">
              После успешной отправки заявления мы рады приветствовать вас в
              кругу наших партнеров.
            </li>
          </ul>
        </div>
      </div>
      <app-button class="partner-complete__content-btn" @click="goToProducts"
        >Перейти к загрузке товаров</app-button
      >
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
import { mapActions, mapGetters, mapState } from "vuex";
import CompletePageSBP from "@/components/Partner/CompletePageSBP";
import CompletePageProducts from "@/components/Partner/CompletePageProducts";
import ButtonLink from "@/components/UI/Buttons/ButtonLink";
// import Preloader from "@/components/UI/Preloader";

export default {
  name: "PartnerComplete",
  components: {
    "app-button": Button,
    "app-link": ButtonLink,
    "app-sbp-info": CompletePageSBP,
    "app-products-info": CompletePageProducts,
    // "app-loader": Preloader,
  },
  data: () => ({
    defaultLength: 39,
    activeTab: 1,
    tabs: [
      {
        id: 1,
        text: "Оплата СБП",
      },
      {
        id: 2,
        text: "Размещение товаров",
      },
    ],
  }),
  async beforeMount() {
    await this.setPartnerByInn();
  },
  computed: {
    ...mapGetters("partner", ["getPartnerInfo", "getLoading"]),
    ...mapState({ accountingEmail: "accountingEmail" }),
    shortBotLink() {
      if (this.getPartnerInfo.botLink.length > this.defaultLength) {
        return this.getPartnerInfo.botLink.slice(0, this.defaultLength) + "...";
      }
      return this.getPartnerInfo.botLink;
    },
    location() {
      return process.env.NODE_ENV === "development"
        ? { protocol: "http:", host: "localhost:3000" }
        : document.location;
    },
    SBPinstructionLink() {
      return process.env.VUE_APP_PROJECT_ID === "hotoff"
        ? "https://ibonus.info/docs/instrukcia_SBP_ib.pdf"
        : "https://bbonus.ru/docs/instrukcia_SBP_bb.pdf";
    },
    botInstructionLink() {
      return process.env.VUE_APP_PROJECT_ID === "hotoff"
        ? "https://ibonus.info/docs/bot_instruction.pdf"
        : "https://bbonus.ru/docs/bot_instruction.pdf";
    },
    ofertaLink() {
      return process.env.VUE_APP_PROJECT_ID === "hotoff"
        ? "https://ibonus.info/docs/Oferta-dlya-Partnera-IBONUS.pdf"
        : "https://bbonus.ru/docs/Oferta-dlya-Partnera-BBONUS.pdf";
    },
  },
  methods: {
    ...mapActions("partner", ["setPartnerByInn"]),
    ...mapActions("modal", ["toggleModal"]),
    showQR() {
      this.toggleModal({
        name: "qr",
        value: true,
      });
    },
    goToProducts() {
      const key = new URL(this.getPartnerInfo.partnerLink).searchParams.get(
        "key"
      );
      this.$router.push({
        name: "PartnerProducts",
        query: {
          key,
        },
      });
    },
    setTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss">
body {
  overflow: visible !important;
}
.partner-complete {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__content {
    margin: 60px auto;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      svg {
        width: 80px;
        height: 80px;
        fill: $label-success !important;
      }
    }
    &-text {
      text-align: center;
      margin: 10px 0 20px;
      & span {
        color: $accent;
      }
    }
    &-btn {
      max-width: 325px;
    }
    &-info {
      max-width: 850px;
      padding: 40px 30px;
      background: $primary;
      border-radius: 30px;
      box-shadow: $popup-shadow;
      margin-bottom: 20px;
      &-tabs {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        &-tab {
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          & > h1 {
            margin-right: 15px;
          }
          &:first-child {
            margin-right: 40px;
          }
          &.inactive {
            cursor: pointer;
            border-bottom: 1px dashed rgba(45, 80, 251, 0.3);
            & > h1 {
              color: $accent;
            }
          }
        }
      }
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        div {
          width: 310px;
        }
        & div:first-child {
          margin-right: 30px;
          text-align: right;
        }
        & div:last-child {
          color: $title;
        }
      }
    }
  }
}
.sbp {
  &-block {
    &__list {
      &-title {
        font-size: 22px;
        font-weight: 700;
        color: $title;
        margin-bottom: 10px;
      }
      &-payment-link {
        margin-bottom: 10px;
        &.select-wrapper {
          width: 50%;
        }
      }
      &-item {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
  }
}

ul {
  padding-left: 20px;
}

@media (max-width: $tablet-width) {
  .partner-complete {
    &__content {
      &-info {
        padding: 25px 15px;
        &-tabs {
          flex-direction: column;
          align-items: stretch;
          margin-bottom: 25px;
        }
      }
    }
  }
  .sbp {
    &-block {
      &__list {
        &-title {
          font-size: 20px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
