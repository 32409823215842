<template>
  <div
    :class="[
      `spinner-wrapper spinner-wrapper--${mode} spinner-wrapper--${size}`,
      { fixed: fixed },
    ]"
  >
    <svg
      :class="`spinner spinner--${mode} spinner--${size}`"
      viewBox="0 0 50 50"
    >
      <circle
        class="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    mode: {
      type: String,
      required: false,
      default: "dark",
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return ["dark", "light"].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      requied: false,
      default: "s",
      validator(value) {
        // Значение должно соответствовать одной из этих строк
        return ["s", "m", "l"].indexOf(value) !== -1;
      },
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.spinner-wrapper {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--dark {
    background: rgba(126, 125, 142, 0.12);
    backdrop-filter: blur(10px);
    border-radius: $border-radius;
  }

  &--s {
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
  }

  &--m {
    width: 64px;
    height: 64px;
    margin-left: -32px;
    margin-top: -32px;
  }

  &--l {
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
  }

  &.fixed {
    position: fixed;
  }
}

.spinner {
  animation: rotate 2s linear infinite;

  &--dark {
    & .path {
      stroke: $accent;
    }
  }

  &--light {
    & .path {
      stroke: $primary;
    }
  }

  &--s,
  &--m {
    width: 40px;
    height: 40px;
  }

  &--l {
    width: 60px;
    height: 60px;
  }

  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
