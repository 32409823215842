<template>
  <div class="app-container map">
    <yandex-map
      class="yandex-map"
      :coords="coords"
      :zoom="zoom"
      @map-was-initialized="onInitMap"
    >
      <span></span>
    </yandex-map>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { yandexMap } from "vue-yandex-maps";
let myMap = null;
let myClusterer = null;
export default {
  name: "Map",
  components: { yandexMap },
  props: {
    offers: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    currentCategoryId: {
      type: Number,
      required: false,
      default: 0,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      clusterer: null,
      coords: [59.91, 30.3],
      zoom: 10,
      offerCardLayout: null,
      hintLayout: null,
      mapCenter: [],
    };
  },
  watch: {
    loading(val) {
      if (!val && myClusterer) {
        myClusterer.removeAll();
        myClusterer.add(this.createPlaceMarks());
        if (myClusterer.getBounds()) {
          myMap.setBounds(myClusterer.getBounds(), {
            checkZoomRange: true,
          });
        }
      }
    },
  },
  onUnmounted() {
    myClusterer = null;
  },
  methods: {
    getCategoryBorderColor(item) {
      let category = this.categories.find((cat) => cat.id === item.categoryId);
      return category ? category.borderColor : "";
    },
    getCategoryBackgroundColor(item) {
      let category = this.categories.find((cat) => cat.id === item.categoryId);
      return category ? category.color : "";
    },
    createPlaceMarks() {
      const placemarks = [];
      this.offers.forEach((item) => {
        if (
          item.categoryId === this.currentCategoryId ||
          this.currentCategoryId === 0
        ) {
          try {
            const placemark = new ymaps.Placemark(
              [item.address.latitude, item.address.longitude],
              {
                title: item.title,
                price: item.price,
                categoryColor: this.getCategoryBackgroundColor(item),
                address: item.address,
                img: item.images[0],
                retailPointName: item.retailPointName,
                id: item.id,
                balloonContentHeader: item.images[0],
                balloonContentBody: `${item.retailPointName} <br/> ${item.address.street} item.address.house`,
                balloonContentFooter: item.id,
              },
              {
                iconColor: this.getCategoryBorderColor(item),
                hasHint: true,
                hintLayout: this.hintLayout,
                hasBalloon: true,
                balloonContentLayout: this.offerCardLayout,
              }
            );
            placemarks.push(placemark);
          } catch (e) {
            console.log("e", e);
          }
        }
      });
      return placemarks;
    },
    onInitMap(map) {
      myMap = map;
      this.hintLayout = ymaps.templateLayoutFactory.createClass(
        `<div class='offersMap__hint' style='background: {{properties.categoryColor}}'>
          {{ properties.title }}<br />
          {{ properties.price.actualPrice }}
        </div>`,
        {
          getShape: function () {
            var el = this.getElement(),
              result = null;
            if (el) {
              var firstChild = el.firstChild;
              result = new ymaps.shape.Rectangle(
                new ymaps.geometry.pixel.Rectangle([
                  [0, 0],
                  [firstChild.offsetWidth, firstChild.offsetHeight],
                ])
              );
            }
            return result;
          },
        }
      );
      this.offerCardLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="offersMap__balloon">
          <div class="offersMap__image" style="background: url({{properties.img}})"></div>
          <div class="offersMap__body">
            <div class="offersMap__title">{{properties.title}}</div>
            <div class="offersMap__point">{{properties.retailPointName}}</div>
            <div class="offersMap__address">{{properties.address.street}} {{properties.address.house}}</div>
          </div>
          <div class="offersMap__footer">
            <div class="offersMap__price">{{properties.price.actualPrice}} ₽</div>
            <a href="/offer/{{properties.id}}" class="offersMap__btn">Купить</a>
          </div>
        </div>`
      );
      const clusterer = new ymaps.Clusterer({
        preset: "islands#blueClusterIcons",
        clusterOpenBalloonOnClick: true,
        clusterIconLayout: "default#pieChart",
        clusterIconPieChartRadius: 25,
        clusterIconPieChartCoreRadius: 20,
        clusterIconPieChartStrokeWidth: 0,
        clusterBalloonContentLayout: "cluster#balloonCarousel",
        clusterBalloonPagerType: "marker",
        clusterBalloonContentLayoutWidth: 270,
        clusterBalloonContentLayoutHeight: 306,
        clusterBalloonItemContentLayout: this.offerCardLayout,
      });
      clusterer.add(this.createPlaceMarks());
      map.geoObjects.add(clusterer);
      myClusterer = clusterer;
      map.setBounds(myClusterer.getBounds(), {
        checkZoomRange: true,
      });
      // clusterer.balloon.open(clusterer.getClusters()[0]);
    },
  },
};
</script>

<style lang="scss">
.ymaps-2-1-79-balloon {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow: hidden;
}
.ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}
.yandex-map {
  height: 650px;
  width: 100%;
}
.offersMap {
  &__hint {
    font-family: $body-font-family;
    display: inline-block;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 4px;
    font-size: 14px;
    width: 157px;
  }
  &__balloon {
    width: 270px;
    font-family: $body-font-family;
  }
  &__body {
    padding: 10px 10px 0;
  }
  &__image {
    height: 150px;
    width: 100%;
    background-size: cover !important;
    background-position: 50% 50% !important;
    margin-bottom: 10px;
  }
  &__title {
    color: $title;
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 14px;
  }
  &__point {
    color: $title;
    margin-bottom: 4px;
  }
  &__address {
    color: $text;
    margin-bottom: 10px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 10px;
  }
  &__btn {
    color: $accent;
    border: 2px solid rgba(45, 80, 251, 0.6);
    box-sizing: border-box;
    border-radius: 20px;
    width: 118px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s linear;
    &:hover {
      color: #ffffff;
      background: $accent;
    }
  }
  &__price {
    color: $title;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
