<template>
  <button :class="[`login`, { 'login--authorized': isAuthorized }]">
    <p v-if="!mobView">
      <span>{{ text }}</span>
    </p>
    <div>
      <span v-html="require(`@/assets/img/icons/user.svg`)" />
      <transition name="fade">
        <span
          v-show="isAuthorized"
          v-html="require(`@/assets/img/icons/ok.svg`)"
        />
      </transition>
    </div>
  </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  props: {
    isAuthorized: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["login"]),
    text() {
      if (this.isAuthorized) {
        return "Личный кабинет";
      } else {
        return "Войти";
      }
    },
    mobView() {
      return window.screen.width <= 768;
    },
  },
};
</script>

<style lang="scss">
.login {
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  padding: 5px;
  text-align: right;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: $accent;
  background-color: $primary;
  border: none;
  border-radius: $border-radius-half;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition: background-color $transition, box-shadow $transition,
    background-color $transition;
  p {
    display: flex;
    flex-direction: column;
    span:first-child {
      color: $accent;
    }
  }
  div {
    position: relative;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        svg {
          width: 30px;
          height: 30px;
          fill: $accent;
          transition: fill $transition;
        }
      }
      &:last-child {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
        background-color: $success;
        border: 1px solid $primary;
        border-radius: 50%;
        box-sizing: border-box;
        svg {
          width: 10px;
          height: 8px;
          fill: $primary;
        }
      }
    }
  }
  &--authorized {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $title;
    div {
      span {
        &:first-child {
          svg {
            fill: $accent;
          }
        }
      }
    }
  }
  &:hover {
    background-color: $input-hover;
    box-shadow: 0px 4px 25px rgba(45, 80, 251, 0.35);
  }
}

@media (min-width: $desktop-width) {
  .login {
    height: 60px;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: $border-radius;
    box-shadow: 12px 12px 20px rgba(45, 80, 251, 0.15);
    p {
      span:first-child {
      }
    }
    div {
      span {
        &:first-child {
          margin-left: 10px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        &:last-child {
          width: 16px;
          height: 16px;
        }
      }
    }
    &--authorized {
      font-weight: 500;
      background-color: $primary;
    }
  }
}
</style>
