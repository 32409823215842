<template>
  <div class="productsInPoint" v-if="!getLoadingStatus && point">
    <app-breadcrumbs :currentPage="point.retailPointName"></app-breadcrumbs>
    <h2 class="productsInPoint__title">
      {{ point.retailPointName }}<sup>{{ point.products.length }}</sup>
    </h2>

    <app-subscribe-banner v-if="!user.subscription" />

    <app-offers-grid
      :offers="point.products"
      :is-loading="getLoadingStatus"
      :is-end="getEndProducts"
      @loadMore="loadProducts(point.id)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Breadcrumbs from "@/components/UI/Breadcrumbs";
import SubscribeBanner from "@/components/UI/SubscribeBanner";
import OffersGrid from "@/components/Offers/OffersGrid";

export default {
  name: "ProductsInPoint",
  components: {
    "app-breadcrumbs": Breadcrumbs,
    "app-subscribe-banner": SubscribeBanner,
    "app-offers-grid": OffersGrid,
  },
  computed: {
    ...mapGetters("products", [
      "getProductsByPointId",
      "getLoadingStatus",
      "getProducts",
      "getLoadingStatus",
      "getEndProducts",
    ]),
    ...mapGetters("offers", {
      getOffersByPointId: "getOffersByPointId",
      getOffersLoadingStatus: "getLoadingStatus",
      getEndOffers: "getEndOffers",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
    point() {
      const products = this.getProductsByPointId(
        this.$route.params.retailPointId
      );
      const offers = this.getOffersByPointId(this.$route.params.retailPointId);
      if (products && products.products.length) {
        return products;
      } else {
        if (offers.length) {
          return {
            products: offers,
            retailPointName: offers[0].retailPointName,
          };
        }
      }
      return {
        products: [],
        retailPointName: "",
      };
    },
  },
  methods: {
    ...mapActions("products", ["loadProducts"]),
    ...mapActions("offers", ["loadOffers"]),
  },
};
</script>

<style scoped lang="scss">
.productsInPoint {
  &__title {
    margin-bottom: 20px;
  }
}
@media (min-width: $desktop-width) {
  .productsInPoint {
    margin-top: -60px;
  }
}
</style>
