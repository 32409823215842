<template>
  <div class="subscribe-banner">
    <div class="subscribe-banner__text">
      <h3>Подключите подписку и выгодно покупайте предложения партнеров</h3>
      <span>Все спецпредложения доступны только по подписке</span>
    </div>
    <Button @click="$router.push('/cart')"> Подключить </Button>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
export default {
  name: "SubscribeBanner",
  components: { Button },
};
</script>

<style lang="scss">
.subscribe-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: radial-gradient(
      100% 0%,
      rgba(45, 80, 251, 0.2) 0%,
      rgba(7, 0, 81, 0.2) 100%
    ),
    radial-gradient(
      100% 0%,
      rgba(45, 80, 251, 0.2) 0%,
      rgba(7, 0, 81, 0.2) 100%
    ),
    #070051;
  border-radius: $border-radius-desktop;
  box-shadow: 12px 12px 20px rgba(45, 80, 251, 0.15);
  padding: 18px 30px;
  margin-bottom: 30px;
  &__text {
    h3 {
      color: $primary;
      margin-bottom: 5px;
      font-size: 26px;
      line-height: 32px;
    }
    span {
      color: $input;
    }
  }
  .button {
    max-width: 175px;
    margin-left: 30px;
  }
}
@media (max-width: $desktop-width) {
  .subscribe-banner {
    flex-direction: column;
    padding: 20px;
    &__text {
      text-align: center;
      h3 {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .button {
      max-width: unset;
      margin-top: 10px;
      margin-left: 0;
    }
  }
}
</style>
