<template>
  <div
    v-if="defaultData.value === 'loyalty'"
    :class="['voucher-form__group', { collapsed: !defaultData.active }]"
  >
    <div class="voucher-form__group-header">
      <app-checkbox
        class="voucher-form__group-header-checkbox"
        v-model:value="defaultData.active"
      />
      <div class="voucher-form__group-header-title">
        Скидка для постоянных клиентов
      </div>
      <div
        v-if="checkValid"
        class="voucher-form__group-header-success-icon"
        v-html="require('@/assets/img/icons/success-fill.svg')"
      />
    </div>
    <div class="voucher-form__group-text">
      Укажите размер скидки для постоянных клиентов, которые совершили хотя бы 1
      покупку. Мы сами определим был ли покупатель у вас ранее.
    </div>
    <app-input
      class="voucher-form__group-input"
      v-model:value="defaultData.discount"
      @update:valid="valid = $event"
      label="Размер скидки, %"
      mode="discount"
      required
    />
  </div>
  <div
    v-else-if="defaultData.value === 'vouchers'"
    :class="['voucher-form__group', { collapsed: !defaultData.active }]"
  >
    <div class="voucher-form__group-header">
      <app-checkbox
        class="voucher-form__group-header-checkbox"
        v-model:value="defaultData.active"
      />
      <div class="voucher-form__group-header-title">
        Персональные ваучеры на скидку от 35%
      </div>
      <div
        v-if="checkValid"
        class="voucher-form__group-header-success-icon"
        v-html="require('@/assets/img/icons/success-fill.svg')"
      />
    </div>
    <div class="voucher-form__group-text">
      Данная привилегия позволит клиенту оплатить покупку с учетом крупной
      скидки. Ваучеры будут выдаваться персонально для тех клиентов, кто
      совершил покупок на сумму, которую вы выставили для минимального порога.
    </div>
    <div class="voucher-form__group-input__group">
      <app-input
        class="voucher-form__group-input"
        v-model:value="defaultData.discount"
        @update:valid="valid = $event"
        label="Размер скидки, %"
        mode="vouchers"
        required
      />
      <app-input
        class="voucher-form__group-input"
        v-model:value="defaultData.minSum"
        @update:valid="valid = $event"
        label="На сумму покупок от, ₽"
        required
      />
    </div>
    <app-input
      class="voucher-form__group-input"
      v-model:value="defaultData.globalCount"
      @update:valid="valid = $event"
      label="Кол-во для выдачи в месяц"
      required
    />
  </div>
  <div
    v-else-if="defaultData.value === 'coupons'"
    :class="['voucher-form__group', { collapsed: !defaultData.active }]"
  >
    <div class="voucher-form__group-header">
      <app-checkbox
        class="voucher-form__group-header-checkbox"
        v-model:value="defaultData.active"
      />
      <div class="voucher-form__group-header-title">
        Купоны на скидку до 35%
      </div>
      <div
        v-if="checkValid"
        class="voucher-form__group-header-success-icon"
        v-html="require('@/assets/img/icons/success-fill.svg')"
      />
    </div>
    <div class="voucher-form__group-text">
      Данная привилегия позволит клиенту оплатить покупку с учетом скидки.
      Задайте также параметры скидки для постоянных клиентов. Мы сами определим
      был ли покупатель у вас ранее.
    </div>
    <div class="voucher-form__group-input__group">
      <app-input
        class="voucher-form__group-input"
        v-model:value="defaultData.discount"
        @update:valid="valid = $event"
        label="Размер скидки, %"
        mode="coupons"
        required
      />
      <!--      <app-input-->
      <!--        class="voucher-form__group-input"-->
      <!--        v-model:value="data.coupon.increasedDiscount"-->
      <!--        label="Повышенная скидка, %"-->
      <!--        mode="discount"-->
      <!--        required-->
      <!--      />-->
      <app-input
        class="voucher-form__group-input"
        v-model:value="defaultData.minSum"
        @update:valid="valid = $event"
        label="На сумму покупок от, ₽"
        required
      />
    </div>
    <div class="voucher-form__group-input__group">
      <app-input
        class="voucher-form__group-input"
        v-model:value="defaultData.globalCount"
        @update:valid="valid = $event"
        label="Кол-во для выдачи в месяц"
        required
      />
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/UI/Inputs/Checkbox";
import Input from "@/components/UI/Inputs/Input";

export default {
  name: "VoucherFormGroup",
  components: {
    "app-checkbox": Checkbox,
    "app-input": Input,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    inputData: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.inputData) {
      this.defaultData = JSON.parse(JSON.stringify(this.inputData));
    }
  },
  data: () => ({
    defaultData: {},
    valid: false,
  }),
  computed: {
    checkValid() {
      if (this.defaultData.value === "loyalty") {
        return !!(
          this.defaultData.active &&
          this.defaultData.discount &&
          this.valid
        );
      } else if (
        this.defaultData.value === "vouchers" ||
        this.defaultData.value === "coupons"
      ) {
        return !!(
          this.defaultData.active &&
          this.defaultData.discount &&
          this.defaultData.globalCount &&
          this.defaultData.minSum &&
          this.valid
        );
      } else {
        return false;
      }
    },
  },
  watch: {
    defaultData: {
      deep: true,
      handler() {
        this.$emit("update:value", this.defaultData);
      },
    },
    valid() {
      if (this.defaultData.active) {
        this.$emit("update:valid", this.checkValid);
      }
    },
  },
};
</script>
