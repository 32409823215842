<template>
  <!--  <h2>Торговая точка не найдена</h2>-->
  <!--  <p>-->
  <!--    Возможно, данная торговая точка больше не участвует в бонусной программе. По возникающим вопросам свяжитесь с тех. поддержкой:-->
  <!--    <span>-->
  <!--      <app-link link="tel:+78123221010">+7 (812) 322-10-10</app-link>-->
  <!--      |-->
  <!--  <app-link :link="`mailto:${helpDeskMailUrl}`">{{ helpDeskMailUrl }}</app-link>-->
  <!--    </span>-->
  <!--  </p>-->
  <h2>Заказ не найден</h2>
  <p>
    По возникающим вопросам свяжитесь с тех. поддержкой:
    <span>
      <app-link link="tel:+78123221010">+7 (812) 322-10-10</app-link>
      |
      <app-link :link="`mailto:${supportEmail}`">{{ supportEmail }} </app-link>
    </span>
  </p>
</template>

<script>
import Link from "@/components/UI/Links/Link.vue";
import { mapState } from "vuex";

export default {
  name: "NotFoundError",
  components: {
    "app-link": Link,
  },
  computed: {
    ...mapState({ supportEmail: "supportEmail" }),
  },
};
</script>
