<template>
  <main class="app-container">
    <app-breadcrumbs current-page="Избранное" />
    <h1>
      Избранное
      <span>{{ favouritesIds.length }}</span>
    </h1>
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs";
import { mapGetters } from "vuex";
export default {
  name: "FavouritesPage",
  components: { "app-breadcrumbs": Breadcrumbs },
  data() {
    return {
      count: 3,
    };
  },
  computed: {
    ...mapGetters("favourites", ["favouritesIds"]),
  },
};
</script>

<style lang="scss"></style>
