<template>
  <div :class="['checkbox', { disabled: disabled }]">
    <label
      class="checkbox__input"
      :class="{
        checked: value,
      }"
    >
      <input
        type="checkbox"
        :required="required"
        :disabled="disabled"
        @change="updateInput"
      />
      <transition name="fade">
        <div
          v-show="value"
          class="checkbox__input-icon"
          v-html="require('@/assets/img/icons/ok.svg')"
        />
      </transition>
    </label>
    <p class="checkbox__label">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    updateInput(event) {
      this.$emit("update:value", event.target.checked);
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  &__input {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: $input;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color $transition, border $transition;
    box-sizing: border-box;
    input {
      display: none;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $primary;
      }
    }
    &:hover {
      background-color: $input-hover;
      border: 2px solid $input;
    }
    &.checked {
      background-color: $accent;
      border: 2px solid transparent;
    }
    &:hover.checked {
      background-color: $accent-hover;
      border: 2px solid transparent;
    }
  }
  &__label {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 6px;
    font-size: 1rem;
    line-height: 1.286rem;
    color: $text;
    text-align: left;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@media (min-width: $desktop-width) {
  .checkbox {
    &__label {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}
</style>
