<template>
  <section class="payment-order form">
    <header>
      <h3>Товары</h3>
      <p v-if="order.id">
        <span v-html="require(`@/assets/img/icons/bag.svg`)" />
        Заказ №: {{ order.id }}
      </p>
    </header>

    <main>
      <app-payment-draft />

      <app-checkbox v-model:value="agreed">
        Я согласен с
        <app-link :link="getPolicyURLs.participantOffer"
          >условиями оферты</app-link
        >
      </app-checkbox>

      <!--      <app-apple-pay-->
      <!--        v-show="paymentType === 'APPLE'"-->
      <!--        :disabled="!valid"-->
      <!--        @pay="pay"-->
      <!--      />-->

      <!--      <app-google-pay-->
      <!--        v-show="paymentType === 'GOOGLE'"-->
      <!--        :disabled="!valid"-->
      <!--        @pay="pay"-->
      <!--      />-->

      <app-sbp
        v-show="paymentType === 'SBP_QR'"
        @click="sendProcessDraft"
        :disabled="!valid"
      />

      <app-button
        v-show="paymentType === 'NEW_CARD'"
        @click="sendProcessDraft"
        :disabled="!valid"
      >
        Оплатить {{ $filters.price(draftExtra.spend) }} ₽
      </app-button>
    </main>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Link from "@/components/UI/Links/Link";
import Checkbox from "@/components/UI/Inputs/Checkbox";
import Button from "@/components/UI/Buttons/Button";
import Sbp from "@/components/UI/Buttons/sbp";
import PaymentDraft from "@/components/Payment/Components/Draft";
// import GooglePay from '@/components/UI/buttons/pay/Google.vue'
// import ApplePay from '@/components/UI/buttons/pay/Apple.vue'

export default {
  name: "PaymentOrder",
  components: {
    "app-sbp": Sbp,
    "app-checkbox": Checkbox,
    "app-link": Link,
    "app-button": Button,
    "app-payment-draft": PaymentDraft,
    // 'app-apple-pay': ApplePay,
    // 'app-google-pay': GooglePay
  },
  computed: {
    ...mapGetters(["getPolicyURLs"]),
    ...mapGetters("user", {
      email: "getEmail",
      emailValid: "getEmailValid",
    }),
    ...mapGetters("offers", {
      offerToPay: "getOfferToPay",
    }),
    ...mapGetters("paymentMethods", {
      paymentType: "paymentType",
    }),
    ...mapGetters("order", ["order"]),
    ...mapGetters("payment", ["draftExtra"]),
    // ...mapGetters('order', [
    //   'orderId',
    //   'orderTotal'
    // ]),
    // ...mapGetters('paymentTypes', [
    //   'paymentType',
    //   'payServices'
    // ]),
    // buttonText () {
    //   if (this.orderTotal > 0) {
    //     return `Оплатить ${filters.price(this.orderTotal)} ₽`
    //   } else {
    //     return 'Оплатить бонусами'
    //   }
    // },
    valid() {
      return this.email && this.emailValid && this.agreed;
    },
  },
  async created() {
    if (!this.order.id) {
      await this.getOrder(this.$route.params.id);
    }
  },
  data() {
    return {
      agreed: false,
    };
  },
  methods: {
    ...mapActions("payment", {
      processDraft: "processDraft",
    }),
    ...mapActions("order", ["getOrder"]),
    async sendProcessDraft() {
      await this.processDraft();
    },
  },
};
</script>

<style lang="scss">
.payment-order {
  & > header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    p {
      display: inline-flex;
      align-items: center;
      justify-self: flex-end;
      font-size: 0.857rem;
      line-height: 1.143rem;

      svg {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        margin-bottom: 2px;
      }
    }
  }
  & > main {
    .checkbox {
      margin: 10px 0 25px;
    }
  }
}

@media (min-width: $desktop-width) {
  .payment-order {
    & > header {
      p {
        svg {
          margin-right: 9px;
        }
      }
    }
    & > main {
      .checkbox {
        margin: 15px 0 25px;
      }
    }
  }
}
</style>
