<template>
  <div class="tooltip">
    <div
      class="tooltip__label"
      @mouseover="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <slot name="label"></slot>
    </div>
    <div class="tooltip__content" v-show="showTooltip">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  data: () => ({
    showTooltip: false,
  }),
};
</script>

<style scoped lang="scss">
.tooltip {
  position: relative;
  &__label {
    display: inline-block;
    cursor: pointer;
  }
  &__content {
    background: #ffffff;
    border-radius: $border-radius;
    box-shadow: $popup-shadow;
    position: absolute;
    z-index: 10;
  }
}
</style>
