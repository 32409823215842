<template>
  <app-preloader :value="isLoading || getLoading" />
  <main class="app-container offer-page" v-if="current">
    <app-breadcrumbs :currentPage="current.title" />
    <h2>{{ current.title }}</h2>

    <div class="offer-page__main">
      <app-offer-slider
        :key="current._id"
        v-if="current.images"
        :offer="current"
      />
      <app-offer-info :offer="current" />
    </div>

    <app-offer-description
      :rules="current.rules"
      :description="current.description || current.partnerDescription"
    />

    <section
      class="offer-page__additional"
      v-if="products.length || offers.length"
    >
      <header>
        <h3>
          <span>Предложения партнера</span>
          <sup>{{ products.length || offers.length }}</sup>
        </h3>
      </header>

      <main>
        <app-offers-grid
          v-if="products.length"
          :offers="products.slice(0, 6)"
          :random-products="[]"
          :is-loading="false"
          :is-end="true"
        />

        <app-offers-grid
          v-if="offers.length"
          :offers="offers.slice(0, 6)"
          :random-products="[]"
          :is-loading="false"
          :is-end="true"
        />
      </main>

      <footer>
        <app-button
          mode="secondary"
          @click="
            $router.push({
              name: 'ProductsInPoint',
              params: { retailPointId: $route.params.id },
            })
          "
        >
          Смотреть все
        </app-button>
      </footer>
    </section>

    <section
      class="offer-page__additional"
      v-if="$route.name === 'Offer' && (products.length || offers.length)"
    >
      <header>
        <h3>
          <span>Предложения этой категории</span>
          <sup>{{ productsByRatio.length }}</sup>
        </h3>
      </header>

      <main>
        <!--        <app-offers-grid-->
        <!--          v-if="productsByRatio.length"-->
        <!--          :offers="productsByRatio.slice(0, 6)"-->
        <!--          :random-products="[]"-->
        <!--          :is-loading="false"-->
        <!--          :is-end="true"-->
        <!--        />-->
        <app-products-slider
          class="offer-page__additional-products-slider"
          v-if="productsByRatio.length"
          :products="productsByRatio.slice(0, 6)"
          mode="default"
        />
      </main>

      <footer>
        <app-button
          mode="secondary"
          @click="
            $router.push({
              name: 'Offers',
              params: { filter: this.currentOffer.price.ratio },
            })
          "
        >
          Смотреть все
        </app-button>
      </footer>
    </section>

    <app-offer-map
      :offers="[current]"
      :categories="getCategories || getProductCategories"
    />
  </main>
</template>

<script>
import Breadcrumbs from "@/components/UI/Breadcrumbs";
import { mapGetters, mapActions } from "vuex";
import OfferSlider from "@/components/Offers/Page/OfferSlider";
import OfferInfo from "@/components/Offers/Page/OfferInfo";
import OfferDescription from "@/components/Offers/Page/OfferDescription";
import OffersGrid from "@/components/Offers/OffersGrid";
import Button from "@/components/UI/Buttons/Button";
import Preloader from "@/components/UI/Preloader";
import Map from "@/components/YMap";
import ProductsSlider from "@/components/Products/ProductsSlider";

export default {
  name: "OfferPage",
  components: {
    "app-offer-map": Map,
    "app-offer-description": OfferDescription,
    "app-offer-info": OfferInfo,
    "app-offer-slider": OfferSlider,
    "app-products-slider": ProductsSlider,
    "app-breadcrumbs": Breadcrumbs,
    "app-offers-grid": OffersGrid,
    "app-button": Button,
    "app-preloader": Preloader,
  },
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters("offers", {
      getCategories: "getCategories",
      currentOffer: "getSingleOffer",
      quantity: "getOffersQuantity",
      getOffers: "getOffers",
      isLoading: "getLoadingStatus",
      isEndOffers: "getEndOffers",
    }),
    ...mapGetters("products", {
      getProductsByPointId: "getProductsByPointId",
      getProductCategories: "getCategories",
      getRandomProductsByRatio: "getRandomProductsByRatio",
      getProductsByRatio: "getProductsByRatio",
    }),
    ...mapGetters("partner", ["getPointInfo", "getLoading"]),
    current() {
      if (this.$route.name === "Offer") {
        return this.currentOffer;
      } else {
        return this.getPointInfo;
      }
    },
    products() {
      let products;
      if (this.$route.name === "Offer") {
        products = this.getProductsByPointId(this.currentOffer.retailPointId);
      } else {
        products = this.getProductsByPointId(this.$route.params.id);
      }
      if (products) {
        return products.products;
      }
      return [];
    },
    productsByRatio() {
      if (this.currentOffer.type === "N_LIKE_M") {
        return this.getProductsByRatio(this.currentOffer.price.ratio);
      } else {
        return this.getProductsByRatio(this.currentOffer.price.percent);
      }
    },
    offers() {
      return this.getOffers.filter(
        (item) => item.retailPointId === +this.$route.params.id
      );
    },
  },
  watch: {
    async $route(to) {
      await this.setDefault(to.name, to.params.id);
    },
  },
  async created() {
    if (!this.getCategories.length) {
      await this.loadCategories();
    }

    await this.setDefault(this.$route.name, this.$route.params.id);
  },
  beforeUnmount() {
    this.resetSingleOffer();
  },
  methods: {
    ...mapActions("offers", {
      loadOffer: "loadSingleOffer",
      resetSingleOffer: "resetSingleOffer",
      loadOffers: "loadOffers",
      loadCategories: "loadCategories",
    }),
    ...mapActions("products", {
      loadProducts: "loadProducts",
      loadProductCategories: "loadCategories",
      loadProductsByRatio: "loadProductsByRatio",
    }),
    ...mapActions("partner", {
      loadPartner: "loadPartner",
    }),
    async setDefault(name, id) {
      if (name === "Offer") {
        await this.loadOffer(id);
        await this.loadProducts();
        if (
          this.currentOffer.productType === "product" &&
          this.currentOffer.type === "N_LIKE_M"
        ) {
          await this.loadProductsByRatio({
            ratio: this.currentOffer.price.ratio,
            type: this.currentOffer.type,
          });
        }
        if (
          this.currentOffer.productType === "product" &&
          this.currentOffer.type === "DISCOUNT"
        ) {
          await this.loadProductsByRatio({
            ratio: this.currentOffer.price.percent,
            type: this.currentOffer.type,
          });
        }
      } else if (name === "PartnerInfo") {
        await this.loadPartner(id);
        await this.loadProducts(id);
        if (!this.products.length) {
          await this.loadOffers(id);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.offer-page {
  min-height: 100vh;
  padding-bottom: 60px;

  &__main {
    width: 100%;
    margin-top: 30px;
    display: grid;
    gap: 0 30px;
    grid-template-columns: minmax(360px, 700px) minmax(380px, 500px);
    @media (max-width: $desktop-width) {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
  }

  &__additional {
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: $desktop-width) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    & > header > h3 {
      margin-top: 30px;
      margin-bottom: 20px;
      @media (max-width: $desktop-width) {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
    & > footer {
      .button {
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
@media (max-width: $desktop-width) {
  .offer-page {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
</style>
