<template>
  <div class="offer-slider" v-if="offer && offer.images && offer.images.length">
    <div class="offer-slider__main">
      <div class="offer-slider__main-container">
        <swiper
          slidesPerView="1"
          :pagination="{ clickable: true }"
          :thumbs="{ swiper: swiperSecondary }"
          :navigation="{
            prevEl: '.offer-slider__main-buttons--left',
            nextEl: '.offer-slider__main-buttons--right',
          }"
        >
          <swiper-slide v-for="img in offer.images" :key="img">
            <img class="offer-slider__main-img" :src="img" alt="" />
          </swiper-slide>
        </swiper>
        <div class="offer-slider__main-buttons">
          <app-slider-button
            class="offer-slider__main-buttons--left"
            direction="left"
          />
          <app-slider-button
            class="offer-slider__main-buttons--right"
            direction="right"
          />
        </div>
      </div>
    </div>
    <swiper
      v-if="showPreviewSlider"
      class="offer-slider__secondary"
      :slidesPerView="slidesPerView"
      @swiper="setSecondarySwiper"
      :spaceBetween="10"
      :freemode="true"
    >
      <swiper-slide
        class="offer-slider__secondary-slide"
        v-for="img in offer.images"
        :key="img"
      >
        <img :src="img" alt="" />
      </swiper-slide>
    </swiper>
    <offer-labels :card="offer" :labelType="offer.type" />
  </div>
  <div class="offer-slider--empty" v-else>
    <img src="@/assets/img/empty-photo.png" alt="" />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import SwiperCore, { Pagination, Thumbs, Navigation, FreeMode } from "swiper";
import "swiper/swiper-bundle.css";
import OfferLabels from "@/components/UI/Labels";
import SliderButton from "@/components/UI/Buttons/Slider";

export default {
  name: "OfferSlider",
  components: {
    "offer-labels": OfferLabels,
    "app-slider-button": SliderButton,
    Swiper,
    SwiperSlide,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperMain: null,
      swiperSecondary: null,
    };
  },
  methods: {
    setSecondarySwiper(swiper) {
      this.swiperSecondary = swiper;
    },
  },
  computed: {
    slidesPerView() {
      if (this.offer.images && this.offer.images.length > 4) {
        return 4;
      } else {
        return this.offer.images.length;
      }
    },
    showPreviewSlider() {
      return this.offer.images.length > 1;
    },
  },
  mounted() {},
  created() {
    SwiperCore.use([Pagination, Thumbs, Navigation, FreeMode]);
  },
};
</script>

<style lang="scss">
.offer-slider {
  width: 100%;
  position: relative;
  &--empty {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }
  .offer-labels {
    position: absolute;
    top: 15px;
    left: 15px;
  }
  &__main {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    border-radius: $border-radius;
    overflow: hidden;
    &-img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
    &-container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      .swiper {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
    }
    &-buttons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .slider__button {
        &--left {
          border-radius: 0 20px 20px 0;
        }
        &--right {
          border-radius: 20px 0 0 20px;
        }
      }
      @media (max-width: 1024px) {
        display: none;
      }
    }
    @media (max-width: 1024px) {
      border-radius: 0;
    }
  }
  &__secondary {
    margin-top: 10px;
    height: 90px;
    &-slide {
      overflow: hidden;
      border-radius: $border-radius;
      cursor: pointer;
      opacity: 0.3;
      transition: $transition;
      &.swiper-slide-thumb-active,
      &:hover {
        opacity: 1;
      }
      img {
        width: 100%;
        height: inherit;
        object-fit: cover;
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }
}
</style>
