<template>
  <app-loader
    v-if="getLoadingStatus === 'pending'"
    :value="getLoadingStatus === 'pending'"
  />
  <div v-else class="partner-products">
    <h2 class="partner-products__title">
      Загрузка товаров на площадку {{ projectName }}
    </h2>
    <div class="partner-products__form">
      <!--      <div class="partner-products__form-header">-->
      <!--        <app-select-->
      <!--          :inputValue="Object.keys(this.points)[0]"-->
      <!--          :options="points"-->
      <!--          placeholder="Торговая точка"-->
      <!--          disabled-->
      <!--          @update:value="retailPointId = $event.id"-->
      <!--        />-->
      <!--        <app-select-->
      <!--          :inputValue="Object.keys(this.types)[0]"-->
      <!--          :options="types"-->
      <!--          placeholder="Тип размещения"-->
      <!--          disabled-->
      <!--          @update:value="productType = $event.id"-->
      <!--        />-->
      <!--      </div>-->
      <app-product-form
        v-for="(product, index) in products"
        :key="index + product.uniqueId"
        :collapse="products.length - 1 > index"
        :product="product"
        :retailPointId="retailPointId"
        :points="getPartnerPoints"
        @update:product="setProduct($event, index)"
        @update:valid="valid = $event"
        @delete="deleteProduct(index)"
      />
      <div class="partner-products__form-general-add">
        <app-button @click="addOne" :disabled="!valid" mode="secondary"
          >+ Добавить еще товар</app-button
        >
      </div>
      <div class="partner-products__form-general-buttons">
        <app-button
          class="partner-products__form-general-btn"
          :disabled="!valid"
          @click="submit"
          >Загрузить</app-button
        >
        <app-button
          mode="secondary"
          class="partner-products__form-general-btn"
          @click="
            $router.push({
              name: 'PartnerProducts',
              query: { key: this.$route.query.key },
            })
          "
          >Отмена</app-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Buttons/Button";
// import Select from "@/components/UI/Selects/Select";
import { mapActions, mapGetters } from "vuex";
import ProductForm from "@/components/Products/ProductForm";
import Preloader from "@/components/UI/Preloader";

export default {
  name: "NewProduct",
  components: {
    "app-product-form": ProductForm,
    // "app-select": Select,
    "app-button": Button,
    "app-loader": Preloader,
  },
  async created() {
    if (!Object.values(this.getPartnerPoints).length) {
      await this.setPartnerPoints({ key: this.$route.query.key });
    }
    this.points = JSON.parse(JSON.stringify(this.getPartnerPoints));
    this.retailPointId = Object.keys(this.points)[0];
    this.products.push({
      ...this.defaultProduct,
      uniqueId: this.defaultProduct.uniqueId++,
    });
  },
  data: () => ({
    points: {},
    categories: {
      1: "Красота и здоровье",
      2: "Торговля",
      3: "Путешествия и отдых",
      4: "Еда и напитки",
      6: "Автомобили",
      7: "Разное",
    },
    retailPointId: null,
    type: null,
    products: [],
    defaultProduct: {
      uniqueId: 1,
      title: "",
      description: "",
      price: {
        oldPrice: "",
        original: "",
      },
      categoryId: "",
      images: [],
      retailPointId: "",
      productType: "product",
    },
    types: {
      product: "Товар",
      offer: "Спецпредложение",
    },
    valid: false,
    checkValid: true,
  }),
  computed: {
    ...mapGetters("partner", ["getPartnerPoints"]),
    ...mapGetters("products", [
      "getSingleProduct",
      "getLoadingStatus",
      "getProjectId",
    ]),
    projectName() {
      if (this.getProjectId === "hotoff") {
        return "IBonus";
      } else {
        return "BBonus";
      }
    },
  },
  methods: {
    ...mapActions("partner", ["setPartnerPoints"]),
    ...mapActions("products", [
      "addOneProduct",
      "loadSinglePartnerProduct",
      "addProducts",
    ]),
    async submit() {
      await this.addProducts(this.products);
      await this.$router.push({
        name: "PartnerProducts",
        query: { key: this.$route.query.key },
      });
    },
    addOne() {
      this.products.push({
        ...this.defaultProduct,
        uniqueId: this.defaultProduct.uniqueId++,
      });
    },
    setProduct(e, id) {
      this.products[id] = Object.assign({}, e);
    },
    deleteProduct(id) {
      const arr = this.products.filter((i, index) => index !== id);
      this.products = arr;
      if (!this.products.length) {
        this.addOne();
      }
    },
  },
};
</script>

<style lang="scss">
.partner-products {
  min-height: calc(100vh - 331px);
  max-width: 850px;
  margin: 0 auto;
  padding: 60px 0;
  &__form {
    margin-top: 35px;
    //width: 100%;
    padding: 30px;
    border-radius: 30px;
    background: $primary;
    box-shadow: $popup-shadow;
    &-header {
      display: flex;
      margin-bottom: 30px;
      .select-wrapper:first-child {
        margin-right: 30px;
      }
    }
    &-general {
      background: rgba(234, 238, 244, 0.3);
      border-radius: 30px;
      padding: 30px;
      border: 2px dashed $text-light;
      margin-bottom: 10px;
      transition: $transition;
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        &-text {
          font-size: 20px;
          font-weight: 700;
          color: $accent;
          display: flex;
          align-items: center;
          &-icon {
            opacity: 0.5;
            margin-right: 8px;
          }
        }
        &-icons {
          display: flex;
          align-items: center;
        }
        &-icon {
          cursor: pointer;
          height: 20px;
          transition: $transition;
          &:last-child {
            margin-left: 35px;
          }
        }
      }
      & .input {
        margin-bottom: 20px !important;
      }
      &--title {
        margin-bottom: 10px;
      }
      &--group {
        display: flex;
        align-items: center;
        & .input:first-child {
          margin-right: 30px;
        }
      }
      & .select-wrapper {
        width: 48%;
        margin-bottom: 20px;
      }
      &-add {
        margin-bottom: 30px;
      }
      &-buttons {
        display: flex;
        align-items: center;
      }
      &-btn {
        width: 215px;
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          height: 64px;
        }
      }
      &.collapse {
        & .partner-products__form-general-header {
          margin-bottom: 0;
          & .partner-products__form-general-header-icon:last-child {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

@media (max-width: $tablet-width) {
  .partner-products {
    padding: 0;
    &__title {
      padding-top: 30px;
      padding-left: 15px;
      font-size: 26px;
      line-height: 32px;
    }
    &__form {
      padding: 15px;
      margin-top: 20px;
      &-header {
        flex-direction: column;
        margin-bottom: 0;
        & .select-wrapper {
          margin-right: 0 !important;
          margin-bottom: 15px;
        }
      }
      & .input {
        margin-bottom: 15px !important;
      }
      &-general {
        padding: 15px;
        &-header {
          &-text {
            font-size: 16px;
          }
        }
        &--group {
          flex-direction: column;
          & .input {
            margin-right: 0 !important;
            margin-bottom: 15px !important;
          }
        }
        & .select-wrapper {
          width: 100%;
        }
        &-add {
          margin-bottom: 15px;
        }
        &-buttons {
          flex-direction: column;
          & .button {
            width: 100%;
          }
          & .button--primary {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
    & .images {
      & .images-item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
        &__wrapper {
          width: 100%;
        }
      }
      & .file-input__wrapper {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>
