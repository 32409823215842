<template>
  <div :class="['timer', { disabled: disabled }]">
    <p v-if="sended && resended">
      Повторно отправить код можно через 0:{{ time }}
    </p>

    <app-button-link v-else size="s" :disabled="disabled" @click="resend">
      Отправить код повторно
    </app-button-link>
  </div>
</template>

<script>
import ButtonLink from "@/components/UI/Buttons/ButtonLink.vue";

export default {
  name: "Timer",
  components: {
    "app-button-link": ButtonLink,
  },
  props: {
    resended: {
      // true, если запрос на отправку токена отправлен успешно
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    timer: 0,
    time: 59,
    sended: true, // true, если запрос на отправку токена отправлен и таймер еще не истек
  }),
  watch: {
    time() {
      if (this.time === 0) {
        this.stopTimer();
        this.sended = false;
        this.time = 59;
      }
    },
    resended: {
      handler() {
        if (this.resended) {
          this.startTimer();
        }
      },
      immediate: true,
    },
  },
  methods: {
    startTimer() {
      this.timer = window.setInterval(() => {
        this.time--;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    resend() {
      this.$emit("send");
      this.sended = true;
    },
  },
};
</script>

<style lang="scss">
.timer {
  p {
    font-size: 0.875rem;
    line-height: 1rem;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
