<template>
  <div class="partner__container">
    <app-partner-stepper />
  </div>
</template>

<script>
import PartnerStepper from "@/components/Partner/PartnerStepper";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Partner",
  components: {
    "app-partner-stepper": PartnerStepper,
  },
  async created() {
    await this.setPartnerByInn();
    this.setPartnerInfo({
      inviter: localStorage.getItem(
        `${process.env.VUE_APP_PROJECT_ID}SpecialsInviter`
      ),
    });
  },
  computed: {
    inviter() {
      return this.$route.query.inviter || "";
    },
  },
  methods: {
    ...mapActions("partner", ["updatePartner", "setPartnerByInn"]),
    ...mapMutations("partner", { setPartnerInfo: "SET_PARTNER_INFO" }),
  },
};
</script>

<style lang="scss">
.partner__container {
  padding: 40px 0 60px;
  min-height: calc(100vh - 331px);
  display: flex;
  justify-content: space-around;
}
</style>
